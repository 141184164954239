import React, {useContext, useState, useEffect } from 'react';
import { CoinContext } from '../contextdtatashare/CoinContext';
import { Snackbar, Stepper, Step, StepLabel , Box, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Confetti from 'react-confetti';
import axios from 'axios';
import { faPaperPlane, faArrowDown, faSyncAlt, faQrcode, faCopy } from '@fortawesome/free-solid-svg-icons';
import {
  StyledButton,
  StyledDrawer,
  StyledTextField,
  StyledBox,
  StyledTypography,
  StyledCloseIconButton,
} from './sendStyle';
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';


const Icon = styled(FontAwesomeIcon)`
    font-size: 20px;
  `;
const formatAddress = (address) => {
    if (address.length <= 15) return address; // Return the address if it's short enough
    const start = address.slice(0, 6); // First 6 characters
    const end = address.slice(-4); // Last 4 characters
    return `${start}...${end}`; // Formatted address
  };
const SEND_FEE_PERCENTAGE = 0.01;
const CRYPTO_TO_USD = 2000;
const getTonPriceInUsd = async () => {
    const price_url = `https://jetton-index.tonscan.org/market_data/chart?days=1&interval=daily&vs_currency=USD&precision=4`;
    try {
      const price_response = await axios.get(price_url);
      if (price_response.status === 200) {
        const latestPriceData = price_response.data.prices;
        // Assuming the latest price is the last entry in the prices array
        return latestPriceData[latestPriceData.length - 1][1]; // The price of 1 TON in USD
      } else {
        console.error('Error fetching price data:', price_response.statusText);
        return null; // Or handle the error appropriately
      }
    } catch (error) {
      console.error('Error fetching price data:', error);
      return null;
    }
  };

  
const SendCrypto = () => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [recipient, setRecipient] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [estimatedUSD, setEstimatedUSD] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cryptoToUsd, setCryptoToUsd] = useState(0); // Store the current price of TON
  const {
    createWallet, fetchWalletData
  } = useContext(CoinContext);
  const [maxSendableAmount, setMaxSendableAmount] = useState(0);
  
  const toggleDrawer = (open) => () => setOpen(open);

  
  const handleMaxAmount = () => {
    setAmount(maxSendableAmount.toString()); // Set the max amount to the amount text field
  };
  const [addressError, setAddressError] = useState('');
  const validateAddress = (address) => {
    
    if (createWallet.address == address) {
      setAddressError("You can't send to your self!");
      
    } else {
      setAddressError(''); // Clear error if valid
    }
    
  };
  useEffect(() => {
    const fetchTonPrice = async () => {
      const price = await getTonPriceInUsd();
      if (price) {
        setCryptoToUsd(price);
      }
    };

    fetchTonPrice();
  }, []);

  useEffect(() => {
    
    const amountInNumber = parseFloat(amount) || 0;
    setEstimatedUSD(amountInNumber * cryptoToUsd);
    const data = window.Telegram.WebApp.initData;
    const feeUrl = `https://67078ccf4eb31299c3df.appwrite.global/fee?initData=${encodeURIComponent(data)}&toAddress=${recipient}&value=${amount}&comment=`;

    //const fee = `https://67078ccf4eb31299c3df.appwrite.global/fee?initData=user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d&toAddress=${recipient}&value=${amount}&comment=Payment%20for%20services`;

    // setTransactionFee(amountInNumber * SEND_FEE_PERCENTAGE);
  }, [amount, cryptoToUsd]);

  useEffect(() => {
    const fetchTransactionFee = async () => {
      if (recipient && amount) {
        const data = window.Telegram.WebApp.initData;
        const feeUrl = `https://67078ccf4eb31299c3df.appwrite.global/fee?initData=${encodeURIComponent(data)}&toAddress=${recipient}&value=${amount}&comment=`;

        //const feeUrl = `https://67078ccf4eb31299c3df.appwrite.global/fee?initData=user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d&toAddress=${recipient}&value=${amount}&comment=Payment%20for%20services`;
        
        try {
          const feeResponse = await axios.get(feeUrl);
          console.log(feeResponse)

          if (feeResponse.data.status === 'error') {
            if (feeResponse.data.message.includes('Insufficient balance')) {
                setErrorMessage(feeResponse.data.message); // Show error message for insufficient balance
                setErrorOpen(true);
                setTransactionFee(feeResponse.data.totalFee);
            } else if (feeResponse.data.message.includes('exceeds the maximum allowable send amount')) {
                setAmount(feeResponse.data.maxSendableAmount); // Automatically set the max sendable amount
                setErrorMessage(`Max sendable amount is ${feeResponse.data.maxSendableAmount}. It has been set for you.`); // Notify user
                setErrorOpen(true);
                setTransactionFee(feeResponse.data.totalFee);
            }
        } else {
            setTransactionFee(feeResponse.data.totalFee); // Normal fee calculation
        }
        
        //   if (feeResponse.data.totalFee) {
        //     setTransactionFee(feeResponse.data.totalFee);
        //   } else {
        //     console.error('Failed to fetch the fee');
        //   }
        } catch (error) {
          console.error('Error fetching fee:', error);
        }
      }
    };
  
    fetchTransactionFee();
  }, [recipient, amount]);
  
//   useEffect(() => {
//     const amountInNumber = parseFloat(amount) || 0;
//     setEstimatedUSD(amountInNumber * CRYPTO_TO_USD);
//     setTransactionFee(amountInNumber * SEND_FEE_PERCENTAGE);
//   }, [amount]);

  const handleNextStep = () => setActiveStep((prev) => prev + 1);
  const handleReset = () => {
    setActiveStep(0);
    setAmount('');
    setRecipient('');
    toggleDrawer(false)();
  };

  const handleConfirmSend = async () => {
    const data = window.Telegram.WebApp.initData;
    const apiUrl = `https://67078ccf4eb31299c3df.appwrite.global/send?initData=${encodeURIComponent(data)}&toAddress=${recipient}&value=${amount}&comment=`;

    //const apiUrl = `https://67078ccf4eb31299c3df.appwrite.global/send?initData=user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d&toAddress=${recipient}&value=${amount}&comment=Payment%20for%20services`;

    setLoading(true);
    setConfirmLoading(true);

    try {
      const response = await axios.get(apiUrl);
      if (response.data.status === 'confirmed') {
        fetchWalletData()
        setSuccessOpen(true);
        handleNextStep();
      } else {
        setErrorMessage(response.data.message || 'An error occurred.');
        setErrorOpen(true);
      }
    } catch (err) {
      setErrorMessage('Failed to send payment. Please try again.');
      setErrorOpen(true);
      console.error(err);
    } finally {
      setLoading(false);
      setConfirmLoading(false);
    }
  };

  const handleCloseSuccess = () => {
    setSuccessOpen(false);
    handleReset();
  };

  const handleCloseError = () => setErrorOpen(false);

  return (
    <>
      
        <StyledButton
          variant="contained"
          disabled={!createWallet}
          
          // endIcon={<SendIcon />}
          onClick={toggleDrawer(true)}
        >
          <Icon icon={faPaperPlane} /> &nbsp;Send
        </StyledButton>
   

      <StyledDrawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
        <StyledBox>
          <StyledTypography variant="h5">
            Send Cryptocurrency
          </StyledTypography>
          <StyledCloseIconButton onClick={() => { handleReset(); toggleDrawer(false)(); }}>
            <CloseIcon />
        </StyledCloseIconButton>
        </StyledBox>

        <Stepper activeStep={activeStep} alternativeLabel sx={{ mt: 2, mb: 4 }}>
          {['Enter Details', 'Confirm', 'Success'].map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <>
            <StyledTextField
              label="Recipient Address"
              fullWidth
              variant="outlined"
              value={recipient}
              onChange={(e) => {
                setRecipient(e.target.value);
                validateAddress(e.target.value);
              }}
               // Validate on blur
              error={Boolean(addressError)} // Show error state in the input field
              helperText={addressError}
            />
            <StyledTextField
              label="Amount"
              type="number"
              fullWidth
              variant="outlined"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <StyledBox mt={2}>
              <Typography color="#3b3f5c">
                💵 Estimated USD: <b>${estimatedUSD.toFixed(2)}</b>
              </Typography>
              <Typography color="#3b3f5c">
                💸 Transaction Fee: <b>{transactionFee} Ton</b>
              </Typography>
            </StyledBox>
            <StyledButton
              onClick={handleNextStep}
              variant="contained"
              disabled={!amount || !recipient || addressError || amount == 0 || amount == 0. || transactionFee == 0}
              sx={{ mt: 4 }}
            >
              Proceed
            </StyledButton>
          </>
        )}

        {activeStep === 1 && (
          <Box textAlign="center" mt={4}>
            <Typography variant="h6" color="#3b3f5c">
              Confirm Transaction
            </Typography>
            <Typography mt={2}>
              Send <b>{amount}</b> Crypto to:
            </Typography>
            <Typography color="primary">
              <b>{formatAddress(recipient)}</b>
            </Typography>
            <Typography mt={2} color="#3b3f5c">
              Estimated USD: <b>${estimatedUSD.toFixed(2)}</b>
            </Typography>
            <Typography color="#3b3f5c">
              Transaction Fee: <b>${transactionFee}</b>
            </Typography>
            <StyledButton
              onClick={handleConfirmSend}
              variant="contained"
              disabled={confirmLoading}
              sx={{ mt: 4 }}
            >
              {confirmLoading ? 'Sending...' : 'Confirm'}
            </StyledButton>
          </Box>
        )}

        {activeStep === 2 && (
          <Box textAlign="center" mt={4}>
            <Typography variant="h4" color="success.main">
              🎉 Success!
            </Typography>
            <Confetti numberOfPieces={200} />
            <StyledButton
              onClick={handleCloseSuccess}
              variant="contained"
              sx={{ mt: 4 }}
            >
              Finish
            </StyledButton>
          </Box>
        )}
      </StyledDrawer>

      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        message="Transaction Successful!"
        action={
          <StyledCloseIconButton onClick={handleCloseSuccess}>
            <CloseIcon />
          </StyledCloseIconButton>
        }
      />

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={errorMessage}
        action={
          <StyledCloseIconButton onClick={handleCloseError}>
            <CloseIcon />
          </StyledCloseIconButton>
        }
      />
    </>
  );
};

export default SendCrypto;
