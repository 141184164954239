import React, { useContext, useEffect, useState } from 'react';
import { CoinContext } from './CoinContext';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import { getIpInfo } from '../ton/ipInfo';
import { NotificationContainer,
  NotificationMessage,
  CloseButton, Wrapper, AnimationContainer, AnimationDot, LoadingMessage, LoadingText, LoadingContainer, ColoredText,CompletionLabel, UserRole, ButtonContainer, BeautifulBanner,GoButton,EarnAmount,Section,TaskInfo,TaskItem,TaskList,Title } from './taskStyle';
import axios from 'axios';
import TaskCreationForm from './TaskCreationForm';
import IframeWithTimer from './IframeWithTimer';
import {Header,Balance,BalanceText,BalanceAmount,Filters,FilterButton,TaskCard,TaskDetails,TaskTitle,TaskDescription,TaskEarn,Actions,ActionButton,FABIcon,FAB,FABText,FABTooltip
}from './taskstyletest';
import Skeleton from '@mui/material/Skeleton';

const TaskListing = () => {
  const appwriteService = new AsbekAppwriteService(); 
  const {hasMore, setTasks ,setNextTaskCursor, taskCursor, setTaskCursor, completeTask,tasks, taskType,setTaskType, loadingTheTasks, incrementTaskEarn, taskEarn, country, vpn} = useContext(CoinContext);
  const [showNotification, setShowNotification] = useState(false); // New state for notification
  const [notificationMessage, setNotificationMessage] = useState(''); // New state for notification
  const [loadingTasks, setLoadingTasks] = useState({});
  const [checking, setChecking] = useState(false)
  const [showCheck, setShowCheck] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");

  const tg = window.Telegram.WebApp;

  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

  const userId = String(usertg?.id).trim();

  const [editingTaskId, setEditingTaskId] = useState(null);

  const [formData, setFormData] = useState({ title: '', description: '', earnPlus: 0, url: '' , adsBudget: '', completeCount: 0});

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore && !loadingTheTasks) {
      console.log('Loading more tasks, current cursor:', taskCursor);
      setNextTaskCursor(taskCursor); // Fetch next set of tasks
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  // Filter tasks based on the search query
  const filteredTasks = tasks.filter(
    (task) =>
      task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort tasks based on the earning value
  const sortedTasks = filteredTasks.sort((a, b) => {
    if (sortOrder === "asc") {
      return a.earn - b.earn; // Sort by earn from low to high
    } else {
      return b.earn - a.earn; // Sort by earn from high to low
    }
  });

  
  const handleEditTask = (task) => {
    setFormData({ ...task }); // Populate the form with current task data
    setEditingTaskId(task.$id); // Set the ID of the task being edited
  };

  // Function to handle form submission

  const handleUpdateTask = async (e) => {
    e.preventDefault();
  
    // Convert form data to the correct types
    const updatedFormData = {
      ...formData,
      earnPlus: formData.earnPlus === "" ? 0 : parseFloat(formData.earnPlus), // Ensure earnPlus is a double
      completeCount: formData.completeCount === "" ? 0 : parseInt(formData.completeCount, 10), // Ensure completeCount is an integer
    };
  
    console.log("Form Data Before API Call:", updatedFormData);  // Debugging line
  
    try {
      await appwriteService.updateTask(updatedFormData); // Send updated data to Appwrite
      console.log("Task successfully updated!");  // Debugging line
      setEditingTaskId(null); // Close the edit form after saving
    } catch (error) {
      console.error("Failed to update task:", error);  // Debugging line
    }
  };
  
  

  // Function to cancel editing

  const handleCancelEdit = () => {
    setEditingTaskId(null); // Close the edit form
  };

  // Function to handle input changes

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "earnPlus" // Handle earnPlus as a floating-point number
          ? value === "" ? "" : parseFloat(value)
          : name === "completeCount" // Handle completeCount as an integer
          ? value === "" ? "" : parseInt(value, 10)
          : value, // Keep the rest as strings
    }));
  
    console.log(`Field Updated: ${name} = ${value}`);  // Debugging line for field changes
  };
  
  const saveCoinsToServer = async (taskEarn) => {
    ////console.log('Task earned:', taskEarn);

    try {
        // Fetch current document
        ////console.log('Fetching user document for user ID:', userId);
        const userDoc = await appwriteService.getDocument(userId);
        ////console.log('Current user document:', userDoc);

        const currentTaskEarn = parseFloat(userDoc.taskEarn) || 0;  // Ensure it's a float
       ////console.log('Current task earnings:', currentTaskEarn);

        const newTaskEarn = currentTaskEarn + parseFloat(taskEarn);  // Ensure taskEarn is added as float
        ////console.log('New task earnings after addition:', newTaskEarn);
        
        const now = Math.floor(Date.now() / 1000);
        ////console.log('Current timestamp:', now);

        await appwriteService.updateDocument(userId, {
            taskEarn: newTaskEarn,  // Send as float
        });
        ////console.log('Successfully updated task earnings on server for user ID:', userId);

        incrementTaskEarn(taskEarn);
        ////console.log('Incremented task earnings locally with:', taskEarn);
        
    } catch (error) {
        console.error('Error updating user data:', error, 'for user ID:', userId);
    }
};

  const handleTaskClick = async (taskId, taskUrl) => {
    try {
      if (taskUrl.includes('t.me')) {
        // For Telegram links, use window.location.href to open in the app directly
        // window.location.href = taskUrl;
        window.open(taskUrl, '_blank');
      } else {
        // For other links, use window.open to open in a new tab
        window.Telegram.WebApp.openLink(taskUrl);
      }
      setShowCheck((prev) => ({ ...prev, [taskId]: true }));
      // setShowCheck(true)
    } catch (error) {
      console.error('Failed to complete task:', error.message);
    }
  };

  const checkMembership = async (taskEarn,taskUrl, taskId) => {
    setLoadingTasks((prev) => ({ ...prev, [taskId]: true }));
    
    try {
      // Remove 'https://t.me/' if it's a Telegram link
      const isTelegramLink = taskUrl.startsWith('https://t.me/') || taskUrl.startsWith('http://t.me/') || taskUrl.startsWith('https://telegram.me/') || taskUrl.startsWith('http://telegram.me/');
      if (!isTelegramLink) {
        ////console.log('The URL is not a Telegram link.');
        await appwriteService.markTaskAsCompleted(taskId, userId);
          completeTask(taskId, userId);
          saveCoinsToServer(taskEarn)
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
          setLoading(false);
          
        setShowNotification(true);
        setNotificationMessage("Well done you have succesfully Completed your task! ")
        setTimeout(() => {
          setShowNotification(false);
        }, 7000);
      } else {
        const username = taskUrl.startsWith('https://t.me/')
                ? taskUrl.replace('https://t.me/', '').split(/[/\?]/)[0] // Split on either '/' or '?'
                : taskUrl.replace('http://t.me/', '').split(/[/\?]/)[0];

        const isBot = username.endsWith('bot');
        if(!isBot){
                
        // const username = taskUrl.startsWith('https://t.me/') 
        //       ? taskUrl.replace('https://t.me/', '') 
        //       : taskUrl;
        ////console.log('username is', username);
        const response = await fetch(`https://66f46cdd4587b0b3ee6b.appwrite.global/check_membership/@${username}/${userId}`);
        const data = await response.json();
        
        ////console.log('checking the data :', data); // Log before calling completeTask
        ////console.log('status :', data.status);
        ////console.log('error :', data.error);
        if (data.error == 'Failed to retrieve bot status in the channel/group.'){
          ////console.log('bot is not even member in the channel');
          // setShowNotification(true);
          // setNotificationMessage("Failed to retrieve  status")
          // setTimeout(() => {
          //   setShowNotification(false);
          // }, 7000);
          await appwriteService.markTaskAsCompleted(taskId, userId);
          completeTask(taskId, userId);
          saveCoinsToServer(taskEarn)
          setLoading(false);
          setShowCheck((prev) => ({ ...prev, [taskId]: false }));
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
        setShowNotification(true);
        setNotificationMessage("Well done you have succesfully Completed your task! ")
        setTimeout(() => {
          setShowNotification(false);
        }, 7000);
        }else if (data.error == 'The bot is not an admin in this channel or group.'){
          ////console.log('bot is not admin');
          await appwriteService.markTaskAsCompleted(taskId, userId);
          completeTask(taskId, userId);
          saveCoinsToServer(taskEarn)
          setLoading(false);
          setShowCheck((prev) => ({ ...prev, [taskId]: false }));
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
        setShowNotification(true);
        setNotificationMessage("Well done you have succesfully Completed your task! ")
        setTimeout(() => {
          setShowNotification(false);
        }, 7000);
        //   setShowNotification(true);
        // setNotificationMessage("The bot is not an admin in this channel or group. ")
        // setTimeout(() => {
        //   setShowNotification(false);
        // }, 7000);
        } else {

          // Show notification if status is member, administrator, or creator
      if (['member', 'administrator', 'creator'].includes(data.status)) {
        ////console.log('stattsghjk', data.status )
        await appwriteService.markTaskAsCompleted(taskId, userId);
          completeTask(taskId, userId);
          saveCoinsToServer(taskEarn)
          setLoading(false);
          setShowCheck((prev) => ({ ...prev, [taskId]: false }));
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
        setShowNotification(true);
        setNotificationMessage("Well done you have succesfully Completed your task! ")
        setTimeout(() => {
          setShowNotification(false);
        }, 7000);
      } else {
        ////console.log('stattsghjk', data.status )
        setShowCheck((prev) => ({ ...prev, [taskId]: false }));
        setShowNotification(true);
        setNotificationMessage("Oops! You haven't Joined Go Join ")
        setTimeout(() => {
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
          setShowNotification(false);
        }, 7000);
      }
          ////console.log('well completed');
          
        }
      } else{
        ////console.log('The URL is not a Telegram link.');
        await appwriteService.markTaskAsCompleted(taskId, userId);
          completeTask(taskId, userId);
          saveCoinsToServer(taskEarn)
          setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
          setLoading(false);
          setShowNotification(true);
        setNotificationMessage("Well done you have succesfully Completed your task! ")
        setTimeout(() => {
          setShowNotification(false);
        }, 7000);
      }
      
      // If you want to mark the task as completed, you can uncomment the line below
      
      
      // Open the task's URL in a new tab
      // window.open(taskUrl, '_blank');
      }
    } catch (error) {
      console.error('Failed to complete task:', error.message);
      setShowNotification(true);
        setNotificationMessage("Failed to complete task! ")
        setTimeout(() => {
          setShowNotification(false);
        }, 7000);
    } finally {
      setLoadingTasks((prev) => ({ ...prev, [taskId]: false }));
      setLoading(false);
    }
  };
  
  const closeNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 7000); // Hide after 7 seconds
      return () => clearTimeout(timer);
    }
  }, [showNotification]);
  const getLoadingMessage = (type) => {
    switch (type) {
      case "notcompleted":
        return "Loading Profitable Tasks...";
      case "completed":
        return "Loading Completed Tasks...";
      case "mytasks":
        return "Loading Your Tasks...";
      default:
        return "Loading Tasks...";
    }
  };

  const getEmptyMessage = (type) => {
    switch (type) {
      case "notcompleted":
        return "No tasks are currently available. Please check back soon for new opportunities.";
      case "completed":
        return "You haven't completed any tasks yet. Start now to earn rewards instantly!";
      case "mytasks":
        return "Create your own tasks and let others help you achieve your goals.";
      default:
        return "Loading tasks, please wait...";
    }
  };
  
  return (
    <Section onScroll={handleScroll}>
      
        {showNotification && (
       <NotificationContainer showNotification={showNotification}>
       <NotificationMessage>{notificationMessage}</NotificationMessage>
       <CloseButton onClick={closeNotification}>OK</CloseButton>
     </NotificationContainer>
      )}


<center><Balance><BalanceText>Your Balance</BalanceText>
<BalanceAmount >
  <center>
{isNaN(taskEarn) || taskEarn === null ? (
  <Skeleton variant="text" width={100} />
) : (
  taskEarn
)}
</center>
 </BalanceAmount>
 <p>Complete Tasks and earn money instantly!</p> 
  </Balance>
  <Filters>
  {['notcompleted', 'completed', 'mytasks'].map((category) => (
    <FilterButton
      key={category}
      active={taskType === category}
      
       // Pass the category directly
       onClick={() => {
        console.log('tuiunijknskd')
      setTasks([]); // Clear tasks before setting the taskType
      setNextTaskCursor(null)

      setTaskType(category); // Update the taskType
    }}
      style={{
        padding: '6px 10px',
        borderRadius: '12px',
        border: '1px solid #ccc',
        fontSize: '12px',
        minWidth: '120px',
        transition: 'all 0.2s ease',
        background: taskType === category 
          ? 'linear-gradient(135deg, #4caf50, #2196f3)' // Gradient for active state
          : 'linear-gradient(to right, #fafafa, #e0e0e0)', // Gradient for inactive state
        color: taskType === category ? '#333' : '#666',
      }}
    >
      {category === 'notcompleted' 
        ? 'For You' 
        : category === 'mytasks' 
        ? 'My Tasks' 
        : category.charAt(0).toUpperCase() + category.slice(1)}
    </FilterButton>
  ))}

  {userId === '6307965462' && (
    <FilterButton
      key="all"
      active={taskType === 'all'}
      onClick={() => setTaskType('all')}
      style={{
        padding: '6px 10px',
        borderRadius: '12px',
        border: '1px solid #ccc',
        fontSize: '12px',
        minWidth: '120px',
        transition: 'all 0.2s ease',
        background: taskType === 'all'
          ? 'linear-gradient(135deg, #4caf50, #2196f3)' // Gradient for active state
          : 'linear-gradient(to right, #fafafa, #e0e0e0)', // Gradient for inactive state
        color: taskType === 'all' ? '#333' : '#666',
      }}
    >
      All
    </FilterButton>
  )}
</Filters>

<div style={{ margin: '20px' }}></div>
<TaskCreationForm></TaskCreationForm>
</center>


{/* Filter Buttons */}
<center>

<div style={{ margin: '20px' }}>
  {/* Search Bar */}
  {/* <input
    type="text"
    placeholder="Search tasks..."
    value={searchQuery}
    onChange={handleSearchChange}
    style={{
      padding: '8px 12px',
      marginBottom: '15px',
      width: '100%',
      maxWidth: '350px',
      display: 'block',
      margin: '10 auto',
      borderRadius: '15px',
      border: '1px solid #ddd',
      fontSize: '14px',
      transition: 'all 0.2s ease',
    }}
  /> */}
</div>


{/* Responsive Styling */}
<style>
  {`
    @media (max-width: 600px) {
      input {
        max-width: 90%;
      }

      select {
        width: 45%;  /* Equal width for dropdowns */
        max-width: 150px;
      }
    }
  `}
</style>      
        </center>

        {loadingTheTasks &&
     <Wrapper>
     <AnimationContainer>
       <AnimationDot />
       <AnimationDot />
       <AnimationDot />
     </AnimationContainer>
     <LoadingMessage>{getLoadingMessage(taskType)}</LoadingMessage>
   </Wrapper>
 
}

      
  
        { (
      tasks.length === 0 ? (
        // Show message when no tasks are available
        <p>{getEmptyMessage(taskType)}</p>
      ) : (
       
      <TaskList >
        {tasks.map(task => (
          <TaskItem key={task.$id}>
            <TaskInfo>
              <TaskTitle>{task.title}</TaskTitle>
              <TaskDescription>{task.description}</TaskDescription>
              <EarnAmount>Earn {task.earnPlus}  </EarnAmount>
              
              {userId === task.owner && (
                <div>
                  <p>Your Task</p>
                  <p>Completed Users: {task.completeCount}</p>
                  <p>Status: {task.status}</p>
                </div>
              )}
              
              {userId === '6307965462' && (
                <div>
                <CompletionLabel label={taskType}>{taskType}</CompletionLabel>
                <p>Completed Users: {task.completeCount}</p>
                <button onClick={() => handleEditTask(task)}>Edit</button>
                </div>
              )}
              
              {/* Render the edit form only for the task being edited */}
          {editingTaskId === task.$id && (
            <form onSubmit={handleUpdateTask} style={{ marginTop: '10px', padding: '10px', border: '1px solid #ccc' }}>
              <div>
                <label>
                  Title:
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  Description:
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  Earn:
                  <input
                    type="number"
                    name="earnPlus"
                    value={formData.earnPlus === "" ? "" : formData.earnPlus}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  URL:
                  <input
                    type="url"
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                adsBudget:
                  <input
                    type="text"
                    name="adsBudget"
                    value={formData.adsBudget}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                completeCount:
                <input
                  type="number"
                  name="completeCount"
                  value={formData.completeCount === "" ? "" : formData.completeCount}
                  onChange={handleChange}
                  required
                />
                </label>
              </div>
              <button type="submit">Save</button>
              <button type="button" onClick={handleCancelEdit}>Cancel</button>
            </form>
          )}
              
              {taskType == 'completed' ? (
              
              <GoButton onClick={() => handleTaskClick(task.$id, task.url)}>Go</GoButton>
            ) : (
              <ButtonContainer>
                <button 
                    onClick={() => checkMembership(task.earnPlus, task.url, task.$id)} 
                    disabled={loadingTasks[task.$id] || Object.values(loadingTasks).some(loading => loading) || !showCheck[task.$id]}>
                    {loadingTasks[task.$id] ? 'Checking...' : 'Check'}
                  </button>
              <GoButton onClick={() => handleTaskClick(task.$id, task.url)}>Go</GoButton>
              </ButtonContainer>
              
            )}
          
              {/* <p>Completed Users: {task.completedUsersCount}</p>  */}
            </TaskInfo>
            

            
            
          </TaskItem>
        ))}
      </TaskList>
      
       )
      )}

{loadingTheTasks && 
  
<LoadingText>Loading more tasks...</LoadingText>
}
    </Section>
   
  );
};

export default TaskListing;
