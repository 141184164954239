import React, { useContext, useEffect, useState } from 'react';
import { CoinContext } from './CoinContext';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import GoalPage from './goal';

const asbekService = new AsbekAppwriteService();
function SomeComponent() {
  const {
    tasks,
    competitionStartDate,
    ergaa,
    theCoinValue,
    theWeeklyClickCount,
    theClickCount,
    theDailyClickCount,
    theMonthlyClickCount,
    incrementCoinValue,
    incrementWeeklyClickCount,
    incrementClickCount,
    incrementDailyClickCount,
    incrementMonthlyClickCount,
    theUser,
    theLastReset,
    theUserId,
    theUsername,
    theFirstName,
    theLastName,
    theInvitedBy,
    theInvitationLink,
    theAccountNumber,
    theAccountName,
    thePhoneNumber,
    theLastDailyReset,
    theLastWeeklyReset,
    theLastMonthlyReset,
    theBlock,
    theClickHistory,
    theOnline,
    theLastWeekClickCount,
    theSessionId,
    theWallet,
    theSessionTime
  } = useContext(CoinContext);
  
  const [timeLeft, setTimeLeft] = useState('');
  const [newStartDate, setNewStartDate] = useState(competitionStartDate); // Hold the new date in state
  const [humanReadableStart, setHumanReadableStart] = useState('');

  useEffect(() => {
    const startTimeUTC = new Date(competitionStartDate);  // Convert start time to Date object
    setHumanReadableStart(startTimeUTC.toLocaleString());  // Convert to human-readable format

    const updateTimer = () => {
      const currentTimeUTC = Date.now();
      const difference = startTimeUTC.getTime() - currentTimeUTC;

      if (difference > 0) {
        // Countdown to start
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setTimeLeft(`Starts in: ${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        // Time since start
        const elapsed = Math.abs(difference);
        const days = Math.floor(elapsed / (1000 * 60 * 60 * 24));
        const hours = Math.floor((elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);
        setTimeLeft(`Started: ${days}d ${hours}h ${minutes}m ${seconds}s ago`);
      }
    };

    updateTimer(); // Initial call to set the time immediately
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);  
  }, [competitionStartDate]);

  const localsave = async () => {
    const currentTime = Date.now();
    localStorage.setItem('lastServerUpdate', currentTime);
    localStorage.setItem(`localCoinValue_${445929610}`, 1);
    localStorage.setItem(`localClickCount_${445929610}`, 1);
    console.log(currentTime, "Saved successfully");
  }

  // Function to handle updating start date
  const handleUpdateStartDate = async () => {
    const data = { competitionStartDate: newStartDate };  // Prepare the data with the new date

    try {
      const updatedDoc = await asbekService.updateStartDate(data);  // Call the updateStartDate function
      console.log('Updated document:', updatedDoc);
    } catch (error) {
      console.error('Failed to update start date:', error);
    }
  };


  return (
    <div style={{ backgroundColor: '#f0f0f0', padding: '20px' }}>
      <button onClick={localsave}>SAve to kj,</button>
      <h2>Competition Start Time</h2>
      <p>{humanReadableStart !== '' ? humanReadableStart : 'Loading...'}</p>
      <GoalPage></GoalPage>
      <h2>Competition Timer</h2>
      <p>{timeLeft}</p>

      <h1>{competitionStartDate}</h1>  {/* Assuming this is to show raw ISO date */}

      <label>New Start Date (ISO 8601): </label>
      <input 
        type="datetime-local" 
        value={newStartDate} 
        onChange={(e) => setNewStartDate(e.target.value)} 
      />
      <button onClick={handleUpdateStartDate}>Update Start Date</button>

      <h1>{ergaa !== '' ? ergaa : 'Loading...'}</h1>
      <h1>Coin Value</h1>
      <p>{theCoinValue !== null ? theCoinValue : 'Loading...'}</p>

      <h2>Weekly Click Count</h2>
      <p>{theWeeklyClickCount !== null ? theWeeklyClickCount : 'Loading...'}</p>

      <h2>Click Count</h2>
      <p>{theClickCount !== null ? theClickCount : 'Loading...'}</p>

      <h2>Daily Click Count</h2>
      <p>{theDailyClickCount !== null ? theDailyClickCount : 'Loading...'}</p>

      <h2>Monthly Click Count</h2>
      <p>{theMonthlyClickCount !== null ? theMonthlyClickCount : 'Loading...'}</p>

      <h2>Last Reset</h2>
      <p>{theLastReset !== null ? new Date(parseInt(theLastReset) * 1000).toLocaleString() : 'Loading...'}</p>

      <h2>User Details</h2>
      <p>User Tg ID: {theUser !== null ? theUser.id : 'Loading...'}</p>
      <p>User ID: {theUserId !== null ? theUserId : 'Loading...'}</p>
      <p>Username: {theUsername !== null ? theUsername : 'Loading...'}</p>
      <p>First Name: {theFirstName !== null ? theFirstName : 'Loading...'}</p>
      <p>Last Name: {theLastName !== null ? theLastName : 'Loading...'}</p>
      <p>Invited By: {theInvitedBy !== null ? theInvitedBy : 'Loading...'}</p>
      <p>Invitation Link: {theInvitationLink !== null ? theInvitationLink : 'Loading...'}</p>
      <p>Account Number: {theAccountNumber !== null ? theAccountNumber : 'Loading...'}</p>
      <p>Account Name: {theAccountName !== null ? theAccountName : 'Loading...'}</p>
      <p>Phone Number: {thePhoneNumber !== null ? thePhoneNumber : 'Loading...'}</p>

      <h2>Session Details</h2>
      <p>Session ID: {theSessionId !== null ? theSessionId : 'Loading...'}</p>
      <p>Wallet: {theWallet !== null ? theWallet : 'Loading...'}</p>
      <p>Session Time: {theSessionTime !== null ? theSessionTime : 'Loading...'}</p>

      <h2>Other Info</h2>
      <p>Block: {theBlock !== null ? (theBlock ? 'Yes' : 'No') : 'Loading...'}</p>
      <p>Online: {theOnline !== null ? theOnline : 'Loading...'}</p>

      <h2>Click History</h2>
      <p>{theClickHistory.length > 0 ? theClickHistory.join(', ') : 'No click history'}</p>

      <div>
        <button onClick={incrementCoinValue}>Increment Coin Value</button>
        <button onClick={incrementWeeklyClickCount}>Increment Weekly Click Count</button>
        <button onClick={incrementClickCount}>Increment Click Count</button>
        <button onClick={incrementDailyClickCount}>Increment Daily Click Count</button>
        <button onClick={incrementMonthlyClickCount}>Increment Monthly Click Count</button>
      </div>
    </div>
  );
}

export default SomeComponent;
