
import React, {useContext, useState, useEffect } from 'react';
import { CoinContext } from '../contextdtatashare/CoinContext';
import styled, { keyframes } from 'styled-components';
import {Container,Divider,LeaderboardContainer,LoadingContainer,ToggleContainer,ToggleItem, StarsWrapper , Star,WhiteCard, TopContainer, Leaderboard, Profile, RowContainer, RowLabel, RowValue, Badge, ListProfile } from '../asbek/top_style';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import asbek from '../assets/asbek.jpg';
import asbek1 from '../assets/asbek1.jpg';
import asbek2 from '../assets/asbek2.jpg';
import asbek3 from '../assets/asbek3.jpg';
import asbek4 from '../assets/asbek4.jpg';
import asbek5 from '../assets/asbek5.jpg';
import LoadingSpinner from './LoadingSpinner';
import HelpComponent from './help';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IconButton } from '@material-ui/core';
import TopUsers from '../api/top.js';
// import AppwriteService from '../api/scripts';
import AppwriteService from '../api/liveWeekly';
import ApiService from '../api/ApiService';
import TransactionPage from './transaction_page';
import WalletConnectPage from '../ton/WalletConnectPage';
import WeeklyPage from '../api/weeklyLive';
import EarnSection from './EarnSection';
import GoalPage from '../contextdtatashare/goal';
import { getIpInfo } from '../ton/ipInfo';
import { BeautifulBanner } from '../contextdtatashare/taskStyle';
const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

// Create a Skeleton component with the loading animation
const Skeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  height: 17px;
  width: 100px; /* Adjust the width as needed */
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const ImageSkeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const SkeletonName = styled(Skeleton)`
  height: 20px;
  width: 100px;
  margin-top: 5px;
`;

const SkeletonCoins = styled(Skeleton)`
  height: 20px;
  width: 50px;
  margin-top: 5px;
`;

const appwriteService = new AsbekAppwriteService();
const formatValue = (value) => {
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(1)}B`;
  }
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(1)}M`;
  }
  if (value >= 1_000) {
    return `${(value / 1_000).toFixed(1)}k`;
  }
  return value.toString();
};

const TopSection = () => {
    const {
        theTopUsers,
        theTop3Users,
        resetClickCount,
        theCoinValue,
        theWeeklyClickCount,
        theClickCount,
        theDailyClickCount,
        theMonthlyClickCount,
        incrementCoinValue,
        incrementWeeklyClickCount,
        incrementClickCount,
        incrementDailyClickCount,
        incrementMonthlyClickCount,
        theUser,
        theLastReset
      } = useContext(CoinContext);
    const [userRank, setUserRank] = useState(null);
    const [top3Users, setTop3Users] = useState([]);
    const [user, setUser] = useState(null);
    const [coinValue, setCoinValue] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [message, setMessage] = useState(null);
    const [filters, setFilters] = useState({ minScore: 0, maxScore: 100 });
    const [documentCount, setDocumentCount] = useState(0);
    const [leaderboardType, setLeaderboardType] = useState('allTime'); // State for toggling leaderboard type
    const [loading, setLoading] = useState(false);
    const [startParam, setStartParam] = useState(null);
    const [countdown, setCountdown] = useState({ minutes: 0, seconds: 0 }); // Initial countdown value in seconds
    const [weeklyClickCount, setWeeklyClickCount] = useState(null);
    const [country, setCountry] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    // Function to get the country
    const getCountry = async () => {
      try {
        const ipInfo = await getIpInfo();
        if (ipInfo) {
          setCountry(ipInfo.country);
          // Show banner if the country is Ethiopia
          if (ipInfo.country === 'Ethiopia') {
            setShowBanner(true);
          } else {
            setShowBanner(false); // Hide banner if the country is not Ethiopia
          }
        }
      } catch (error) {
        console.error('Error fetching country info:', error.message);
      }
    };

    // Fetch country initially
    getCountry();

    // Set up polling to fetch country every 30 seconds
    const intervalId = setInterval(getCountry, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
    const generateStars = (numStars) => {
        let stars = [];
        for (let i = 0; i < numStars; i++) {
          stars.push(<Star key={i}>💸</Star>);
        }
        return stars;
      };
      
      const Stars = ({ numStars }) => {
        return <StarsWrapper>{generateStars(numStars)}</StarsWrapper>;
      };
      
    return (
        <>
        <TopContainer>
            {/* <div>
            <h1>Countdown to Cache Expiry: {countdown.minutes} minutes {countdown.seconds} seconds</h1>
        </div> */}

            <Stars numStars={40} />
            <Leaderboard>
            {/* {showBanner && (
  <BeautifulBanner>
    <p>Turn on your VPN to access the content. This banner will stay until your country is not Ethiopia.</p>
  </BeautifulBanner>
)} */}
            {theTop3Users.length > 0 ? (
                theTop3Users[1].username ? (
                    // If the second user has a username, render the profile with a link to their Telegram profile
                    <a
                        href={`https://t.me/${theTop3Users[1].username}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <Profile>
                            <div className="image-wrapper">
                                <img src={asbek2} alt="Profile 2" />
                                <div className="badge">2nd</div>
                            </div>
                            <name>{theTop3Users[1].first_name}</name>
                            <div>{theTop3Users[1].weeklyClickCount}</div>
                        </Profile>
                    </a>
                ) : (
                    // If the second user does not have a username, render the profile without the link
                    <Profile>
                        <div className="image-wrapper">
                            <img src={asbek2} alt="Profile 2" />
                            <div className="badge">2nd</div>
                        </div>
                        <name>{theTop3Users[1].first_name}</name>
                        <div>{theTop3Users[1].weeklyClickCount}</div>
                    </Profile>
                )
            ) : (
                // If the theTop3Users array is empty, render a placeholder image
                <Profile>
                    <div className="image-wrapper">
                        <img src={asbek2} alt="Profile 2" />
                        <div className="badge">2nd</div>
                    </div>
                    <SkeletonName/>
                    <SkeletonCoins/>
                </Profile>
            )}

    {theTop3Users.length > 1 ? (
        theTop3Users[0].username ? (
            <a
                href={`https://t.me/${theTop3Users[0].username}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <Profile background={'#add8e6'} width={100} height={100}>
                    <div className="image-wrapper">
                        <span role="img" aria-label="King" className="king-emoji">👑</span>
                        <img src={asbek5} alt="Profile 1" />
                        <div className="badge">1st</div>
                    </div>
                    <name>{theTop3Users[0].first_name}</name>
                    <div>{theTop3Users[0].weeklyClickCount}</div>
                </Profile>
            </a>
        ) : (
            <Profile background={'#add8e6'} width={100} height={100}>
                <div className="image-wrapper">
                    <span role="img" aria-label="King" className="king-emoji">👑</span>
                    <img src={asbek5} alt="Profile 1" />
                    <div className="badge">1st</div>
                </div>
                <name>{theTop3Users[0].first_name}</name>
                <div>{theTop3Users[0].weeklyClickCount}</div>
            </Profile>
        ) 
    ): (
        // If the theTop3Users array is empty, render a placeholder image
        <Profile>
            <div className="image-wrapper">
                    <span role="img" aria-label="King" className="king-emoji">👑</span>
                    <img src={asbek5} alt="Profile 1" />
                    <div className="badge">1st</div>
                </div>
            <SkeletonName/>
            <SkeletonCoins/>
        </Profile>
    )}
    {theTop3Users.length > 2 ? (
        theTop3Users[2].username ? (
            <a
                href={`https://t.me/${theTop3Users[2].username}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <Profile>
                    <div className="image-wrapper">
                        <img src={asbek3} alt="Profile 1" />
                        <div className="badge">3rd</div>
                    </div>
                    <name>{theTop3Users[2].first_name}</name>
                    <div>{theTop3Users[2].weeklyClickCount}</div>
                </Profile>
            </a>
        ) : (
            <Profile>
                <div className="image-wrapper">
                    <img src={asbek3} alt="Profile 1" />
                    <div className="badge">3rd</div>
                </div>
                <name>{theTop3Users[2].first_name}</name>
                <div>{theTop3Users[2].weeklyClickCount}</div>
            </Profile>
        )
    ): (
        // If the theTop3Users array is empty, render a placeholder image
        <Profile>
            <div className="image-wrapper">
                <img src={asbek3} alt="Profile 1" />
                <div className="badge">3rd</div>
            </div>
            <SkeletonName/>
            <SkeletonCoins/>
        </Profile>
    )}
        </Leaderboard>
{/* <WalletConnectPage/> */}
           <EarnSection></EarnSection>
           {/* <GoalPage></GoalPage> */}
    </TopContainer>

           
      {/* <Divider></Divider> */}
      <WeeklyPage/>
      {/* <TopUsers /> */}
        </>
    );
};

export default TopSection;
