// src/ipInfo.js
import axios from 'axios';

export const getIpInfo = async () => {
  try {
    const response = await axios.get('http://ip-api.com/json');
    
    // Debugging console output
    console.debug('IP Info Response:', response.data);
    
    return response.data;
  } catch (error) {
    // Detailed error logging
    console.error('Error fetching IP info:', error);
    
    return null;
  }
};
