import React, { useState, useEffect } from 'react';
import { Client, Databases } from 'appwrite';
import { ASBEK_ENDPOINT, ASBEK_PROJECT_ID, ASBEK_DATABASE_ID, ASBEK_CLICK_HISTORY_COLLECTION_ID } from './config.js';
import * as Appwrite from 'appwrite';
// Initialize Appwrite client
const client = new Client()
    .setEndpoint(ASBEK_ENDPOINT)
    .setProject(ASBEK_PROJECT_ID);

const databases = new Databases(client);

const userIdToTrack = '5430315389'; // User ID to track

const RealTimeClickRank = () => {
    const [clickCount, setClickCount] = useState(null);
    const [rank, setRank] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Subscribe to changes in the click history collection
        const unsubscribe = client.subscribe(
            `databases.${ASBEK_DATABASE_ID}.collections.${ASBEK_CLICK_HISTORY_COLLECTION_ID}.documents`,
            (response) => {
                console.log('Subscription response:', response); // Debug log
                if (response.events.includes('databases.*.collections.*.documents.*')) {
                    fetchClickData();
                }
            }
        );

        // Cleanup function to unsubscribe on component unmount
        return () => {
            unsubscribe(); // Ensure proper unsubscription
        };
    }, []);

    const fetchClickData = async () => {
        try {
            console.log('Fetching documents...');
            let allDocuments = [];
            let offset = 0;
            const limit = 25; // Number of documents to fetch per request
    
            // Fetch documents in a paginated manner
            while (true) {
                const result = await databases.listDocuments(
                    ASBEK_DATABASE_ID,
                    ASBEK_CLICK_HISTORY_COLLECTION_ID,
                    [ 
                        Appwrite.Query.limit(limit),
                        Appwrite.Query.offset(offset)
                    ]
                );
    
                console.log('Documents fetched:', result); // Debug log
    
                if (!result.documents || result.documents.length === 0) {
                    break; // No more documents to fetch
                }
    
                allDocuments = allDocuments.concat(result.documents);
    
                // If the number of fetched documents is less than the limit, we have reached the end
                if (result.documents.length < limit) {
                    break;
                }
    
                offset += limit; // Update offset for the next request
            }
    
            if (allDocuments.length === 0) {
                console.log('No documents found.');
                setClickCount('No data found');
                setRank(null);
                return;
            }
    
            // Filter documents to find the specific user
            const userDocuments = allDocuments.filter(doc => doc.userId === userIdToTrack);
    
            if (userDocuments.length === 0) {
                console.log('No documents found for the user.');
                setClickCount('No data found');
                setRank(null);
                return;
            }
    
            // Sum up the clicks for the user
            const totalClicks = userDocuments.reduce((sum, doc) => sum + (doc.clicks || 0), 0);
    
            // Calculate the rank based on clicks
            const sortedDocuments = allDocuments
                .filter(doc => doc.userId && typeof doc.userId === 'string') // Ensure userId is present and is a string
                .sort((a, b) => b.clicks - a.clicks);
    
            const userRank = sortedDocuments.findIndex(doc => doc.userId === userIdToTrack) + 1;
    
            console.log(`User ${userIdToTrack} total clicks:`, totalClicks);
            console.log(`User ${userIdToTrack} rank:`, userRank);
    
            setClickCount(totalClicks);
            setRank(userRank > 0 ? userRank : 'Not found');
        } catch (err) {
            console.error('Error fetching documents:', err);
            setError(err.message);
        }
    };
    

    return (
        <div>
            <h1>User Click Data</h1>
            {error && <p>Error: {error}</p>}
            {clickCount === null || rank === null ? (
                <p>Loading...</p>
            ) : (
                <p>
                    User {userIdToTrack} has {clickCount} clicks and is ranked #{rank} based on clicks.
                </p>
            )}
        </div>
    );
};

export default RealTimeClickRank;
