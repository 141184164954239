import React, {useContext, useEffect, useState } from 'react';
import { CoinContext } from '../contextdtatashare/CoinContext';
import styled from 'styled-components';
import {
    updateAccountDetails ,
    AccountDetailsForm,
    SaveButton,
    SavedMessage,
    PageContainer,
    FilterContainer,
    FilterButton,
    LoadingContainer,
    ContentContainer,
    TransactionCard,
    TransactionRow,
    RowLabel,
    RowValue,
    NoDataMessage,
    Summary,
    CollapsibleDetails,
    FieldSet,
    FieldContainer,
    Label,
    Input,
    ButtonContainer
} from '../asbek/history_style';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import LoadingSpinner from './LoadingSpinner';
import { ASBEK_HISTORY_COLLECTION_ID } from '../api/config';
import { CopyButton } from './copy_button';
import TransactionPage from './transaction_page';
import IDTranslator from '../api/IDTranslator';
const appwriteService = new AsbekAppwriteService(ASBEK_HISTORY_COLLECTION_ID);
const proappwriteService = new AsbekAppwriteService();

const HistoryPage = () => {
    const {
        updateAccountDetails,
        ergaa,
        theCoinValue,
        theWeeklyClickCount,
        theClickCount,
        theDailyClickCount,
        theMonthlyClickCount,
        incrementCoinValue,
        incrementWeeklyClickCount,
        incrementClickCount,
        incrementDailyClickCount,
        incrementMonthlyClickCount,
        theUser,
        theLastReset,
        theUserId,
        theUsername,
        theFirstName,
        theLastName,
        theInvitedBy,
        theInvitationLink,
        theAccountNumber,
        theAccountName,
        thePhoneNumber,
        theLastDailyReset,
        theLastWeeklyReset,
        theLastMonthlyReset,
        theBlock,
        theClickHistory,
        theOnline,
        theLastWeekClickCount,
        theSessionId,
        theWallet,
        theSessionTime
      } = useContext(CoinContext);
    
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [filter, setFilter] = useState('all');
    const [message, setMessage] = useState(null);
    const [expandedId, setExpandedId] = useState(null);
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [accountDetails, setAccountDetails] = useState({
        accountNumber: '',
        accountName: '',
        phoneNumber: ''
    });
    const [savedMessage, setSavedMessage] = useState('');

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.ready();
        const initDataUnsafe = tg.initDataUnsafe;
        const user = initDataUnsafe?.user;
    
        if (user) {
            setUser(user);
            setUserId(user.id);
            setAccountDetails({
                accountNumber: theAccountNumber,
                accountName: theAccountName,
                phoneNumber: thePhoneNumber
            });
        }
    
        fetchAllDocuments();
    }, [theAccountNumber, theAccountName, thePhoneNumber]);
    
    const fetchAllDocuments = async () => {
        const tg = window.Telegram.WebApp;
        tg.ready();
        console.log('ready');
        const initDataUnsafe = tg.initDataUnsafe;
        const user = initDataUnsafe?.user;
        console.log('fetching', userId);
        try {
            setLoading(true);
            const allDocuments = await appwriteService.getAllHistory(user.id);
            setDocuments(allDocuments.documents || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching documents:', error);
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    const filteredDocuments = documents.filter((doc) => {
        const tg = window.Telegram.WebApp;
        tg.ready();
        console.log('ready');
        const initDataUnsafe = tg.initDataUnsafe;
        const user = initDataUnsafe?.user;
        console.log('fetching', userId);

        if (filter === 'sent') {
            return doc.sender === String(user.id);
        } else if (filter === 'received') {
            return doc.recipient === String(user.id);
        } else {
            return true;
        }
    });

    
    const toggleDetails = (transactionId) => {
        setExpandedId(expandedId === transactionId ? null : transactionId);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAccountDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await proappwriteService.updateDocument(userId, accountDetails);
            setSavedMessage('Account details saved successfully!');
            updateAccountDetails(accountDetails);
            setTimeout(() => {
                setSavedMessage('');
            }, 3000);
        } catch (error) {
            console.error('Error saving account details:', error);
        }
    };

    return (
        <PageContainer>
            <ContentContainer>
                <AccountDetailsForm onSubmit={handleSubmit}>
                    <FieldSet>
                        <legend>Account Details</legend>
                        <FieldContainer>
                            <Label htmlFor="accountNumber">Account Number:</Label>
                            <Input
                                type="text"
                                id="accountNumber"
                                name="accountNumber"
                                placeholder="Enter your account Number (CBE only)"
                                value={accountDetails.accountNumber}
                                onChange={handleInputChange}
                                required
                            />
                        </FieldContainer>
                        <FieldContainer>
                            <Label htmlFor="accountName">Account Name:</Label>
                            <Input
                                type="text"
                                id="accountName"
                                name="accountName"
                                placeholder="Enter your name as specified on book"
                                value={accountDetails.accountName}
                                onChange={handleInputChange}
                                required
                            />
                        </FieldContainer>
                        <FieldContainer>
                            <Label htmlFor="phoneNumber">Phone Number:</Label>
                            <Input
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Your phone number"
                                value={accountDetails.phoneNumber}
                                onChange={handleInputChange}
                                required
                            />
                        </FieldContainer>
                        <ButtonContainer>
                            <SaveButton type="submit">Save</SaveButton>
                        </ButtonContainer>
                        {savedMessage && <SavedMessage>{savedMessage}</SavedMessage>}
                    </FieldSet>
                    <CopyButton inviteLink={userId} title=''/>
                </AccountDetailsForm>
                <FilterContainer>
                <FilterButton active={filter === 'all'} onClick={() => setFilter('all')}>All</FilterButton>
                <FilterButton active={filter === 'sent'} onClick={() => setFilter('sent')}>Sent</FilterButton>
                <FilterButton active={filter === 'received'} onClick={() => setFilter('received')}>Received</FilterButton>
            </FilterContainer>
                {loading ? (
                    <LoadingContainer>
                        <LoadingSpinner />
                    </LoadingContainer>
                ) : filteredDocuments.length > 0 ? (
                    filteredDocuments.map(doc => (
                        <TransactionCard key={doc.$id} onClick={() => toggleDetails(doc.$id)}>
                            <Summary> {doc.sender === String(userId) ? `Sent ${doc.amount} ${doc.currency}` : `Received ${doc.amount} ${doc.currency}`}</Summary>
                            {expandedId === doc.$id && (
                                <CollapsibleDetails>
                                <TransactionRow>
                                    <RowLabel>{doc.sender === String(userId) ? `To` : `From`}</RowLabel>
                                    <RowValue>{doc.sender === String(userId) ? `${IDTranslator.translateId(doc.recipient)  || ''}` : `${IDTranslator.translateId(doc.sender) || ''} `}</RowValue>
                                </TransactionRow>
                                <TransactionRow>
                                    <RowLabel>Amount:</RowLabel>
                                    <RowValue>{doc.amount} {doc.currency}</RowValue>
                                </TransactionRow>
                                <TransactionRow>
                                    <RowLabel>Date:</RowLabel>
                                    <RowValue>{doc.timestamp}</RowValue>
                                </TransactionRow>
                            </CollapsibleDetails>
                            )}
                        </TransactionCard>
                    ))
                ) : (
                    <NoDataMessage>No transactions found</NoDataMessage>
                )}
            </ContentContainer>
        </PageContainer>
    );
};

export default HistoryPage;
