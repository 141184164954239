import styled, { keyframes } from 'styled-components';
import { TelegramShareButton } from 'react-share';
import { FaTelegramPlane, FaCopy } from 'react-icons/fa';
import React, { useState } from 'react';

// Keyframe for button hover animation
const hoverGlow = keyframes`
  0% { box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }
  50% { box-shadow: 0 4px 16px rgba(0, 136, 204, 0.4); }
  100% { box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }
`;

// Keyframe for toast appearance
const fadeInOut = keyframes`
  0% { opacity: 0; transform: translateY(10px); }
  20% { opacity: 1; transform: translateY(0); }
  80% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(10px); }
`;

// Styled components for buttons
const Button = styled.button`
  flex: 1;
  padding: 10px 20px;
  background: linear-gradient(45deg, #00c6ff, #0072ff); /* Gradient */
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-right: 10px;

  &:hover {
    animation: ${hoverGlow} 1.5s infinite; /* Glow effect */
    transform: scale(1.05); /* Subtle zoom effect */
  }

  &:last-child {
    margin-right: 0;
  }
`;

// Styled component for the toast message
const Toast = styled.div`
  position: absolute;
  bottom: -60px; /* Position below the buttons */
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(45deg, #ff512f, #dd2476); /* Cool gradient */
  color: #fff;
  padding: 15px 25px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  max-width: 300px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: ${fadeInOut} 4s forwards;
`;

export const TelegramInviteButton = ({ inviteLink, title }) => {
    const [toastVisible, setToastVisible] = useState(false);

    const handleCopyClick = () => {
        const linkWithText = `${title}, ${inviteLink}`;
        navigator.clipboard.writeText(linkWithText).then(() => {
            setToastVisible(true); // Show toast when link is copied
            setTimeout(() => {
                setToastVisible(false); // Hide toast after 3 seconds
            }, 3000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    return (
        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', gap: '10px' }}>
            <TelegramShareButton url={inviteLink} title={title}>
                <Button>
                    <FaTelegramPlane style={{ marginRight: '8px' }} />
                    Invite Friend
                </Button>
            </TelegramShareButton>
            <Button onClick={handleCopyClick}>
                <FaCopy style={{ marginRight: '8px' }} />
                Copy
            </Button>

            {toastVisible && (
                <Toast>
                    🎉 Invitation link copied! Share it with your friends! 🎉
                </Toast>
            )}
        </div>
    );
};
