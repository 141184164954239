import React, { useContext, useState, useEffect } from 'react';
import { CoinContext } from '../contextdtatashare/CoinContext';
import Chart from 'react-apexcharts';
import axios from 'axios';

const AsbeksPrice = () => {
  const [chartData, setChartData] = useState([]);
  const [percentageChange, setPercentageChange] = useState(null);
  const { theasbeksPrice } = useContext(CoinContext);
  const isLoading = theasbeksPrice === null;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        'https://jetton-index.tonscan.org/market_data/chart?days=1&interval=minute&vs_currency=USD&precision=4'
      );
      const prices = response.data.prices;
      const formattedData = prices.map((pricePoint) => ({
        x: new Date(pricePoint[0]),
        y: pricePoint[1],
      }));

      if (prices.length > 1) {
        const firstPrice = prices[0][1];
        const lastPrice = prices[prices.length - 1][1];
        const change = ((lastPrice - firstPrice) / firstPrice) * 100;
        setPercentageChange(change.toFixed(2));
      }
      setChartData(formattedData);
    } catch (error) {
      console.error('Error fetching price data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const chartOptions = {
    chart: {
      type: 'line',
      height: '60px',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 700,
      },
      toolbar: { show: false },
      background: 'transparent',
    },
    xaxis: {
      type: 'datetime',
      labels: { show: false },
      axisBorder: { show: false },
    },
    yaxis: {
      labels: { show: false },
      axisBorder: { show: false },
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
      colors: ['#1FA2FF'],
    },
    grid: { show: false },
    tooltip: { enabled: false },
  };

  const percentageStyle = {
    color: percentageChange > 0 ? '#27AE60' : '#E74C3C',
    fontSize: '16px',
    fontWeight: 'bold',
    marginLeft: '8px',
    transition: 'color 0.3s ease, transform 0.3s ease',
    transform: percentageChange !== null ? 'scale(1.1)' : 'scale(1)',
  };

  const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'linear-gradient(145deg, #2D2E30, #3C3E40)',
    padding: '16px',
    borderRadius: '12px',
    boxShadow: '0 10px 15px rgba(0, 0, 0, 0.3)',
    maxWidth: '600px',
    margin: '20px auto',
    cursor: 'pointer',
    overflow: 'hidden',
  };

  const chartContainerStyle = {
    flex: 1,
    backgroundColor: 'transparent',
    padding: '6px',
    borderRadius: '8px',
    overflow: 'hidden',
  };

  const skeletonStyle = {
    backgroundColor: '#333',
    borderRadius: '4px',
    height: '20px',
    width: '100%',
    animation: 'pulse 1.5s ease-in-out infinite',
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.05)';
        e.currentTarget.style.boxShadow = '0 15px 20px rgba(0, 0, 0, 0.4)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.boxShadow = '0 10px 15px rgba(0, 0, 0, 0.3)';
      }}
    >
      <div style={{ flex: 2, color: '#F2F2F2', fontSize: '18px', lineHeight: '1.2' }}>
        <p style={{ marginBottom: '8px', fontSize: '20px', fontWeight: 'bold', color: '#FFFFFF' }}>
        {isLoading || isNaN(parseFloat(theasbeksPrice)) ? (
  <div style={skeletonStyle} />
) : (
  `ASBEKS/USDT ${parseFloat(theasbeksPrice).toFixed(10)}`
)}


        </p>
        {isLoading ? (
          <div style={{ width: '40%', ...skeletonStyle }} />
        ) : (
          percentageChange !== null && (
            <span style={percentageStyle}>
              {percentageChange > 0 ? '+' : ''}
              {percentageChange}% {percentageChange > 0 ? '↑' : '↓'}
            </span>
          )
        )}
      </div>
      <div style={chartContainerStyle}>
        {isLoading ? (
          <div style={{ ...skeletonStyle, height: '60px' }} />
        ) : (
          <Chart
            options={chartOptions}
            series={[{ name: 'Price', data: chartData }]}
            type="line"
            height="60px"
          />
        )}
      </div>
    </div>
  );
};

export default AsbeksPrice;
