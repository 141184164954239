// ./asbek/styles.js

import styled from 'styled-components';

export const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 10px;
  background: white;
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background: #fff;
  border-top: 1px solid #ddd;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const NavItem = styled.div`
  font-size: 18px;
  cursor: pointer;
  color: ${props => (props.active ? '#008CBA' : '#000')};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavIcon = styled.div`
  font-size: 24px;
  margin-bottom: 5px;
  color: ${props => (props.active ? '#008CBA' : '#666')}; /* Grey color for inactive state */
`;

export const NavText = styled.div`
  font-size: 14px;
  color: ${props => (props.active ? '#00ADEF' : '#666')}; /* Brighter color for active state */
`;


export const TopContainer = styled.div`
  background: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 60px; /* Adjusted for navigation bar */
`;

export const Leaderboard = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

export const FriendsContainer = styled.div`
  background: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 60px; /* Adjusted for navigation bar */
`;

export const InviteButton = styled.button`
  background-color: #008CBA;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  display: block;
`;

export const Friend = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
`;



export const EarnContainer = styled.div`
//  background: linear-gradient(135deg, #e0f7fa, #80deea, #a0e0f1);

overflow: hidden; /* Ensures stars do not overflow */
  z-index: 0; /* Positioned above the stars */
  // padding: 20px;
   border-radius: 16px;
   margin-top: 10px; /* Adjusted for navigation bar */
`;

export const Task = styled.div`
  margin-top: 10px;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

export const RowLabel = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const RowValue = styled.div`
  font-size: 18px;
`;

export const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: #008CBA; /* Blue circle */
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
`;





export const BlockMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  background-color: #721c24; /* Dark red background */
  color: #f8d7da; /* Light pink text */
  border: 1px solid #f5c6cb;
  border-radius: 10px; /* Slightly rounder edges */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  z-index: 9999; /* Ensure it's on top of other content */
  max-width: 80%;
  margin: 0 auto; /* Center horizontally */

  h2 {
    margin-bottom: 10px;
    font-size: 24px; /* Larger heading */
    color: #f5c6cb; /* Light pink heading */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Shadow for emphasis */
  }

  p {
    margin: 10px 0;
    font-size: 18px; /* Slightly larger text */
    color: #f5c6cb; /* Light pink text */
  }
`;
