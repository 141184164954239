import styled from 'styled-components';

export const AdItem = styled.li`
  display: flex;
  align-items: flex-start; /* Align items at the start */
  justify-content: space-between; /* Space between image/details and button */
  background: white;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const AdDetails = styled.div`
  display: flex;
  flex-direction: column; /* Stack items in a column */
  flex: 1; /* Allow this to grow and take available space */
  margin-left: 15px; /* Space between the image and details */
`;

export const AdImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
`;

export const GoButton = styled.a`
  background: #28a745;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: background 0.3s;

  &:hover {
    background: #218838;
  }
`;


export const CPCInfo = styled.span`
  font-weight: bold;
  color: #ff6600;
  margin-bottom: 2px; /* Space between CPC rate and text */
`;

export const AdText = styled.span`
  color: #888; /* Light color for ad text */
`;


export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.h1`
  text-align: center;
  color: #343a40;
`;

export const LoadButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #0056b3;
  }
`;

export const AdList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;



export const AdInfo = styled.div`
  display: flex;
  align-items: center;
`;



export const AdTitle = styled.h3`
  margin: 0;
  color: #007bff;
  font-size: 16px;
`;



export const TotalProfit = styled.p`
  font-weight: bold;
  color: #28a745;
  text-align: center;
`;
