// LoadingSpinner.js
import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({ size }) => {
  return (
    <div className="loadings-spinner" style={{ width: size, height: size }}>
      <div className="spinners-inner"></div>
    </div>
  );
};

export default LoadingSpinner; // Ensure correct export
