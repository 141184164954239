import React, {useContext, useState, useEffect } from 'react';
import { CoinContext } from './contextdtatashare/CoinContext';
import { GiveLifeButton } from './asbek/earn_style';
import styled from 'styled-components';
const AdsComponent = ({ blockId }) => {
  const [adController, setAdController] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {localClickCount,    localCoinValue,    setLocalClickCount,    setLocalCoinValue,} = useContext(CoinContext);
  const [isclicked, setIsClicked] = useState(false);
  const tg = window.Telegram.WebApp;
  tg.ready();
  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

    // Optional story sharing parameters

    // Share the story

    ////console.log('User found:', usertg); // Log user details
    
    
    const userId = String(usertg?.id).trim();
    const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  `;
  
  const InfoText = styled.p`
  font-size: 14px;
  color: #333;
  text-align: center;

  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
`;
  useEffect(() => {
    //console.log('script loaded')
    // Check if the script is already loaded
    if (window.show_8442826) {
      setScriptLoaded(true);
      setIsLoading(false);
      return;
    }

    // Create a new script tag to load the ad script
    const tag = document.createElement('script');
    tag.src = '//doakosteceet.net/act/files/tag.min.js';
    tag.dataset.zone = '8442826';
    tag.dataset.sdk = 'show_8442826';

    // Append the script to the document body
    document.body.appendChild(tag);

    // Handle when the script is successfully loaded
    tag.onload = () => {
      setScriptLoaded(true);
      setIsLoading(false); // Hide the loading state
      //console.log("Ad script loaded successfully.");
    };

    // Handle errors if the script fails to load
    tag.onerror = () => {
      setIsLoading(false);
      console.error("Failed to load ad script.");
      
    };
  }, []);

  const showAd = (add) => {
    //console.log('show ad')
    if (scriptLoaded && window.show_8442826) {
      // Call the global function to show the ad
      window.show_8442826().then(() => {
        // Add the function to be executed after the ad is shown
        setIsClicked(false)
        if(add){
          setLocalClickCount((prevCount) => {
            const newClickCount = prevCount + 1;
            localStorage.setItem(`localClickCount_${userId}`, newClickCount); // Save to localStorage on each click
            return newClickCount;
          });
        }
        setLocalCoinValue((prevCount) => {
          const newCoinValue = prevCount + 50;
          localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
          return newCoinValue;
        });
    
      }).catch((error) => {
        console.error("Error while showing ad:", error);
        setIsClicked(false)
      });
    } else {
      setIsClicked(false)
    }
  };

  useEffect(() => {
    const checkAdsgramLoaded = () => {
      //console.log('herekj')
      if (window.Adsgram) {
        //console.log('window.')
        try {
          const controller = window.Adsgram.init({
            blockId,
            //debug: false, // set to false in production
          });
          setAdController(controller);
          // setMessage('Adsgram SDK initialized successfully.');
        } catch (e) {
          //console.log('why', e)
          // setError('Failed to initialize Adsgram SDK.');
        }
      } else {
        setTimeout(checkAdsgramLoaded, 100);
      }
    };
    checkAdsgramLoaded();
  }, [blockId]);

  const handleAdDisplay = () => {
    setIsClicked(true)
    
    if (adController) {
      adController.addEventListener('onBannerNotFound', (e) => {
        //console.log('No ads available at the moment', e);
        // Handle no banner found (suppress alert or custom handling)
      });

      adController.addEventListener('onError', (e) => {
        //console.log('onError', e);
        // Handle no banner found (suppress alert or custom handling)
      });

      adController.addEventListener('onNonStopShow', (e) => {
        //console.log('onNonStopShow', e);
        // Handle no banner found (suppress alert or custom handling)
      });
      //console.log('controller')
      
      adController.show()
        .then((result) => {
          //console.log('lets go')
            setLocalClickCount((prevCount) => {
              const newClickCount = prevCount + 1;
              localStorage.setItem(`localClickCount_${userId}`, newClickCount); // Save to localStorage on each click
              return newClickCount;
            });
      
            setLocalCoinValue((prevCount) => {
              
              const newCoinValue = prevCount + 20;
              localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
              return newCoinValue;
            });
            //console.log('dancing')
            showAd()
            // setMessage('Thank you for watching the ad!');
          
        })
        .catch((result) => {
          //console.log('jumbing')
          showAd(1)
          
          // setError(`Ad error or skipped: ${result.description}`);
        })
        .finally(() => {
          //console.log('finishing')
          // setMessage('Ad finished showing.');
        });
    } else {
      showAd(1)
    }
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <InfoText >
      Watch ads and earn free Asbeks! Tap the button below to get started.
      </InfoText>
      <GiveLifeButton 
      onClick={(e) => {handleAdDisplay() }} 
     disabled={isclicked } // Disable button if waiting for network or fetching coin value
    >
    {/* {theWeeklyClickCount === null && !isOnline ? 'Waiting for network...' : 'EARN ASBEKS +70'} */}
EARN ASBEKS +70
    </GiveLifeButton>
      {/* {message && <p>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>} */}
    </div>
  );

};

export default AdsComponent;
