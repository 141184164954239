import styled, { keyframes } from 'styled-components';

const shootingAnimation = keyframes`
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw) translateY(-100vh);
    opacity: 0;
  }
`;

export const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
  will-change: transform, opacity;
`;

export const Star = styled.div`
  position: absolute;
  left: ${(props) => Math.random() * 100}%;
  top: ${(props) => Math.random() * 100}%;
  font-size: 20px;
  color: gold;
  animation: ${shootingAnimation} ${(props) => Math.random() * 10 + 6}s linear infinite;
`;

export const TopContainer = styled.div`
  position: relative;
  background: linear-gradient(135deg, #e0f7fa, #80deea, #a0e0f1);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Limit the max width */
  margin: 0 auto; /* Center horizontally */
`;

export const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 10px;
  position: relative; /* Ensure container is positioned correctly */
`;

export const WhiteCard = styled.div`

  background-color: #ffffff;
  width: 90%;
  border-radius: 14px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

export const RowLabel = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: #333333;
  margin-bottom: 10px;
  text-align: center;
`;

export const RowValue = styled.div`
  font-size: 17px;
  color: #808080;
  margin: 10px 0;
  text-align: center;
`;



export const Leaderboard = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Profile = styled.div`
z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  name {
    margin-top: 10px; /* Adjust spacing if needed */
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10ch;
  }
  .image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.background || 'transparent'};
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width + 'px' : '60px')};
    height: ${(props) => (props.height ? props.height + 'px' : '60px')};
  }

  .king-emoji {
    position: absolute;
    top: -30px;
    left: 22px;
    font-size: 40px;
  }

  .badge {
    position: absolute;
    bottom: -5px;
    background-color: blue;
    color: white;
    border-radius: 12px;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
  }

  img {
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width - 10 + 'px' : '65px')};
    height: ${(props) => (props.height ? props.height - 10 + 'px' : '65px')};
  }
`;



export const ListProfile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%; /* Ensure it takes full width */

  img {
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width + 'px' : '50px')};
    height: ${(props) => (props.height ? props.height + 'px' : '50px')};
    margin-right: 10px;
  }

  div {
    flex: 1; /* Allow name and stars to take up remaining space */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  name {
    margin-right: 10px; /* Adjust spacing if needed */
    font-weight: bold;
  }

  stars {
    font-weight: bold;
  }
`;

export const RowContainer = styled.div`
  color: #808080;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

export const WhiteFriendsCard = styled.div`
  z-index: 2;
  background: linear-gradient(135deg, #a1c4fd, #c2e9fb, #ffecd2, #fcb69f);

  width: ${({ fullWidth }) => (fullWidth ? '90%' : '40%')};
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 8px;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
`;

export const FriendRowContainer = styled.div`
  color: #808080;
  display: flex;
  justify-content: space-around;
 margin: 2px;
`;

export const FriendRowLabel = styled.div`
  font-weight: bold;
  font-size: 13px;
`;