import React, { useState, useEffect } from 'react';
import AppwriteService from './scripts.js'; // Ensure this is the correct path
import AsbekAppwriteService from './AsbekAppwriteService.js';

const asbekService = new AsbekAppwriteService();

const TopClicksOfToday = () => {
  const [userDetails, setUserDetails] = useState({});
  const [clicks, setClicks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [criteria, setCriteria] = useState('daily'); // Default to 'daily'
  const [inputUserId, setInputUserId] = useState(''); // State for input user ID
  const [userRank, setUserRank] = useState(null); // State for user rank

  useEffect(() => {
    const fetchTopClicks = async () => {
      const appwriteService = new AppwriteService();
      try {
        const clicksData = await appwriteService.getTopClicksByCriteria(criteria);

        // Fetch user details for each click
        const userDetailsPromises = clicksData.map(async (click) => {
          try {
            const details = await asbekService.getUserDetails(click.userId);
            return { ...click, userDetails: details };
          } catch (err) {
            console.error(`Failed to fetch user details for userId ${click.userId}:`, err);
            return { ...click, userDetails: {} };
          }
        });

        const clicksWithUserDetails = await Promise.all(userDetailsPromises);
        setClicks(clicksWithUserDetails);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTopClicks();
  }, [criteria]); // Refetch when criteria changes

  const handleCriteriaChange = (event) => {
    setCriteria(event.target.value);
    setLoading(true); // Reset loading state when criteria changes
  };

  const handleUserIdChange = (event) => {
    setInputUserId(event.target.value);
  };

  const fetchUserRank = async () => {
    const appwriteService = new AppwriteService();
    try {
      const { rank } = await appwriteService.getTopClicksByCriteria(criteria, inputUserId);
      setUserRank(rank);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Top Clicks Based on {criteria.charAt(0).toUpperCase() + criteria.slice(1)}</h1>
      <select value={criteria} onChange={handleCriteriaChange}>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="lastweek">Last Week</option>
        <option value="monthly">Monthly</option>
        <option value="lastmonth">Last Month</option>
        <option value="last3months">Last 3 Months</option>
        <option value="thisyear">This Year</option>
      </select>

      <div>
        <input
          type="text"
          value={inputUserId}
          onChange={handleUserIdChange}
          placeholder="Enter User ID"
        />
        <button onClick={fetchUserRank}>Get User Rank</button>
        {userRank !== null && <div>User Rank: {userRank}</div>}
      </div>

      <table>
        <thead>
          <tr>
            <th>Username</th> {/* Added column for username */}
            <th>Clicks</th>
          </tr>
        </thead>
        <tbody>
          {clicks.map((click, index) => (
            <tr key={index}>
              <td>{click.userDetails.first_name || 'N/A'}</td> {/* Display username */}
              <td>{click.clicks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopClicksOfToday;
