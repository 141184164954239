import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';

const CandlestickChart = () => {
    const [chartData, setChartData] = useState([]);
    const [currentPrice, setCurrentPrice] = useState(null); // State for current price
     
    // Function to fetch data from API and format it for line chart
    const fetchData = async () => {
        try {
            const response = await axios.get(
                'https://jetton-index.tonscan.org/market_data/chart?days=1&interval=minute&vs_currency=USD&precision=4' // Using 1-minute interval
            );
            const prices = response.data.prices;

            // Transforming API data to match the line chart format
            const formattedData = prices.map((pricePoint) => {
                const timestamp = pricePoint[0];
                const price = pricePoint[1];
                return {
                    x: new Date(timestamp),
                    y: price,
                };
            });

            setChartData((prevData) => {
                const updatedData = [...prevData, ...formattedData].slice(-100); // Keep the last 100 entries
                return updatedData;
            });

            // Set the latest price (closing price of the last entry)
            const latestPrice = prices[prices.length - 1][1];
            setCurrentPrice(latestPrice);

        } catch (error) {
            console.error('Error fetching price data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch initial data
        const intervalId = setInterval(fetchData, 1000); // Fetch new data every second

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    // Chart options
    const chartOptions = {
        chart: {
            type: 'line', // Line chart type
            height: '100%', // Responsive height
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
            },
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
            },
            toolbar: {
                show: false, // Hide toolbar for simplicity
            },
        },
        xaxis: {
            type: 'datetime', // Ensure x-axis shows dates
            labels: {
                style: {
                    fontSize: '12px', // Font size for mobile
                },
                formatter: (value) => {
                    const date = new Date(value);
                    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Professional time format
                },
            },
        },
        yaxis: {
            title: {
                text: '', // Removed y-axis title
                style: {
                    fontSize: '14px', // Font size for mobile
                },
            },
            labels: {
                style: {
                    fontSize: '12px', // Font size for mobile
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            theme: 'dark',
            x: {
                formatter: (value) => {
                    const date = new Date(value);
                    return date.toLocaleString(); // Full date and time
                },
            },
            y: {
                formatter: (value) => `$${value.toFixed(2)}`, // Format y-axis values
            },
        },
        stroke: {
            curve: 'smooth', // Smooth line
            width: 2, // Line thickness
        },
        grid: {
            show: true,
            borderColor: '#e0e0e0',
            position: 'back',
            xaxis: {
                lines: {
                    show: true, // Show vertical grid lines
                },
            },
            yaxis: {
                lines: {
                    show: true, // Show horizontal grid lines
                },
            },
        },
    };

    return (
        <div style={{ padding: '2px' }}>
            {/* Displaying the current price on top of the chart */}
            <h2 style={{ 
                textAlign: 'center', 
                margin: '10px 0', 
                color: currentPrice >= chartData[chartData.length - 1]?.y ? 'green' : 'black', 
                width: '100%', // Take full width
                fontSize: '24px', // Larger font size
            }}>
                TON/USDT ${currentPrice ? currentPrice.toFixed(2) : 'Loading...'}
            </h2>
            <Chart
                options={chartOptions} // Use the defined options
                series={[{ name: 'Price', data: chartData }]} // Update the series to use line data
                type="line" // Change type to 'line'
                height={400} // Set a fixed height
            />
        </div>
    );
};

export default CandlestickChart;
