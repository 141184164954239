import React, { useContext } from 'react';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import { CoinContext } from './CoinContext';
import styled from 'styled-components';

const appwriteService = new AsbekAppwriteService();

const TasksPage = () => {
  const {
    hasMore,
    setTasks,
    setNextTaskCursor,
    taskCursor,
    loadingTheTasks,
    completeTask,
    tasks
  } = useContext(CoinContext);

  // Handle scroll event to load more tasks when scrolled to the bottom
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore && !loadingTheTasks) {
      console.log('Loading more tasks, current cursor:', taskCursor);
      setNextTaskCursor(taskCursor); // Fetch next set of tasks
    }
  };

  return (
    <Container onScroll={handleScroll}>
      <Title>Tasks</Title>
      {loadingTheTasks && <LoadingText>Loading...</LoadingText>}
      {tasks.length === 0 && !loadingTheTasks && (
        <NoTasksMessage>No tasks available at the moment.</NoTasksMessage>
      )}
      <TaskList>
        {tasks.map((task) => (
          <TaskCard key={task.$id}>
            <TaskTitle>{task.title}</TaskTitle>
            <TaskDescription>{task.description}</TaskDescription>
            <TaskStatus>
              Status: <StatusBadge>{task.status}</StatusBadge>
            </TaskStatus>
            <CompleteButton onClick={() => completeTask(task.$id)}>
              Complete Task
            </CompleteButton>
          </TaskCard>
        ))}
      </TaskList>
      {loadingTheTasks && <LoadingText>Loading more tasks...</LoadingText>}
    </Container>
  );
};

export default TasksPage;

// Styled Components
const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 0 auto;
  height: 80vh;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const LoadingText = styled.div`
  text-align: center;
  color: #2980b9;
  font-size: 1.2rem;
`;

const NoTasksMessage = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #7f8c8d;
`;

const TaskList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const TaskCard = styled.li`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

const TaskTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color: #2c3e50;
  margin: 0;
`;

const TaskDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #7f8c8d;
  margin: 10px 0;
`;

const TaskStatus = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: #34495e;
`;

const StatusBadge = styled.span`
  font-weight: 600;
  color: #27ae60; /* Green for active tasks */
`;

const CompleteButton = styled.button`
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: #2980b9;
  }
`;
