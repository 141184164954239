import styled, { keyframes } from 'styled-components';

export const Section = styled.section`
  overflow-y: auto;
  height: 87vh;
`;

export const LoadingText = styled.div`
text-align: center;
color: #2980b9;
font-size: 1.2rem;
`;

export const BeautifulBanner = styled.div`
  background: linear-gradient(90deg, #ff6a6a, #ffcc00);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full page height */
  width: 100vw; /* Full page width */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  
  p {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 24px;
    margin: 0;
    padding: 20px;
  }
`;
export const Title = styled.h2`
  text-align: center;
  font-size: 1.8rem;
  color: #001f3f;
  margin-bottom: 10px;
`;

export const TaskList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  //padding-bottom: 80px;
`;

export const TaskItem = styled.li`
  background: #f9f9f9; /* Light background for contrast */
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-2px);
  }
`;

export const TaskInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 75%;
  word-wrap: break-word;
`;

export const TaskTitle = styled.h3`
  font-size: 1.2rem;
  color: #444;
  margin: 0;
`;

export const TaskDescription = styled.p`
  font-size: 0.8rem;
  color: #666;
  margin: 5px 0 0;
`;

export const EarnAmount = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #28a745;
  margin: 0;
`;

export const Balance = styled.button`
  padding: 8px 16px;
  background-color: #1e90ff;
  background-image: linear-gradient(135deg, #6dd5fa, #2980b9, #2a9df4); /* Brighter, multi-tone gradient */
  font-size: 18px; /* Slightly larger text for emphasis */
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  transition: background-color 0.3s ease;

`;
export const GoButton = styled.button`
  padding: 8px 16px;
  background-color: #1e90ff;
  background-image: linear-gradient(135deg, #6dd5fa, #2980b9, #2a9df4); /* Brighter, multi-tone gradient */
  font-size: 14px; /* Slightly larger text for emphasis */
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
`;




export const UserRole = styled.p`
  font-size: 1rem;
  font-weight: 300; /* Changed to semi-bold for emphasis */
  color: #001f3f; /* Keep your original green */
  margin: 0;
  padding: 10px 15px; /* Added padding for a better touch */
  background-color: rgba(92, 184, 92, 0.1); /* Light background for contrast */
  border-radius: 5px; /* Rounded corners for a softer look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effects */
  font-style: italic;
  &:hover {
    transform: scale(1.05); /* Slightly scale up on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  }
`;

export const ColoredText = styled.span`
  font-size: 1rem;
  color: #2a9df4; /* Change this to your desired color */
  font-weight: 600; /* Normal weight for the text */
  font-style: bold;
`;

export const CompletionLabel = styled.p`
  font-size: 14px;
  font-weight: 400; /* Normal weight for contrast */
  color: ${props => (props.label === 'Completed' ? '#28a745' : '#dc3545')}; /* Green for completed, red for not yet */
  margin: 0; /* Remove default margin */
  text-transform: uppercase; /* Uppercase for style */
  letter-spacing: 0.5px; /* Spacing for readability */
`;

export const FilterButton = styled.button`
  padding: 4px 12px; /* Compact padding */
  font-size: 12px; /* Small font size for simplicity */
  font-weight: 600; /* Medium weight for clarity */
  color: #ffffff; /* White text for contrast */
  background: linear-gradient(135deg, #FF6F61, #6A82FB); /* Cool color mix gradient */
  border: none;
  border-radius: 20px; /* Soft rounded corners */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  outline: none;

  /* Shadow for depth */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */

  /* Hover effect */
  &:hover {
    background: linear-gradient(135deg, #6A82FB, #FF6F61); /* Reverse gradient on hover */
    transform: translateY(-1px); /* Slight lift effect */
  }

  /* Active state */
  &:active {
    transform: translateY(1px) scale(0.95); /* Press down effect */
  }

  /* Disabled state */
  &:disabled {
    background: rgba(200, 200, 200, 0.5); /* Light grey for disabled state */
    color: #aaa; /* Greyed out text */
    cursor: not-allowed; /* Not-allowed cursor */
  }

  /* Add margin for spacing */
  margin: 0 8px; /* Space between buttons */
`;
export const LoadingContainer = styled.div`
  padding: 10px;
  background: #e0f7fa; /* Light cyan background */
  border: 1px solid #4dd0e1; /* Teal border */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 20px; /* Spacing below the container */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Center text */
  font-size: 14px; /* Font size for better readability */
  color: #00796b; /* Darker teal for text contrast */
`;

// Animations
const bounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
export const Wrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #00c6ff, #0072ff);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding: 20px;
  position: relative;
`;

export const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  animation: ${fadeInAnimation} 1s ease-in-out;
  margin-top: 20px; /* Space from the top of the container */
`;

export const AnimationDot = styled.span`
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: ${bounceAnimation} 1.2s infinite ease-in-out;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

export const LoadingMessage = styled.h1`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 10px; /* Small margin between animation and text */
  animation: ${fadeInAnimation} 1s ease-in-out 0.5s;
`;


export const NotificationContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(45deg, #ff6ec7, #ff9a8b);
  color: #fff;
  padding: 20px 40px;
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  opacity: ${({ showNotification }) => (showNotification ? 1 : 0)};
  transform: ${({ showNotification }) =>
    showNotification
      ? 'translate(-50%, -50%) scale(1)'
      : 'translate(-50%, -50%) scale(0.8)'};
  transition: all 0.5s ease-in-out;
  animation: ${bounceAnimation} 0.5s ease-in-out;
`;

export const NotificationMessage = styled.p`
  margin: 0;
  font-size: 18px;
`;

export const CloseButton = styled.button`
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #fff;
  color: #ff6ec7;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #f7f7f7;
  }
`;
