import styled from 'styled-components';
import { Button, Drawer, TextField, Box, Typography, IconButton } from '@mui/material';

// Custom Styled Button with better hover effects and a gradient background
export const StyledButton = styled(Button)`
  background: linear-gradient(120deg, #4f94d4, #81d4fa);
    color: #ffffff;
    font-size: 14px;
    padding: 12px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background: #3f51b5;
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }

    // Style for disabled state
    &:disabled {
      background: #cfd8dc; // Light gray background for disabled state
      color: #b0bec5; // Light gray text for disabled state
      cursor: not-allowed; // Change cursor to not-allowed
      pointer-events: none; // Prevent any interaction
    }
`;

// Drawer with smooth animations and rounded edges
export const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    border-radius: 20px 20px 0 0;
    background: #f4f4f9;
    padding: 25px;
    box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.15);
    min-height: 88vh;
    overflow-y: auto;  // Enable vertical scrolling
  }
    animation: slideUp 0.4s ease;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

// Styled TextField with custom background and box shadow
export const StyledTextField = styled(TextField)`
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  & .MuiInputBase-root {
    font-size: 16px;
  }
`;

// Box for layouts with enhanced spacing
export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #e9f7fe;
  border-radius: 10px;
  margin-bottom: 16px;
`;

// Typography with modern font weight and spacing
export const StyledTypography = styled(Typography)`
  font-weight: 700;
  color: #3b3f5c;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
`;

// Close button for Snackbar
export const StyledCloseIconButton = styled(IconButton)`
  color: #ff595e;
`;
