// config.js
export const APPWRITE_ENDPOINT = 'https://serverprovider.fulbek.com/v1';
export const ASBEK_ENDPOINT = 'https://cloud.appwrite.io/v1';
export const APPWRITE_PROJECT_ID = '6668146b001b27aae761';
export const APPWRITE_DATABASE_ID = '665d31c9001a2bd4c357';
export const APPWRITE_COLLECTION_ID = '665d31d20031a6b989b3';
export const TASKS_COLLECTION_ID = '66f810830009befd8bc4';
export const ASBEK_PROJECT_ID = '66af5ec5001608e38606';
export const ASBEK_DATABASE_ID = '668bcc380001d64abb68';
export const ASBEK_COLLECTION_ID = '668bcc4400378ad66e98';
export const ASBEK_HISTORY_COLLECTION_ID = '6693f0e40035db9c9eab';
export const ASBEK_CLICK_HISTORY_COLLECTION_ID = '66a0c9f1001da60602d0';
export const API_KEY  = '7299ff5f35d593bee94e72aaa2a3bf11b41a60cda1e72f3ab164e46d619829e42b183e000e8d3c72394170b5a89577100ae97a965b8125d937f2e694330b4dacff65f1815f98d63215632068e7e3e28f6288a169b5d204eb79c0dcfcdba5cb1587d2ddab4f6461c014b38400661439c3cb9cc490a1adb8a8f84812a829ebdd63';
export const ASBEK_FUNCTION_ID = '66afddbc001291c89e83';
export const API_ENDPOINT = 'https://66b08271d75db0be960b.appwrite.global/v1';