// src/shop/styles/ProductCardStyles.js
import styled from 'styled-components';

export const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

export const CardBody = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CardTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

export const CardDescription = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  color: #666;
  margin: 0;
`;

export const CardPrice = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: #ff416c;
`;

export const BuyButton = styled.button`
  padding: 10px 15px;
  background-color: #ff416c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 10px;
  align-self: center;

  &:hover {
    background-color: #ff4b2b;
  }
`;
