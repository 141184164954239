import React, { useState, useEffect } from 'react';
import { ASBEK_ENDPOINT, ASBEK_PROJECT_ID, ASBEK_DATABASE_ID,ASBEK_COLLECTION_ID, ASBEK_CLICK_HISTORY_COLLECTION_ID } from './config.js';
import * as Appwrite from 'appwrite';
import { getTopClicksByCriteria } from './AppwriteService.js';
import AsbekAppwriteService from './AsbekAppwriteService.js';
import {
    PageContainer,
    MainHeader,
    SectionHeader,
    InfoText,
    Separator,
    UserList,
    UserItem,
    UserHeading,
    UserDetail,
    Loader,
    ErrorMessage,
    PasswordField,
    SubmitButton
} from './realui.js';
import { renderSkeleton } from '../asbek/skeleton.js';
import { LiveTv } from '@material-ui/icons';
import asbek1 from '../assets/asbek1.jpg';

const asbekService = new AsbekAppwriteService();
const client = new Appwrite.Client()
    .setEndpoint(ASBEK_ENDPOINT)
    .setProject(ASBEK_PROJECT_ID);

const db = new Appwrite.Databases(client);

const TopClicksPage = () => {
    const [rankClickCount, setRankClickCount] = useState(0);
    const [clickData, setClickData] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [fixedUserDetails, setFixedUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userRank, setUserRank] = useState(null);
    const [userRankError, setUserRankError] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  
    const fixedUserId = '6307965462';
    const correctPassword = '14899315';
    const handleRankClick = () => {
        setRankClickCount(prevCount => prevCount + 1);
    };
    
    const fetchUserDetails = async (userId) => {
        try {
            const details = await asbekService.getUserDetails(userId);
            const isOnline = await asbekService.isUserOnline(userId);
            return { ...details, isOnline };
        } catch (err) {
            //console.error(`Failed to fetch details for user ${userId}:`, err);
            return null;
        }
    };
    

    const fetchClickData = async (criteria) => {
        const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;
  
  
    ////console.log('User found:', usertg); // Log user details
    
    
    const userId = String(usertg.id).trim();
    const now = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp

    asbekService.updateDocument(userId, { online: String(now) })
        try {
            const data = await getTopClicksByCriteria(criteria, userId);
            if (data.rank) {
                setUserRank(data.rank);
                setClickData(data.topClicks.slice(0, 50));
                const userDetailsPromises = data.topClicks.map(item => fetchUserDetails(item.userId));
                const users = await Promise.all(userDetailsPromises);
                const userDetailsMap = data.topClicks.reduce((acc, item, index) => {
                    acc[item.userId] = users[index];
                    return acc;
                }, {});
                setUserDetails(userDetailsMap);
            } else {
                setUserRank(null);
                setClickData(data.topClicks.slice(0, 50));
                const userDetailsPromises = data.topClicks.map(item => fetchUserDetails(item.userId));
                const users = await Promise.all(userDetailsPromises);
                const userDetailsMap = data.topClicks.reduce((acc, item, index) => {
                    acc[item.userId] = users[index];
                    return acc;
                }, {});
                setUserDetails(userDetailsMap);
               // setUserRankError('User not found in the top clicks.');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchFixedUserDetails = async () => {
        try {
            const details = await fetchUserDetails(fixedUserId);
            setFixedUserDetails(details);
        } catch (err) {
            //console.error(`Failed to fetch details for fixed user ${fixedUserId}:`, err);
        }
    };

    const handlePasswordSubmit = () => {
        if (password === correctPassword) {
            setIsPasswordCorrect(true);
            setPasswordError('');
        } else {
            setPasswordError('Incorrect password. Please try again.');
        }
    };

    useEffect(() => {
        fetchClickData('weekly');
        fetchFixedUserDetails();

        const unsubscribe = client.subscribe(
            `databases.${ASBEK_DATABASE_ID}.collections.${ASBEK_CLICK_HISTORY_COLLECTION_ID}.documents`,
            (response) => {
                //console.log('Subscription response:', response);
                if (response.events.includes('databases.*.collections.*.documents.*')) {
                    fetchClickData('weekly');
                }
            }
        );

        return () => {
            unsubscribe();
        };
    }, []);

    if (loading) return  (
        <div>  
          <SectionHeader><LiveTv style={{ fontSize: '30px', fontweight: 'bold', color: 'red', marginRight: '8px' }} />
            LIVE RANK</SectionHeader>
            <Separator/>
          {renderSkeleton()}
        </div>
        );
    
    if (error) return <ErrorMessage>Error: {error}</ErrorMessage>;

    return (
        <PageContainer>
            
            {userRank !== null && (
    <InfoText onClick={handleRankClick}>Your Rank: {userRank}</InfoText>
)}

            {userRankError && (
                <InfoText>{userRankError}</InfoText>
            )}
    
            <Separator />
    
            
            {isPasswordCorrect ? (
                fixedUserDetails ? (
                    <UserList>
                        <UserItem>
                            <UserHeading><strong>Fixed User ID:</strong> {fixedUserId}</UserHeading>
                            <UserDetail><strong>Username:</strong> {fixedUserDetails.username || 'N/A'}</UserDetail>
                            <UserDetail><strong>First Name:</strong> {fixedUserDetails.first_name || 'N/A'}</UserDetail>
                            <UserDetail><strong>Last Name:</strong> {fixedUserDetails.last_name || 'N/A'}</UserDetail>
                            <UserDetail><strong>Account Number:</strong> {fixedUserDetails.accountNumber || 'N/A'}</UserDetail>
                            <UserDetail><strong>Account Name:</strong> {fixedUserDetails.accountName || 'N/A'}</UserDetail>
                            <UserDetail><strong>Phone Number:</strong> {fixedUserDetails.phoneNumber || 'N/A'}</UserDetail>
                        </UserItem>
                    </UserList>
                ) : (
                    <InfoText>Loading fixed user details...</InfoText>
                )
            ) : (
                <div>
                   {rankClickCount >= 3 && (
    <div>
        <InfoText>Please enter the password to view details:</InfoText>
        <PasswordField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
        />
        <SubmitButton onClick={handlePasswordSubmit}>Submit</SubmitButton>
        {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
    </div>
)}

                </div>
            )}
    
            
    
            <SectionHeader><LiveTv style={{ fontSize: '30px', fontweight: 'bold', color: 'red', marginRight: '8px' }} />
            LIVE RANK</SectionHeader>
            <Separator/>
            <UserList>
                {clickData.map((item, index) => {
                    const userDetail = userDetails[item.userId] || {};
                    return (
                        <UserItem key={item.userId}>
                            
                            <UserDetail>
                            <img src={asbek1} alt="User Profile" />
                                <div>
                                <name>{userDetail.first_name || `User ${item.last_name}`}</name>
                                <stars>Clicks: {item.clicks} </stars>
                                {userDetail.isOnline ? (
                <span style={{ color: 'green' }}>Online</span>
            ) : (
                <span style={{ color: 'red' }}>Offline</span>
            )}
                                </div>
                                <count>{index + 1}</count>
                                
                                
                                </UserDetail>
                            {isPasswordCorrect && (
                                <>
                                    <UserDetail>First Name: {userDetail.first_name || 'N/A'}</UserDetail>
                                    <UserDetail>Last Name: {userDetail.last_name || 'N/A'}</UserDetail>
                                    <UserDetail>Account Number: {userDetail.accountNumber || 'N/A'}</UserDetail>
                                    <UserDetail>Account Name: {userDetail.accountName || 'N/A'}</UserDetail>
                                    <UserDetail>Phone Number: {userDetail.phoneNumber || 'N/A'}</UserDetail>
                                </>
                            )}
                        </UserItem>
                    );
                })}
            </UserList>
        </PageContainer>
    );
    
};

export default TopClicksPage;
