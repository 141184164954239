import styled, { keyframes } from 'styled-components';

// Keyframe animation for shimmer effect
const shimmer = keyframes`
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
`;

const SkeletonProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  animation: ${shimmer} 1.5s infinite linear;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
`;

const SkeletonText = styled.div`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '16px'};
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: ${shimmer} 1.5s infinite linear;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
`;

const SkeletonList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TopContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const renderSkeleton = () => {
  return (
    <>
      <TopContainer>
        
        <SkeletonProfile>
          <SkeletonText width="50px" height="50px" />
          <div style={{ flex: 1, marginLeft: '10px' }}>
            <SkeletonText width="150px" height="16px" />
            <SkeletonText width="100px" height="16px" />
          </div>
          <SkeletonText width="60px" height="30px" />
        </SkeletonProfile>
        <SkeletonProfile>
          <SkeletonText width="50px" height="50px" />
          <div style={{ flex: 1, marginLeft: '10px' }}>
            <SkeletonText width="150px" height="16px" />
            <SkeletonText width="100px" height="16px" />
          </div>
          <SkeletonText width="60px" height="30px" />
        </SkeletonProfile>
        <SkeletonProfile>
          <SkeletonText width="50px" height="50px" />
          <div style={{ flex: 1, marginLeft: '10px' }}>
            <SkeletonText width="150px" height="16px" />
            <SkeletonText width="100px" height="16px" />
          </div>
          <SkeletonText width="60px" height="30px" />
        </SkeletonProfile>
        <SkeletonProfile>
          <SkeletonText width="50px" height="50px" />
          <div style={{ flex: 1, marginLeft: '10px' }}>
            <SkeletonText width="150px" height="16px" />
            <SkeletonText width="100px" height="16px" />
          </div>
          <SkeletonText width="60px" height="30px" />
        </SkeletonProfile>
        <SkeletonProfile>
          <SkeletonText width="50px" height="50px" />
          <div style={{ flex: 1, marginLeft: '10px' }}>
            <SkeletonText width="150px" height="16px" />
            <SkeletonText width="100px" height="16px" />
          </div>
          <SkeletonText width="60px" height="30px" />
        </SkeletonProfile>
        <SkeletonProfile>
          <SkeletonText width="50px" height="50px" />
          <div style={{ flex: 1, marginLeft: '10px' }}>
            <SkeletonText width="150px" height="16px" />
            <SkeletonText width="100px" height="16px" />
          </div>
          <SkeletonText width="60px" height="30px" />
        </SkeletonProfile>
      </TopContainer>
    </>
  );
};
