import styled from 'styled-components';

// Container for the whole page
export const PageContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  background: white; /* Gradient background */

  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: 300% 300%;
  

 
`;




// Header for the page
export const MainHeader = styled.div`
  margin-top: 10px; /* Adjust spacing if needed */
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10ch;
`;

// Subheader for sections
export const SectionHeader = styled.h2`
  color: #333;
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

// Paragraphs for text
export const InfoText = styled.p`
font-weight: bold;
  color: #555;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 1rem;
  text-align: center;
`;

// Divider
export const Separator = styled.hr`
  content: '';
    flex: none;
    width: 100%;
    height: 0.3px; /* Adjust thickness as needed */
    background: linear-gradient(135deg, #a8edea, #fed6e3); /* Soft gradient for a modern look */
    margin: 10px 0; /* Spacing above and below the divider */

`;

// List container
export const UserList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 600px;
  margin-bottom: 40px;
`;

// List item
export const UserItem = styled.li`
  padding: 1rem;
  border-bottom: 1px solid #ddd;
`;

// List item heading
export const UserHeading = styled.h3`
  color: #333;
  font-size: 1.25rem;
  margin: 0;
`;

// List item detail
export const UserDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  img {
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width + 'px' : '50px')};
    height: ${(props) => (props.height ? props.height + 'px' : '50px')};
    margin-right: 10px;
  }
  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  name {
    font-weight: bold;
  font-size: 15px;
  color: #333;
  margin-bottom: 0px;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds the ellipsis (...) at the end of the truncated text */
  max-width: 200px; /* Adjust the width based on your layout */

  }

  stars {
    font-size: 13px;
    color: #666;
  }

  count {
    margin-left: auto;
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
`;

// Loader for loading state
export const Loader = styled.div`
  color: #333;
  font-size: 1.25rem;
  text-align: center;
`;

// Error message
export const ErrorMessage = styled.p`
  color: #e74c3c;
  font-size: 1rem;
  text-align: center;
`;

// Password input field
export const PasswordField = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 1rem);
  margin-bottom: 1rem;
`;

// Submit button for password
export const SubmitButton = styled.button`
  background: #007bff;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #0056b3;
  }
`;

// Usage of updated components in the return statement
