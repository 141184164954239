import { Client, Databases } from 'appwrite';
import { ASBEK_ENDPOINT, ASBEK_PROJECT_ID, ASBEK_DATABASE_ID, ASBEK_HISTORY_COLLECTION_ID } from './config.js';

class TransferBirr {
    constructor() {
        this.client = new Client()
            .setEndpoint(ASBEK_ENDPOINT)
            .setProject(ASBEK_PROJECT_ID);
        this.database = new Databases(this.client);
    }

    async createHistoryDocument(data) {
        try {
            return await this.database.createDocument(ASBEK_DATABASE_ID, ASBEK_HISTORY_COLLECTION_ID, 'unique()', data);
        } catch (error) {
            throw new Error(`Failed to create history document: ${error.message}`);
        }
    }
}

export default TransferBirr;
