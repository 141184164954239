import styled from 'styled-components';

// Prevent zooming on mobile devices
document.documentElement.style.setProperty('viewport', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');

export const PageContainer = styled.div`
    padding: 20px;
    background: linear-gradient(135deg, #f9f9f9 25%, #d1d8e0 100%);
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;

    @media (max-width: 600px) {
        padding: 10px;
    }
`;

export const ContentContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
`;

export const FilterButton = styled.button`
    background: ${(props) => (props.active ? 'linear-gradient(135deg, #ff7e5f, #feb47b)' : '#e0e0e0')};
    color: ${(props) => (props.active ? '#fff' : '#000')};
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background: ${(props) => (props.active ? 'linear-gradient(135deg, #ff7e5f, #feb47b)' : '#c0c0c0')};
        color: #fff;
        transform: translateY(-2px);
    }

    @media (max-width: 600px) {
        margin: 5px 0;
        padding: 8px 16px;
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const AccountDetailsForm = styled.form`
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    @media (max-width: 600px) {
        padding: 15px;
    }
`;

export const FieldSet = styled.fieldset`
    border: 2px solid #007bff;
    border-radius: 10px;
    padding: 20px;
    background: #ffffff;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        padding: 15px;
    }
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        margin-bottom: 15px;
    }
`;

export const Label = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
    color: #555555;
`;

export const Input = styled.input`
    padding: 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    transition: border-color 0.3s;

    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    @media (max-width: 600px) {
        justify-content: center;
    }
`;

export const SaveButton = styled.button`
    background: linear-gradient(135deg, #007bff, #00aaff);
    color: #fff;
    border: none;
    padding: 12px 24px;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background: linear-gradient(135deg, #0056b3, #007acc);
        transform: translateY(-2px);
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 10px 20px;
    }
`;

export const SavedMessage = styled.div`
    color: #28a745;
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
`;

export const TransactionCard = styled.div`
    background: linear-gradient(135deg, #ffffff, #f9f9f9);
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    }
`;

export const Summary = styled.div`
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid #e0e0e0;
`;

export const CollapsibleDetails = styled.div`
    padding: 15px;
    background: linear-gradient(135deg, #f8f9fa, #e1e1e1);
`;

export const TransactionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const RowLabel = styled.span`
    font-weight: bold;
    color: #555555;
`;

export const RowValue = styled.span`
    color: #333333;
    word-wrap: break-word; /* Ensures long words break and wrap within the container */
    max-width: 100%; /* Prevents overflow */
    overflow-wrap: break-word; /* Fallback for older browsers */

    @media (max-width: 600px) {
        margin-top: 5px;
    }
`;


export const NoDataMessage = styled.div`
    text-align: center;
    color: #777777;
    margin-top: 50px;
    font-size: 18px;
`;
