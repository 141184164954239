import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';

const ProductDetailBottomSheet = ({ product, isOpen, onDismiss }) => {
  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onDismiss}
      defaultSnap={({ maxHeight }) => maxHeight * 0.5} // Open to 50% of screen height
    >
      <Content>
        <Image src={product.image} alt={product.name} />
        <Title>{product.name}</Title>
        <Description>{product.description}</Description>
        <Price>${product.price}</Price>
        <BuyButton>Add to Cart</BuyButton>
      </Content>
      
    </BottomSheet>
  );
};

const Content = styled.div`
  padding: 20px;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
`;

const Price = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #ff5722;
  margin-bottom: 20px;
`;

const BuyButton = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

export default ProductDetailBottomSheet;
