import styled from 'styled-components';

// Styled components
export const Container = styled.div`
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center;
    align-items: center;
    
    // background-color: #f8f9fa; 
    font-family: 'Arial', sans-serif;
`;


export const OpenButton = styled.button`
    padding: 4px 12px; /* Compact padding */
    font-size: 12px; /* Small font size for simplicity */
    font-weight: 600; /* Medium weight for clarity */
    color: #ffffff; /* White text for contrast */
    background: linear-gradient(135deg, #FF6F61, #6A82FB); /* Cool color mix gradient */
    border: none; 
    border-radius: 20px; /* Soft rounded corners */
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease; /* Smooth transitions */
    outline: none;

    /* Shadow for depth */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */

    /* Hover effect */
    &:hover {
        background: linear-gradient(135deg, #6A82FB, #FF6F61); /* Reverse gradient on hover */
        transform: translateY(-1px); /* Slight lift effect */
    }

    /* Active state */
    &:active {
        transform: translateY(1px) scale(0.95); /* Press down effect */
    }

    /* Disabled state */
    &:disabled {
        background: rgba(200, 200, 200, 0.5); /* Light grey for disabled state */
        color: #aaa; /* Greyed out text */
        cursor: not-allowed; /* Not-allowed cursor */
    }
`;



export const SheetContent = styled.div`
    padding: 30px;
    background-color: #fff; 
    border-radius: 12px; 
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15); 
`;

export const ModalHeader = styled.h3`
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    color: #333;
    font-size: 24px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const Input = styled.input`
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s;

    &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
`;

export const TextArea = styled.textarea`
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    resize: none;
    transition: border-color 0.3s;

    &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
`;

export const CharacterCount = styled.span`
    align-self: flex-end;
    font-size: 14px;
    color: #555;
`;

export const Explanation = styled.p`
    font-size: 14px;
    color: #555;
    margin: 0;
`;

export const TelegramNotice = styled.p`
    font-size: 14px;
    color: #d9534f; /* Red for important notices */
    margin-top: 15px;
    padding: 10px;
    border: 1px solid #d9534f; /* Red border */
    border-radius: 8px;
    background-color: #f8d7da; /* Light red background */
`;

export const BotLink = styled.a`
    color: #007bff; /* Bootstrap primary color */
    text-decoration: none;
    font-weight: bold;

    &:hover {
        text-decoration: underline; /* Underline on hover */
    }
`;

export const TotalCost = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
`;

export const CostAmount = styled.span`
    color: #28a745; /* Green for positive amount */
`;

export const MotivationalMessage = styled.p`
    font-size: 16px;
    color: #5e7fd2; /* Friendly blue */
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

export const SubmitButton = styled.button`
    padding: 6px 12px; /* Smaller padding for a compact look */
    background: linear-gradient(90deg, #4e8cff, #007bff); /* Blue gradient */
    color: white;
    border: none;
    border-radius: 30px; /* More pronounced rounding for a modern look */
    cursor: pointer;
    font-size: 14px; /* Smaller font size */
    font-weight: 500; /* Medium weight for subtlety */
    transition: background 0.3s ease, transform 0.2s;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */

    &:hover {
        background: linear-gradient(90deg, #007bff, #0056b3); /* Darker gradient on hover */
        transform: translateY(-1px); /* Slight lift effect */
    }

    &:disabled {
        background: #ccc; /* Grey when disabled */
        cursor: not-allowed;
        box-shadow: none; /* No shadow when disabled */
    }
`;

export const CancelButton = styled.button`
    padding: 6px 12px; /* Smaller padding for a compact look */
    background: linear-gradient(90deg, #ff6f61, #dc3545); /* Red gradient */
    color: white;
    border: none;
    border-radius: 30px; /* More pronounced rounding for a modern look */
    cursor: pointer;
    font-size: 14px; /* Smaller font size */
    font-weight: 500; /* Medium weight for subtlety */
    transition: background 0.3s ease, transform 0.2s;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */

    &:hover {
        background: linear-gradient(90deg, #dc3545, #c82333); /* Darker gradient on hover */
        transform: translateY(-1px); /* Slight lift effect */
    }

    &:disabled {
        background: #ccc; /* Grey when disabled */
        cursor: not-allowed;
        box-shadow: none; /* No shadow when disabled */
    }
`;



export const MessageContainer = styled.div`
    color: #d9534f; /* Danger color */
    font-weight: bold;
    background-color: #f8d7da; /* Light red background */
    padding: 10px;
    border: 1px solid #f5c6cb; /* Light red border */
    border-radius: 5px;
    margin-top: 10px; /* Space above the message */
    width: 100%; /* Make message container full width */
    text-align: center; /* Center the text */
`;

export const TaskDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

export const DetailItem = styled.p`
    font-size: 16px;
    margin: 5px 0;
    color: #333;
`;

export const TotalWorkers = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    color: #28a745; /* Green for positive message */
    text-align: center;
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 6px; /* Height for better visibility */
    background: linear-gradient(90deg, #FF6F61, #6A82FB); /* Colorful gradient background */
    border-radius: 3px; /* Rounded corners */
    position: relative;
    overflow: hidden;
    margin-top: 10px;

    /* Inner progress animation */
    &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%; /* Fill the full width */
        background: rgba(255, 255, 255, 0.8); /* Light opaque overlay for visibility */
        animation: progress 2s linear infinite; /* Smooth left-to-right animation */
        transform: translateX(-100%); /* Start off-screen */
    }

    @keyframes progress {
        0% { transform: translateX(-100%); } /* Start off-screen on the left */
        100% { transform: translateX(100%); } /* End off-screen on the right */
    }
`;