import React, { useEffect, useState } from 'react';
import AppwriteService from './scripts.js';
import {Container, Divider, LeaderboardContainer, LoadingContainer, ToggleContainer, ToggleItem, StarsWrapper, Star, WhiteCard, TopContainer, Leaderboard, Profile, RowContainer, RowLabel, RowValue, Badge, ListProfile} from '../asbek/top_style.js';
import asbek1 from '../assets/asbek1.jpg';
import { renderSkeleton } from '../asbek/skeleton.js';
import ApiService from './ApiService.js';
import styled, { keyframes } from 'styled-components';
import AsbekAppwriteService from './AsbekAppwriteService.js';
import WeeklyPage from './weeklyLive.js';
const asbekService = new AsbekAppwriteService();


const ContainerRank = styled.div`
  width: 100%;
  height: 60px; /* Slightly increased height for better spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc; /* Subtle border color */
  border-radius: 12px; /* More rounded corners for a modern look */
  background: linear-gradient(135deg, #e0f7fa, #80deea); /* Gradient background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */

  /* Hover effect */
  &:hover {
    background: linear-gradient(135deg, #80deea, #e0f7fa); /* Reversed gradient on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
  }
`;




const Header = styled.div`
  font-size: 16px; /* Slightly larger for better readability */
  font-weight: 600; /* Semi-bold for a strong emphasis */
  color: #444; /* Darker shade for better contrast */
  text-transform: uppercase; /* Uppercase text for a more formal appearance */
  letter-spacing: 0.5px; /* Slight spacing for improved readability */
  margin-bottom: 4px; /* Space below the header for separation */
`;

const ValueRank = styled.div`
  font-size: 18px; /* Larger font size for more prominence */
  font-weight: 700; /* Bold for strong emphasis */
  color: #222; /* Darker color for better contrast */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  letter-spacing: 0.5px; /* Slight spacing for improved readability */
`;
const TopUsers = () => {
  const [userRank, setUserRank] = useState(null); // State for user rank
  const [topUsers, setTopUsers] = useState([]);
  const [criteria, setCriteria] = useState('alltime');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [top3Users, setTop3Users] = useState([]);
  const [clicksData, setClicksData] = useState([]);
  const MAX_RETRIES = 5; // Set the maximum number of retries
  const RETRY_DELAY = 2000; // Set the delay between retries in milliseconds
  const [user, setUser] = useState(null);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  
  
 

  const fetchUserRank = async () => {
    const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;
  setUser(usertg);
  
    //console.log('User found:', usertg); // Log user details
    
    
    const userId = String(usertg.id).trim();
    const appwriteService = new AppwriteService();
    try {
      const { rank } = await appwriteService.getTopClicksByCriteria('weekly', userId);
      setUserRank(rank);
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    const fetchTopUsers = async () => {
      const appwriteService = new AppwriteService();
      setLoading(true);
      fetchUserRank();
      try {
        if (criteria === 'weekly') {
          // Fetch clicks data for the weekly criteria
          const clicksData = await appwriteService.getTopClicksByCriteria('weekly');
          // Fetch user details for each click
        const userDetailsPromises = clicksData.map(async (click) => {
          try {
            const details = await asbekService.getUserDetails(click.userId);
            return { ...click, userDetails: details };
          } catch (err) {
            //console.error(`Failed to fetch user details for userId ${click.userId}:`, err);
            return { ...click, userDetails: {} };
          }
        });

        const clicksWithUserDetails = await Promise.all(userDetailsPromises);
        setClicksData(clicksWithUserDetails);
        
        }
        const { top3, rest } = await appwriteService.getTopUsers(criteria);
        setTopUsers(rest);
        setTop3Users(top3);
        setError(null);
        setRetryCount(0); // Reset retry count on success
      } catch (error) {
        setError(error.message);
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          await delay(RETRY_DELAY);
          fetchTopUsers(); // Retry fetching data
        } else {
          setLoading(false); // Stop loading after max retries
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTopUsers();
  }, [criteria, retryCount]);

  if (loading) {
    return  (
      <div>  
        <ToggleContainer>
          <ToggleItem active={criteria === 'alltime'} onClick={() => setCriteria('alltime')}>All Time</ToggleItem>
          {/* <ToggleItem active={criteria === 'daily'} onClick={() => setCriteria('daily')}>Daily</ToggleItem> */}
          <ToggleItem active={criteria === 'weekly'} onClick={() => setCriteria('weekly')}>Weekly</ToggleItem>
          <ToggleItem active={criteria === 'monthly'} onClick={() => setCriteria('monthly')}>Monthly</ToggleItem>
          
        </ToggleContainer>
        {renderSkeleton()}
      </div>
      );
  }

  if (error) {
    return  (
      <div>
      <ToggleContainer>
        <ToggleItem active={criteria === 'alltime'} onClick={() => setCriteria('alltime')}>All Time</ToggleItem>
        {/* <ToggleItem active={criteria === 'daily'} onClick={() => setCriteria('daily')}>Daily</ToggleItem> */}
        <ToggleItem active={criteria === 'weekly'} onClick={() => setCriteria('weekly')}>Weekly</ToggleItem>
        <ToggleItem active={criteria === 'monthly'} onClick={() => setCriteria('monthly')}>Monthly</ToggleItem>
        
      </ToggleContainer>
      <center>Please try again later!</center>
    </div>
      );
  }

  return (
    <div>
      {/* <ContainerRank>
    <Header>Your Weekly Rank</Header>
    <ValueRank>{userRank}</ValueRank>
  </ContainerRank> */}
      <ToggleContainer>
        <ToggleItem active={criteria === 'alltime'} onClick={() => setCriteria('alltime')}>All Time</ToggleItem>
        {/* <ToggleItem active={criteria === 'daily'} onClick={() => setCriteria('daily')}>Daily</ToggleItem> */}
        <ToggleItem active={criteria === 'weekly'} onClick={() => setCriteria('weekly')}>Weekly</ToggleItem>
        <ToggleItem active={criteria === 'monthly'} onClick={() => setCriteria('monthly')}>Monthly</ToggleItem>
       
      </ToggleContainer>
      <LeaderboardContainer>
      
  {criteria === 'weekly' && clicksData.length === 0 && <div>Loading weekly clicks...</div>}
  {criteria === 'weekly' ? (
          <WeeklyPage /> // Show WeeklyPage only if criteria is 'weekly'
        ) : (
          // Optionally, you can show nothing or a placeholder here for other criteria
         console.log('')
        )}
  {criteria !== 'weekly' && topUsers.map((user, index) => (
    <li key={user.$id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      {user.username ? (
        <a
          href={`https://t.me/${user.username}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <ListProfile width={35} height={35}>
            <img src={asbek1} alt="Esalat" />
            <div>
              <name>{user.first_name}</name>
              <stars>{user.coinValue}</stars>
            </div>
            <count>{criteria === 'alltime' ? index + 4 : index + 1}</count>
          </ListProfile>
        </a>
      ) : (
        <ListProfile width={35} height={35}>
          <img src={asbek1} alt="Esalat" />
          <div>
            <name>{user.first_name}</name>
            <stars>{user.coinValue}</stars>
          </div>
          <count>{criteria === 'alltime' ? index + 4 : index + 1}</count>
        </ListProfile>
      )}
    </li>
  ))}
</LeaderboardContainer>

    </div>
  );
};

export default TopUsers;
