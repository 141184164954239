import * as Appwrite from 'appwrite';
import { Query } from "appwrite";

import {
    ASBEK_ENDPOINT,
    ASBEK_PROJECT_ID,
    ASBEK_DATABASE_ID,
    ASBEK_COLLECTION_ID,
    ASBEK_HISTORY_COLLECTION_ID,
    TASKS_COLLECTION_ID,
    ASBEK_CLICK_HISTORY_COLLECTION_ID
    
} from './config.js';
import IDTranslator from './IDTranslator.js';
import { v4 as uuidv4 } from 'uuid';
import { config } from 'process';
import wallet from '../tonpython/wallet.js';
class AsbekAppwriteService {
    constructor(collectionId = ASBEK_COLLECTION_ID) {
        this.client = new Appwrite.Client();
        this.database = new Appwrite.Databases(this.client);
        this.account = new Appwrite.Account(this.client);
        this.storage = new Appwrite.Storage(this.client);
        this.functions = new Appwrite.Functions(this.client);
        
        this.client
            .setEndpoint(ASBEK_ENDPOINT)
            .setProject(ASBEK_PROJECT_ID);

        this.databaseId = ASBEK_DATABASE_ID;
        this.collectionId = collectionId;
        this.historyCollectionId = ASBEK_HISTORY_COLLECTION_ID;
        this.tasksCollectionId = TASKS_COLLECTION_ID;
    
    }

    async getAllTasks() {
        //////console.log('iiiiiiiiiiiiiiiiiiiiiiiii',userId, filterType)
        let allDocuments = [];
        let limit = 100;
        let lastDocumentId = null;

        try {
            while (true) {
                
                let queryOptions = [
                    Appwrite.Query.limit(limit),
                ];

                if (lastDocumentId) {
                    queryOptions.push(Appwrite.Query.cursorAfter(lastDocumentId));
                }
                const response = await this.database.listDocuments(this.databaseId, this.tasksCollectionId, queryOptions);
                allDocuments = allDocuments.concat(response.documents);

                if (response.documents.length < limit) {
                    break;  // no more documents to fetch
                }

                lastDocumentId = response.documents[response.documents.length - 1].$id;
            }
        
            return allDocuments
            
            ;
        } catch (error) {
            //console.error('Error fetching all documents:', error.message);
            throw error;
        }
    }

    async updateTask(formData) {
        await this.database.updateDocument(this.databaseId, this.tasksCollectionId, formData.$id, 
            formData
        );
      }
      
    async createNewTask(taskData) {
        try {
            ////console.log('daataa jaaibaa',taskData);
            // Validate taskData to ensure necessary fields are provided
            if (!taskData.title || !taskData.description || !taskData.earn || !taskData.url || !taskData.adsBudget) {
                throw new Error('Missing required fields: title, description, earn, url, or adsBudget');
            }
    
            
            // Create a new task document with the provided data
            const newTask = await this.database.createDocument(this.databaseId, this.tasksCollectionId,'unique()', {
                title: taskData.title,
                description: taskData.description,
                earn: taskData.earn,
                url: taskData.url,
                adsBudget: taskData.adsBudget,
                
            });
    
            ////console.log(`New task created with ID: ${newTask.$id}`);
            return newTask;
        } catch (error) {
            console.error('Error creating new task:', error.message);
            throw error; // Ensure error is properly propagated
        }
    }
    
    // Method to mark task as completed and add userId
// Method to mark task as completed and add userId
// async markTaskAsCompleted(taskId, userId) {
//     try {
//         // First, fetch the current document
//         const task = await this.database.getDocument(this.databaseId, this.tasksCollectionId, taskId);

//         // Check if completedUsers exists and add the userId
//         const updatedUsers = task.completedUsers || [];
//         if (!updatedUsers.includes(userId)) {
//             updatedUsers.push(userId);
//         }

//         // Update the task document
//         await this.database.updateDocument(this.databaseId, this.tasksCollectionId, taskId, {
//             completedUsers: updatedUsers,  // Update the completedUsers array
            
//         });
//         ////console.log(`Task ${taskId} marked as completed by user ${userId}`);
//     } catch (error) {
//         console.error('Error marking task as completed:', error.message);
//         throw error;
//     }
// }

async buyAsbeks(
    {
        docId, initData, toAddress, comment, tonAmount, title, description, earn, url, adsBudget, asbeksAmount, thewallet, boc, token
    }
){
    try {
        const document = await this.database.getDocument(this.databaseId, this.collectionId, docId);
        
        const coinValue = document.coinValue;

        const updatedCoinValue = parseFloat(asbeksAmount) + parseFloat(coinValue);

        const timestamp = new Date().toISOString();
        const transactionId = uuidv4();

        await this.database.updateDocument(this.databaseId, this.collectionId, docId, {
            coinValue: updatedCoinValue
        });

        await this.database.createDocument(this.databaseId, this.historyCollectionId, transactionId, {
            transactionId,
            amount: parseInt(asbeksAmount),
            currency: 'ASB', // Adjust as needed
            sender: 'Asbeks',
            recipient: String(docId),
            timestamp,
            ton:parseFloat(tonAmount) || 0,
            boc: String(boc),
            status: 'confirmed',
            address: String(thewallet)
        });

    } catch (error) {
        //console.log('hjk', error)
    }
}

async createTask({
    userId,
    toAddress,
    comment,
    value,
    title,
    description,
    earn,
    url,
    adsBudget,
    asbeksInvested,
}) {
    ////console.log("Starting createTask function...");
    // //console.log("Input parameters:", {
    //     userId,
    //     toAddress,
    //     comment,
    //     value,
    //     title,
    //     description,
    //     earn,
    //     url,
    //     adsBudget,
    //     asbeksInvested,
    // });

    const docId = userId;

    if (!docId) {
        console.error("docId is required.");
        throw new Error("docId is required.");
    }

    // Fetch the user document
    ////console.log("Fetching user document for docId:", docId);
    const document = await this.database.getDocument(this.databaseId, this.collectionId, docId);
    ////console.log("Fetched document:", document);

    const coinValue = parseFloat(document.coinValue);
    ////console.log("User coin value:", coinValue);

    // Prepare task data
    const taskData = {
        title: String(title),
        description: String(description),
        earn: String(earn),
        url: String(url),
        adsBudget: String(adsBudget),
        owner: String(docId),
        status: 'approved',
        earnPlus: parseFloat(earn),
    };
    ////console.log("Prepared task data:", taskData);

    // Validate required fields
    if (!taskData.title || !taskData.description || !taskData.earn || !taskData.url || !taskData.adsBudget) {
        console.error("Missing required fields in taskData:", taskData);
        throw new Error("Missing required fields: title, description, earn, url, or adsBudget");
    }

    // Check if the user has enough budget
    const requiredAdsBudget = parseFloat(taskData.adsBudget) * parseFloat(taskData.earn);
    ////console.log("Required ads budget:", requiredAdsBudget);

    if (coinValue < requiredAdsBudget) {
        console.error("Insufficient asbeks:", { coinValue, requiredAdsBudget });
        throw new Error("Insufficient asbeks to create or update task.");
    }

    // Check for existing tasks
    ////console.log("Checking for existing tasks...");
    const existingTasks = await this.database.listDocuments(this.databaseId, '66f810830009befd8bc4', [
        Appwrite.Query.equal('owner', String(docId)),
        Appwrite.Query.equal('url', taskData.url),
    ]);
    ////console.log("Existing tasks:", existingTasks);

    if (existingTasks.total > 0) {
        // Update existing task
        ////console.log("Updating existing task...");
        const existingTask = existingTasks.documents[0];
        ////console.log("Existing task data:", existingTask);

        const updatedAdBudget = parseFloat(existingTask.adsBudget) + parseFloat(taskData.adsBudget);
        ////console.log("Updated ad budget:", updatedAdBudget);

        await this.database.updateDocument(
            ASBEK_DATABASE_ID,
            '66f810830009befd8bc4',
            existingTask.$id,
            {
                ...taskData,
                adsBudget: String(updatedAdBudget),
            }
        );
        ////console.log("Updated existing task successfully.");

        // Deduct asbeks from the user
        const updatedCoinValue = coinValue - requiredAdsBudget;
        ////console.log("Updated coin value after deduction:", updatedCoinValue);

        await this.database.updateDocument(this.databaseId, this.collectionId, docId, {
            coinValue: updatedCoinValue,
        });
        ////console.log("Updated user coin value.");

        return "Task updated successfully.";
    } else {
        // Create a new task
        ////console.log("Creating new task...");
        const updatedCoinValue = coinValue - requiredAdsBudget;
        ////console.log("Updated coin value after deduction:", updatedCoinValue);

        await this.database.updateDocument(this.databaseId, this.collectionId, docId, {
            coinValue: updatedCoinValue,
        });
        ////console.log("Updated user coin value.");

        await this.database.createDocument(this.databaseId, '66f810830009befd8bc4', 'unique()', taskData);
        ////console.log("New task created successfully.");

        return "Task created successfully.";
    }
}

async fetchTasks({ taskType, userId, limit = 5, cursor = null }) {
    if (!userId) {
        throw new Error("User ID is required");
    }

    const completedTaskIds = [];
    let lastCompletionId = null;

    // Step 1: Fetch all completed task IDs for the user
    while (true) {
        const completionQueryOptions = [
            Query.limit(100),
            Query.equal("userId", userId),
        ];

        if (lastCompletionId) {
            completionQueryOptions.push(Query.cursorAfter(lastCompletionId));
        }

        const completionResponse = await this.database.listDocuments(
            this.databaseId,
            "6742c7ce0015102f9c48", // Completion collection ID
            completionQueryOptions
        );

        completedTaskIds.push(
            ...completionResponse.documents.map((doc) => doc.taskId)
        );

        if (completionResponse.documents.length < 100) break;
        lastCompletionId = completionResponse.documents[completionResponse.documents.length - 1].$id;
    }

    // Step 2: Fetch tasks the user hasn't completed
    let tasks = [];
    let currentCursor = cursor;
    let fetchedTaskIds = new Set();
    let moreTasksAvailable = true;

    while (tasks.length < limit && moreTasksAvailable) {
        const taskQueryOptions = [
            Query.orderDesc('earnPlus'),
            Query.limit(limit),
            Query.select([ "description", "$id", "earn", "title", "url", "adsBudget", "status", "completeCount", "$createdAt", "earnPlus", "owner"]),
        ];

        if (currentCursor) {
            taskQueryOptions.push(Query.cursorAfter(currentCursor));
        }

        if (taskType === "completed") {
            taskQueryOptions.push(Query.contains("$id", completedTaskIds));
        }
        if (taskType === "mytasks") {
            taskQueryOptions.push(Query.contains("owner", userId));
        }

        const taskResponse = await this.database.listDocuments(
            this.databaseId,
            "66f810830009befd8bc4", // Tasks collection ID
            taskQueryOptions
        );

        const newTasks = taskResponse.documents.filter((task) => {
            if (taskType === "completed" || taskType === "mytasks") {
                return !fetchedTaskIds.has(task.$id);
            }

            const adsBudget = parseInt(task.adsBudget, 10);
            const completedUsersCount = task.completeCount || 0;

            return (
                completedUsersCount < adsBudget &&
                !completedTaskIds.includes(task.$id) &&
                !fetchedTaskIds.has(task.$id)
            );
        });

        tasks = tasks.concat(newTasks);
        newTasks.forEach((task) => fetchedTaskIds.add(task.$id));

        if (taskResponse.documents.length > 0) {
            currentCursor = taskResponse.documents[taskResponse.documents.length - 1].$id;
        } else {
            moreTasksAvailable = false;
        }
    }

    const nextCursor = tasks.length >= limit ? currentCursor : null;
    ////console.log('kkkkkkkknuhoikm', tasks.slice(0, limit))
    return {
        tasks: tasks.slice(0, limit),
        nextCursor,
        hasMore: tasks.length >= limit && moreTasksAvailable,
        taskType: taskType
    };
}

async fetchWithdrawalRequests({ userId, limit = 5, cursor = null, status }) {
    //console.log('sdhjkljhbjkhb')
    if (!userId) {
        throw new Error("User ID is required");
    }

    const withdrawalRequests = [];
    let currentCursor = cursor;

    while (true) {
        const withdrawalQueryOptions = [
            Query.limit(limit),
            Query.orderDesc("$createdAt"),
        ];

        if (currentCursor) {
            withdrawalQueryOptions.push(Query.cursorAfter(currentCursor));
        }

        // Admin specific logic: Filter by status (e.g., pending requests)
        if (userId === "445929610") {
            withdrawalQueryOptions.push(Query.equal("status", status));
        } else {
            // Normal user logic: Filter by userId
            withdrawalQueryOptions.push(Query.equal("userId", userId));
        }

        // Fetch withdrawal requests from the database
        const withdrawalResponse = await this.database.listDocuments(
            this.databaseId,
            "675044da00236c930339", // Replace with the actual collection ID for withdrawal requests
            withdrawalQueryOptions
        );

        withdrawalRequests.push(...withdrawalResponse.documents);

        // Break if no more documents are available
        if (withdrawalResponse.documents.length < limit) {
            break;
        }

        // Update cursor to continue from the last document
        currentCursor = withdrawalResponse.documents[withdrawalResponse.documents.length - 1].$id;

        // If we have enough documents, stop fetching (optional for limit)
        if (withdrawalRequests.length >= limit && cursor !== null) {
            break;
        }
    }

    // Determine next cursor if more results are available
    const nextCursor =
        withdrawalRequests.length > 0
            ? withdrawalRequests[withdrawalRequests.length - 1].$id
            : null;
            //console.log('sdhjkljhbjkhb',withdrawalRequests)
    return {
        withdrawalRequests: withdrawalRequests.slice(0, limit), // Return only the requested limit
        nextCursor,
        hasMore: withdrawalRequests.length >= limit, // Check if more results are available
    };
}

async createWithdrawalRequest({ userId, asbeks, dollar, ton, wallet }) {
    if (!userId || typeof asbeks !== "number" || typeof dollar !== "number" || typeof ton !== "number" || !wallet) {
        throw new Error("Invalid input data");
    }
    try {
        ////console.log("Fetching document for userId:", userId);
        const document = await this.database.getDocument(this.databaseId, this.collectionId, userId);
        
        ////console.log("Document fetched:", document);
        
        const coinValue = document.coinValue;
        //console.log("Current coinValue:", coinValue);
        
        if (parseFloat(asbeks) > parseFloat(coinValue)) {
            //console.log("Coin value is greater than asbeks. Aborting request.");
            throw new Error("What are you doing!");
        }

        const updatedCoinValue = parseFloat(coinValue) - parseFloat(asbeks);
        ////console.log("Updated coin value after withdrawal:", updatedCoinValue);

        ////console.log("Updating document with new coin value.");
        await this.database.updateDocument(this.databaseId, this.collectionId, userId, {
            coinValue: updatedCoinValue
        });

        // Define the withdrawal request attributes
        const withdrawalRequest = {
            userId: userId,
            status: "pending", // Default status is 'pending'
            asbeks: asbeks,
            dollar: dollar,
            ton: ton,
            wallet: wallet
        };

        ////console.log("Creating withdrawal request:", withdrawalRequest);
        const requestId = uuidv4();
        // Create a new withdrawal request document in the database
        const response = await this.database.createDocument(
            this.databaseId,
            "675044da00236c930339", // Replace with the actual collection ID for withdrawal requests
            requestId,
            withdrawalRequest
        );

        ////console.log("Withdrawal request created successfully:", response);
        return response; // Return the response from creating the document
    } catch (error) {
        console.error("Error creating withdrawal request:", error);
        throw new Error("Error creating withdrawal request: " + error.message);
    }
}




async updateWithdrawalRequestStatus({ requestId, status, message, userIdCheck }) {
    try {
        if(userIdCheck == '445929610'){
            throw new Error("Anauthorized" );
        }
        ////console.log("Starting updateWithdrawalRequestStatus", { requestId, status, message });

        const requestdocument = await this.database.getDocument(this.databaseId, '675044da00236c930339', requestId);
        ////console.log("Request Document Retrieved:", requestdocument);

        const asbeks = requestdocument.asbeks;
        const userId = requestdocument.userId;
        const dollar = requestdocument.dollar;
        const ton = requestdocument.ton;
        const wallet = requestdocument.wallet;

        // Check if status is "declined" and message is provided
        const updateData = { status };
        if (status === "declined" && message) {
            updateData.message = message; // Add the message if status is declined and message is not null
            ////console.log("Declined status with message:", message);
        }

        if (status === "declined") {
            const document = await this.database.getDocument(this.databaseId, this.collectionId, userId);
            ////console.log("User Document Retrieved for declined status:", document);

            const coinValue = document.coinValue;
            ////console.log("Current Coin Value:", coinValue);

            const updatedCoinValue = parseFloat(coinValue) + parseFloat(asbeks);
            ////console.log("Updated Coin Value:", updatedCoinValue);

            await this.database.updateDocument(this.databaseId, this.collectionId, userId, {
                coinValue: updatedCoinValue
            });
            ////console.log("User's Coin Value Updated Successfully.");
        }

        if (status === "approved") {
            const timestamp = new Date().toISOString();
            const transactionId = uuidv4();
            

            await this.database.createDocument(this.databaseId, this.historyCollectionId, transactionId, {
                transactionId,
                amount: parseInt(dollar),
                currency: 'USD', // Adjust as needed
                sender: 'Asbeks',
                recipient: String(userId),
                timestamp,
                ton: parseFloat(ton) || 0,
                boc: '',
                status: 'confirmed',
                address: String(wallet)
            });
            ////console.log("Transaction document created successfully.");
        }

        const updatedRequest = await this.database.updateDocument(
            this.databaseId,
            "675044da00236c930339", // Replace with the actual collection ID for withdrawal requests
            requestId,
            updateData
        );
        ////console.log("Withdrawal Request Status Updated:", updatedRequest);

        return updatedRequest; // Return the updated withdrawal request
    } catch (error) {
        console.error("Error updating withdrawal request status:", error);
        throw new Error("Error updating withdrawal request status: " + error.message);
    }
}


async markTaskAsCompleted(taskId, userId) {
    try {
        await this.database.createDocument(
            this.databaseId, // Your database ID
            '6742c7ce0015102f9c48', // taskCompletion collection ID
            'unique()', // Generate a unique ID for the document
            {
                taskId,
                userId
            }
        );
        const document = await this.database.getDocument(this.databaseId, '66f810830009befd8bc4', taskId);
        const prevcount = document.completeCount;

    await this.database.updateDocument(
        ASBEK_DATABASE_ID,
        '66f810830009befd8bc4',
        taskId,
        {
            completeCount: prevcount + 1
        }
    );
        ////console.log(`Task ${taskId} marked as completed by user ${userId}`);
    } catch (error) {
        console.error('Error marking task as completed:', error.message);
        throw error;
    }
}

    async fetchCompetitionStartDate() {
        try {
            // Fetch the document from the collection
            const configDoc = await this.database.getDocument(
                this.databaseId,    // Replace with your database ID
                '66e7d22c0031a282cd93',  // Replace with your document ID
                'competitionConfig'   // Replace with your document ID
            );
            // console.error('555555555557sdddddd:', configDoc);
            // Extract and convert the competition start date
            const competitionStart = new Date(configDoc.competitionStartDate).getTime(); // convert to milliseconds
            
            // For demonstration purposes, return the value
            return configDoc;
        } catch (error) {
            console.error('Error fetching competition start date:', error);
            return null; // Return null if an error occurs
        }
    }
    async getConfigs() {
        try {
            // Fetch the document from the collection
            const configDoc = await this.database.getDocument(
                this.databaseId,    // Replace with your database ID
                '66e7d22c0031a282cd93',  // Replace with your document ID
                '672cefcf002c4575eb19'   // Replace with your document ID
            );
            
            // For demonstration purposes, return the value
            return configDoc;
        } catch (error) {   
            console.error('Error fetching competition start date:', error);
            return null; // Return null if an error occurs
        }
    }

    
    async fetchAdsgram() {
        try {
            // Fetch the document from the collection
            const configDoc = await this.database.getDocument(
                this.databaseId,    // Replace with your database ID
                '66e7d22c0031a282cd93',  // Replace with your document ID
                'adsgram'   // Replace with your document ID
            );
            // console.error('555555555557sdddddd:', configDoc);
            // Extract and convert the competition start date
            const competitionStart = new Date(configDoc.competitionStartDate).getTime(); // convert to milliseconds
            
            // For demonstration purposes, return the value
            return configDoc;
        } catch (error) {
            console.error('Error fetching competition start date:', error);
            return null; // Return null if an error occurs
        }
    }
    async updateStartDate(data) {
        try {
            // Fetch the document from the collection
            const configDoc = await this.database.updateDocument(
                this.databaseId,    // Replace with your database ID
                '66e7d22c0031a282cd93',  // Replace with your document ID
                'competitionConfig',
                data   // Replace with your document ID
            );
            // console.error('555555555557sdddddd:', configDoc);
            // Extract and convert the competition start date
            const competitionStart = new Date(configDoc.competitionStartDate).getTime(); // convert to milliseconds
            
            // For demonstration purposes, return the value
            return configDoc;
        } catch (error) {
            console.error('Error fetching competition start date:', error);
            return null; // Return null if an error occurs
        }
    }
    // async handleSession(userId) {
    //     const sessionId = uuidv4(); // Generate a unique session ID
      
    //     try {
    //       const sessionDoc = await this.database.getDocument(this.databaseId, this.collectionId, userId);
      
    //       const localSessionId = localStorage.getItem('sessionId');
      
    //       if (!localSessionId) {
    //         await this.database.updateDocument(this.databaseId, this.collectionId, userId, {
    //           sessionId
    //         });
    //         localStorage.setItem('sessionId', sessionId);
    //         return { success: true, message: 'A new session has been initiated. Stay vigilant.' };
    //       } else if (sessionDoc.sessionId === localSessionId) {
    //         return { success: true, message: 'Your session is secure and validated. Proceed with caution.' };
    //       } else {
    //         const userConfirmed = window.confirm('WARNING: Your session is currently active on another device. Continuing here may compromise security. Do you wish to proceed?');
      
    //         if (userConfirmed) {
    //           await this.database.updateDocument(this.databaseId, this.collectionId, userId, {
    //             sessionId: localSessionId
    //           });
    //           return { success: true, message: 'Session updated. All other devices have been overridden. Monitor your activity closely.' };
    //         } else {
    //           throw new Error('SECURITY BREACH: Session conflict detected. Access has been denied.');
    //         }
    //       }
    //     } catch (error) {
    //       console.error('CRITICAL FAILURE: Error handling session:', error.message);
    //       throw error;
    //     }
    //   }
      
    async handleSession(userId) {
        const sessionId = uuidv4(); // Generate a unique session ID
      
        try {
          const sessionDoc = await this.database.getDocument(this.databaseId, this.collectionId, userId);
          const localSessionId = localStorage.getItem('sessionId');
            if (sessionDoc.sessionTime){
               // ////console.log(sessionDoc.sessionTime)
               // return { sessionTime: sessionDoc.sessionTime };
            } else {
               // ////console.log('hin jiru')
            }
          if (!localSessionId) {
            await this.database.updateDocument(this.databaseId, this.collectionId, userId, { sessionId });
            localStorage.setItem('sessionId', sessionId);
            return { success: true,sessionTime: sessionDoc.sessionTime, message: 'A new session has been initiated. Stay vigilant.' };
          } else if (sessionDoc.sessionId === localSessionId) {
            return { success: true,sessionTime: sessionDoc.sessionTime, message: 'Your session is secure and validated. Proceed with caution.' };
          } else {
            // Indicate a session conflict and provide the session ID to update
            return { success: false, sessionTime: sessionDoc.sessionTime,sessionConflict: true, sessionIdToUpdate: localSessionId };
          }
        } catch (error) {
          console.error('CRITICAL FAILURE: Error handling session:', error.message);
          throw error;
        }
      }
      
    //   async updateSession(userId, sessionId) {
    //     try {
    //       await this.database.updateDocument(this.databaseId, this.collectionId, userId, { sessionId });
    //       return { success: true, message: 'Session updated. All other devices have been overridden. Monitor your activity closely.' };
    //     } catch (error) {
    //       console.error('CRITICAL FAILURE: Error updating session:', error.message);
    //       throw error;
    //     }
    //   }
      
    
    async updateSession(userId, sessionId) {
        const sessionTime = new Date().toISOString(); // Get the current time in string format
      
        try {
          await this.database.updateDocument(this.databaseId, this.collectionId, userId, { 
            sessionId, 
            sessionTime 
          });
          
          return { 
            success: true, 
            message: 'Session updated. All other devices have been overridden. Monitor your activity closely.' 
          };
        } catch (error) {
          console.error('CRITICAL FAILURE: Error updating session:', error.message);
          throw error;
        }
      }
    async transferCoins(senderId, recipientId, amount) {
        const transactionId = uuidv4(); // Generate a unique transaction ID
        const timestamp = new Date().toISOString(); // Current timestamp in ISO format
    
    // Define reversal translation map (ensure this matches the mapping used for encoding)
   

    

    const originalSenderId = IDTranslator.reverseTranslateId(senderId);
    const originalRecipientId = IDTranslator.reverseTranslateId(recipientId);
        // Validate inputs
    if (typeof senderId !== 'string' || typeof originalRecipientId !== 'string' || typeof amount !== 'number') {
        throw new Error('Invalid input');
    }
    if (senderId === originalRecipientId) {
        throw new Error('Cannot send coins to yourself');
    }
    if (amount <= 0) {
        throw new Error('Amount must be greater than zero');
    }
        try {
            
    
            // Fetch sender and recipient documents
            const senderDoc = await this.database.getDocument(this.databaseId, this.collectionId, senderId);
            const recipientDoc = await this.database.getDocument(this.databaseId, this.collectionId, originalRecipientId);
    
            if (!senderDoc || !recipientDoc) {
                throw new Error('Sender or recipient not found');
            }
    
            const senderCoinValue = senderDoc.coinValue || 0;
            const recipientCoinValue = recipientDoc.coinValue || 0;
    
            // Check if sender has enough coins
            if (senderCoinValue < amount) {
                throw new Error('Insufficient coins');
            }
    
            // Calculate new coin values
            const newSenderCoinValue = senderCoinValue - amount;
            const newRecipientCoinValue = recipientCoinValue + amount;
    
            // Update the sender and recipient documents
            await this.database.updateDocument(this.databaseId, this.collectionId, senderId, { coinValue: newSenderCoinValue });
            await this.database.updateDocument(this.databaseId, this.collectionId, originalRecipientId, { coinValue: newRecipientCoinValue });
    
            // Create transaction history record
            await this.database.createDocument(this.databaseId, this.historyCollectionId, transactionId, {
                transactionId,
                amount,
                currency: 'ASB', // Adjust as needed
                sender: senderId,
                recipient: originalRecipientId,
                timestamp
            });
            localStorage.removeItem(`coinValue_${senderId}`);

            return { success: true, message: 'Transfer successful' };
        } catch (error) {
            // Handle errors
            console.error('Error transferring coins:', error.message);
    
            // Rollback logic in case of failure (if needed)
            // Consider reverting updates if transaction history recording fails
    
            throw new Error('User with the requested ID could not be found ');
        }
    }
    
    async isUserOnline(userId) {
    try {
        const response = await this.getUserDetails(userId);
        const lastOnlineTime = response.online;
        const currentTime = Math.floor(Date.now() / 1000);
        const onlineThreshold = 300; // 1.5 minutes (90 seconds)

        return currentTime - lastOnlineTime <= onlineThreshold;
    } catch (error) {
        console.error('Error checking user online status:', error);
        return false;
    }
}

    
    async markTaskCompleted(taskId, userId) {
        try {
            // Step 1: Retrieve the current document
            const currentDoc = await this.database.getDocument(this.databaseId, this.collectionId, taskId);
    
            if (!currentDoc) {
                throw new Error('Document not found');
            }
    
            // Step 2: Modify the array
            let completedUsers = currentDoc.completedUsers || []; // Initialize as empty array if not present
            completedUsers.push(userId); // Add userId to the array
    
            // Step 3: Update the document with the modified array
            const updatedDoc = await this.database.updateDocument(
                this.databaseId,
                this.collectionId,
                taskId,
                { completedUsers } // Update completedUsers field with the new array
            );
    
            return updatedDoc;
        } catch (error) {
            //console.error('Error marking task completed:', error.message);
            throw error;
        }
    }
    
    async getDocument(docId) {
        try {
            const document = await this.database.getDocument(this.databaseId, this.collectionId, docId);
            return document;
        } catch (error) {
            //console.error('Error getting document:', error.message);
            throw error;
        }
    }

    async createDocument(docId, data) {
        try {
            const document = await this.database.createDocument(this.databaseId, this.collectionId, docId, data);
            return document;
        } catch (error) {
            //console.error('Error creating document:', error.message);
            throw error;
        }
    }

        async updateDocument(docId, data) {
        try {
            const document = await this.database.updateDocument(this.databaseId, this.collectionId, docId, data);
            return document;
        } catch (error) {
            //console.error('Error updating document:', error.message);
            throw error;
        }
    }

    async deleteDocument(docId) {
        try {
            await this.database.deleteDocument(this.databaseId, this.collectionId, docId);
            return true;
        } catch (error) {
            //console.error('Error deleting document:', error.message);
            throw error;
        }
    }

    async countDocuments() {
        try {
            const response = await this.listDocuments([
                Query.greaterThan('coinValue', 100)
            ]);
            const totalDocuments = response.total;
            return totalDocuments;
        } catch (error) {
            //console.error('Error counting documents:', error.message);
            throw error;
        }
    }

    async listDocuments(filters = [], limit = 10, offset = 0) {
        try {
            const documents = await this.database.listDocuments(this.databaseId, this.collectionId, [], limit, offset, '', filters);
            return documents;
        } catch (error) {
            //console.error('Error listing documents:', error.message);
            throw error;
        }
    }

    async getTopUsers(criteria = 'alltime') {
        const now = Date.now();
    
        // Check if cache is still valid
        if (this.cache.data && this.cache.criteria === criteria && (now - this.cache.timestamp) < this.cacheExpirationTime) {
          return this.cache.data;
        }
    
        // If a fetch operation is already in progress, return cached data
        if (this.fetching) {
          return this.cache.data;
        }
    
        // Cache is either empty or expired, fetch from Appwrite
        this.fetching = true; // Set flag to indicate fetching
        let sortField = 'coinValue';
        if (criteria === 'daily') sortField = 'dailyClickCount';
        else if (criteria === 'weekly') sortField = 'weeklyClickCount';
        else if (criteria === 'monthly') sortField = 'monthlyClickCount';
    
        try {
          const response = await this.db.listDocuments(
            ASBEK_DATABASE_ID,
            ASBEK_COLLECTION_ID,
            [
              Appwrite.Query.orderDesc(sortField),
              Appwrite.Query.limit(50),
            ]
          );
    
          let users = response.documents;
    
          // Separate top 3 all-time users
          let top3 = [];
          let rest = [];
    
          if (criteria === 'alltime') {
            top3 = users.slice(0, 3).map(doc => ({
              $id: doc.$id,
              first_name: doc.first_name,
              coinValue: doc.coinValue,
              [criteria]: doc[sortField],
            }));
            rest = users.slice(3).map(doc => ({
              $id: doc.$id,
              first_name: doc.first_name,
              coinValue: doc.coinValue,
              [criteria]: doc[sortField],
            }));
          } else {
            rest = users.map(doc => ({
              $id: doc.$id,
              first_name: doc.first_name,
              coinValue: doc.coinValue,
              [criteria]: doc[sortField],
            }));
          }
    
          // Update in-memory cache
          this.cache.data = { top3, rest };
          this.cache.timestamp = now;
          this.cache.criteria = criteria;
    
          // Save to local storage
          this.saveToLocalStorage();
    
          return { top3, rest };
    
        } catch (err) {
          //console.error('Error fetching documents:', err);
          throw new Error('Internal Server Error');
        } finally {
          this.fetching = false; // Reset flag after fetch completes
        }
      }
    
      async getFastRank(weeklyClickCount, userDocId) {
        let allDocuments = [];
        let limit = 100;
        let lastDocumentId = null;
        let userRank = -1;
    
        try {
            while (true) {
                let queryOptions = [
                    Appwrite.Query.limit(limit),
                    Appwrite.Query.greaterThanEqual('weeklyClickCount', weeklyClickCount)
                ];
                if (lastDocumentId) {
                    queryOptions.push(Appwrite.Query.cursorAfter(lastDocumentId));
                }
                const response = await this.database.listDocuments(
                    this.databaseId, 
                    this.collectionId, 
                    queryOptions
                );
                
                allDocuments = allDocuments.concat(response.documents);
    
                if (response.documents.length < limit) {
                    break;  // no more documents to fetch
                }
    
                lastDocumentId = response.documents[response.documents.length - 1].$id;
            }
    
            // Sort all documents based on 'weeklyClickCount' in descending order
            allDocuments.sort((a, b) => b.weeklyClickCount - a.weeklyClickCount);
    
            // Determine the rank of the user
            userRank = 1;
            for (const doc of allDocuments) {
                if (doc.$id === userDocId) {
                    return { rank: userRank, documents: allDocuments };
                }
                userRank++;
            }
    
            // If userDocId is not found
            return { rank: -1, documents: allDocuments };
    
        } catch (error) {
            console.error('Error fetching all documents:', error.message);
            throw error;
        }
    }
    
    async getFriends(userDocId) {
        let allDocuments = [];
        let limit = 100;
        let lastDocumentId = null;
    
        try {
            while (true) {
                let queryOptions = [
                    Appwrite.Query.limit(limit),
                    Appwrite.Query.equal('invited_by', userDocId)
                ];
                if (lastDocumentId) {
                    queryOptions.push(Appwrite.Query.cursorAfter(lastDocumentId));
                }
    
                const response = await this.database.listDocuments(
                    this.databaseId,
                    this.collectionId,
                    queryOptions
                );
    
                allDocuments = allDocuments.concat(response.documents);
    
                if (response.documents.length < limit) {
                    break; // no more documents to fetch
                }
    
                lastDocumentId = response.documents[response.documents.length - 1].$id;
            }
    
            // Return the list of friends and their count
            return { friends: allDocuments, count: allDocuments.length };
    
        } catch (error) {
            console.error('Error fetching invited friends:', error.message);
            throw error;
        }
    }
    
    
    async getAllDocuments(userId,filterType) {
        //////console.log('iiiiiiiiiiiiiiiiiiiiiiiii',userId, filterType)
        let allDocuments = [];
        let limit = 100;
        let lastDocumentId = null;

        try {
            while (true) {
                let queryOptions = [Appwrite.Query.limit(limit)];
                if (lastDocumentId) {
                    queryOptions.push(Appwrite.Query.cursorAfter(lastDocumentId));
                }
                const response = await this.database.listDocuments(this.databaseId, this.collectionId, queryOptions);
                allDocuments = allDocuments.concat(response.documents);

                if (response.documents.length < limit) {
                    break;  // no more documents to fetch
                }

                lastDocumentId = response.documents[response.documents.length - 1].$id;
            }
        //     //////console.log(`Number of documents before sorting: ${allDocuments.length}`);

        // // Sort documents based on the filterType
        // if (filterType === 'daily') {
        //     allDocuments.sort((a, b) => b.dailyClickCount - a.dailyClickCount);
        //     //////console.log('Sorted documents by dailyClickCount.');
        // } else if (filterType === 'weekly') {
        //     allDocuments.sort((a, b) => b.weeklyClickCount - a.weeklyClickCount);
        //     //////console.log('Sorted documents by weeklyClickCount.');
        // }else if (filterType === 'monthly') {
        //     allDocuments.sort((a, b) => b.monthlyClickCount - a.monthlyClickCount);
        //     //////console.log('Sorted documents by weeklyClickCount.');
        // } else {
        //     allDocuments.sort((a, b) => b.coinValue - a.coinValue);
        //     //////console.log('Sorted documents by coinValue.');
        // }

        // // Step 3: Find the user's rank out of all users
        // const userIndex = allDocuments.findIndex(doc => doc.$id === userId);

        // if (userIndex === -1) {
        //     //////console.log('User not found. jkllllllllllllll Sorted documents by coinValue.');
        //     // User is not found in the documents
        //     return 'User not found.';
        // }

        // // Rank is 1-based index
        // const rank = userIndex + 1;
        // //////console.log(`User with ID ${userId} is ranked ${rank} out of ${allDocuments.length} users.`);
        // const topUsers = allDocuments.slice(0, 50)
            return allDocuments
            
            ;
        } catch (error) {
            //console.error('Error fetching all documents:', error.message);
            throw error;
        }
    }
    async getUserDetails(userId) {
        try {
            // Fetch the user document using userId
            const userDoc = await this.database.getDocument(this.databaseId, this.collectionId, userId);
            
            if (!userDoc) {
                throw new Error('User not found');
            }
    
            // Extract and return relevant user details
            const userDetails = {
                userId: userDoc.$id,
                username: userDoc.username,
                first_name: userDoc.first_name,
                last_name: userDoc.last_name,
                accountNumber:userDoc.accountNumber,
                accountName:userDoc.accountName,
                phoneNumber:userDoc.phoneNumber,
                online: userDoc.online

            };
    
            return userDetails;
        } catch (error) {
            //console.error('Error getting user details:', error.message);
            throw error;
        }
    }
    
    async getAllFriends(myId) {
        let allDocuments = [];
        let limit = 100;
        let lastDocumentId = null;
    
        try {
            while (true) {
                //////console.log(myId, 'muyasaaaaaaaaaaaaaaaaaaaaaa');
                
                // Convert myId to string if necessary
                let myIdString = myId.toString();
    
                let queryOptions = [
                    Appwrite.Query.limit(limit),
                    Appwrite.Query.equal('invited_by', myIdString)
                ];
    
                if (lastDocumentId) {
                    queryOptions.push(Appwrite.Query.cursorAfter(lastDocumentId));
                }
    
                const response = await this.database.listDocuments(
                    this.databaseId,
                    this.collectionId,
                    queryOptions
                );
    
                allDocuments = allDocuments.concat(response.documents);
    
                if (response.documents.length < limit) {
                    break; // no more documents to fetch
                }
    
                lastDocumentId = response.documents[response.documents.length - 1].$id;
            }
    
            return { documents: allDocuments, count: allDocuments.length };
        } catch (error) {
            //console.error('Error fetching all documents:', error.message);
            throw error;
        }
    }
    
    async getAllHistory(myId) {
        let allDocuments = [];
        let limit = 100;
        let lastDocumentId = null;
        //////console.log(myId, 'shdgfdhsjak');
                
        try {
            while (true) {
                //////console.log(myId, 'muyasaaaaaaaaaaaaaaaaaaaaaa');
                
                // Convert myId to string if necessary
                let myIdString = myId.toString();
    
                let queryOptions = [
                    Appwrite.Query.limit(limit),
                    Appwrite.Query.orderDesc(),
                    Appwrite.Query.or(
                        [
                            Appwrite.Query.equal('recipient', myIdString),
                            Appwrite.Query.equal('sender', myIdString),
                        ]
                    ),
                   
                ];
    
                if (lastDocumentId) {
                    queryOptions.push(Appwrite.Query.cursorAfter(lastDocumentId));
                }
    
                const response = await this.database.listDocuments(
                    this.databaseId,
                    this.collectionId,
                    queryOptions
                );
    
                allDocuments = allDocuments.concat(response.documents);
    
                if (response.documents.length < limit) {
                    break; // no more documents to fetch
                }
    
                lastDocumentId = response.documents[response.documents.length - 1].$id;
            }
    
            return { documents: allDocuments, count: allDocuments.length };
        } catch (error) {
            //console.error('Error fetching all documents:', error.message);
            throw error;
        }
    }
    async register(email, password, name) {
        try {
            const user = await this.account.create('unique()', email, password, name);
            return user;
        } catch (error) {
            //console.error('Error registering user:', error.message);
            throw error;
        }
    }

    async login(email, password) {
        try {
            const session = await this.account.createSession(email, password);
            return session;
        } catch (error) {
            //console.error('Error logging in:', error.message);
            throw error;
        }
    }

    async logout() {
        try {
            await this.account.deleteSession('current');
            return true;
        } catch (error) {
            //console.error('Error logging out:', error.message);
            throw error;
        }
    }

    async uploadFile(file) {
        try {
            const uploadedFile = await this.storage.createFile('unique()', file);
            return uploadedFile;
        } catch (error) {
            //console.error('Error uploading file:', error.message);
            throw error;
        }
    }

    async getFile(fileId) {
        try {
            const file = await this.storage.getFile(fileId);
            return file;
        } catch (error) {
            //console.error('Error getting file:', error.message);
            throw error;
        }
    }

    async deleteFile(fileId) {
        try {
            await this.storage.deleteFile(fileId);
            return true;
        } catch (error) {
            //console.error('Error deleting file:', error.message);
            throw error;
        }
    }

    async executeFunction(functionId, data) {
        try {
            const execution = await this.functions.createExecution(functionId, data);
            return execution;
        } catch (error) {
            //console.error('Error executing function:', error.message);
            throw error;
        }
    }
}

export default AsbekAppwriteService;
