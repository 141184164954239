import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import styled from 'styled-components';

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 180px; /* Slightly taller for full-width layout */
  border-radius: 0; /* Remove rounded corners for edge-to-edge layout */
  overflow: hidden;
  margin: 0; /* Ensure no margins to maintain full width */
`;

const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(135deg, #ff4b2b, #ff416c); /* Gradient background for appeal */
  color: #fff;
  text-align: center;
  padding: 10px;
  margin: 10px;
`;

const SlideTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0;
`;

const SlideDescription = styled.p`
  font-size: 0.9rem;
  font-weight: 300;
  margin-top: 4px;
`;

const HeroSwiper = () => (
  <StyledSwiper
    modules={[Pagination, Autoplay]}
    pagination={{ clickable: true }}
    autoplay={{ delay: 3000 }}
    loop
  >
    <SwiperSlide>
      <SlideContent>
        <SlideTitle>Asbeks Shop</SlideTitle>
        <SlideDescription>The best deals, just for you!</SlideDescription>
      </SlideContent>
    </SwiperSlide>
    <SwiperSlide>
      <SlideContent>
        <SlideTitle>Amazing Products</SlideTitle>
        <SlideDescription>Shop now and save more.</SlideDescription>
      </SlideContent>
    </SwiperSlide>
    <SwiperSlide>
      <SlideContent>
        <SlideTitle>Discover Deals</SlideTitle>
        <SlideDescription>Unbeatable prices await!</SlideDescription>
      </SlideContent>
    </SwiperSlide>
  </StyledSwiper>
);

export default HeroSwiper;
