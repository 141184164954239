// IDTranslator.js

export default class IDTranslator {
    static translationMap = {
        '1': 'ab76',
        '2': 'ba1398',
        '3': '63kl',
        '4': 'fj83',
        '5': 'ew5',
        '6': 'iue6',
        '7': '65ds',
        '8': '@on',
        '9': '6945@#$',
        '0': 'ljkjs55'
    };

    static reverseTranslationMap = {
        'ab76': '1',
        'ba1398': '2',
        '63kl': '3',
        'fj83': '4',
        'ew5': '5',
        'iue6': '6',
        '65ds': '7',
        '@on': '8',
        '6945@#$': '9',
        'ljkjs55': '0'
    };

    static translateId(id) {
        if (typeof id !== 'string') {
            id = String(id);
        }
        return id.split('').map(digit => this.translationMap[digit] || digit).join('');
    }

    static reverseTranslateId(translatedId) {
        let decodedId = '';
        let tempStr = '';
        for (let i = 0; i < translatedId.length; i++) {
            tempStr += translatedId[i];
            for (let [key, value] of Object.entries(this.reverseTranslationMap)) {
                if (tempStr.endsWith(key)) {
                    decodedId += value;
                    tempStr = '';
                    break;
                }
            }
        }
        return decodedId;
    }
}
