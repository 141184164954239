// src/AppwriteService.js
import { 
  ASBEK_ENDPOINT, 
  ASBEK_PROJECT_ID, 
  ASBEK_DATABASE_ID, 
  ASBEK_COLLECTION_ID,
  ASBEK_CLICK_HISTORY_COLLECTION_ID,

} from './config.js';
import * as Appwrite from 'appwrite';

class AppwriteService {
  constructor() {
    this.client = new Appwrite.Client();
    this.client
      .setEndpoint(ASBEK_ENDPOINT)
      .setProject(ASBEK_PROJECT_ID);

    this.db = new Appwrite.Databases(this.client);
    
    // Initialize cache
    this.cache = {
      data: null,
      timestamp: null,
      criteria: null
    };

    // Cache expiration time in milliseconds (10 minutes)
    this.cacheExpirationTime = 10 * 60 * 1000; // 10 minutes

    // Flag to indicate if a fetch operation is in progress
    this.fetching = false;

    // Load data from local storage
    this.loadFromLocalStorage();

    // Set up automatic cache refresh
    this.startAutoRefresh();
  }

  async liveTopUsers(criteria = 'alltime') {
    const now = Date.now();

    // Check if cache is still valid
    if (this.cache.data && this.cache.criteria === criteria && (now - this.cache.timestamp) < this.cacheExpirationTime) {
      return this.cache.data;
    }

    // If a fetch operation is already in progress, return cached data
    if (this.fetching) {
      return this.cache.data;
    }

    // Cache is either empty or expired, fetch from Appwrite
    this.fetching = true; // Set flag to indicate fetching
    let sortField = 'coinValue';
    if (criteria === 'daily') sortField = 'dailyClickCount';
    else if (criteria === 'weekly') sortField = 'weeklyClickCount';
    else if (criteria === 'monthly') sortField = 'monthlyClickCount';

    try {
      const response = await this.db.listDocuments(
        ASBEK_DATABASE_ID,
        ASBEK_COLLECTION_ID,
        [
          Appwrite.Query.orderDesc(sortField),
          Appwrite.Query.limit(50),
        ]
      );

      let users = response.documents;

      // Separate top 3 all-time users
      let top3 = [];
      let rest = [];

      if (criteria === 'alltime') {
        top3 = users.slice(0, 3).map(doc => ({
          $id: doc.$id,
          first_name: doc.first_name,
          coinValue: doc.coinValue,
          [criteria]: doc[sortField],
        }));
        rest = users.slice(3).map(doc => ({
          $id: doc.$id,
          first_name: doc.first_name,
          coinValue: doc.coinValue,
          [criteria]: doc[sortField],
        }));
      } else {
        rest = users.map(doc => ({
          $id: doc.$id,
          first_name: doc.first_name,
          coinValue: doc.coinValue,
          [criteria]: doc[sortField],
        }));
      }

      // Update in-memory cache
      this.cache.data = { top3, rest };
      this.cache.timestamp = now;
      this.cache.criteria = criteria;

      // Save to local storage
      this.saveToLocalStorage();

      return { top3, rest };

    } catch (err) {
      //console.error('Error fetching documents:', err);
      throw new Error('Internal Server Error');
    } finally {
      this.fetching = false; // Reset flag after fetch completes
    }
  }
  
  async registerClick(userId) {
    
    try {
       // Get today's date in UTC
    const now = new Date();
    const utcYear = now.getUTCFullYear();
    const utcMonth = now.getUTCMonth();
    const utcDate = now.getUTCDate();

    // Calculate the start and end of the day in UTC
    const todayStartUTC = new Date(Date.UTC(utcYear, utcMonth, utcDate));
    const todayEndUTC = new Date(Date.UTC(utcYear, utcMonth, utcDate + 1)) - 1;

    // const todayStart = Math.floor(todayStartUTC.getTime() / 1000);
    // const todayEnd = Math.floor(todayEndUTC / 1000);
      // Get today's date in timestamp format
      const today = new Date();
      const todayStart = Math.floor(new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime() / 1000);
      const todayEnd = Math.floor(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).getTime() / 1000) - 1;
  
      // Find existing click history for the user today
      const response = await this.db.listDocuments(
        ASBEK_DATABASE_ID,
        ASBEK_CLICK_HISTORY_COLLECTION_ID,
        [
          Appwrite.Query.equal('userId', userId),
          Appwrite.Query.greaterThanEqual('date', todayStart.toString()),
          Appwrite.Query.lessThanEqual('date', todayEnd.toString()),
          Appwrite.Query.limit(1),
        ]
      );
  
      if (response.documents.length > 0) {
        // Document exists for today, update it
        const document = response.documents[0];
        const updatedClicks = (document.clicks || 0) + 1;
  
        await this.db.updateDocument(
          ASBEK_DATABASE_ID,
          ASBEK_CLICK_HISTORY_COLLECTION_ID,
          document.$id,
          { clicks: updatedClicks }
        );
      } else {
        // Document does not exist for today, create a new one
        const newDocument = {
          userId: userId,
          date: todayStart.toString(),
          clicks: 1
        };
  
        await this.db.createDocument(
          ASBEK_DATABASE_ID,
          ASBEK_CLICK_HISTORY_COLLECTION_ID,
          'unique()',
          newDocument
        );
      }
    } catch (err) {
      //console.error('Error registering click:', err);
      throw new Error('Internal Server Error');
    }
  }
  
  async getTopClicksByCriteria(criteria, userId = null) {
    const now = new Date();
    let startDate, endDate;

    switch (criteria) {
        case 'daily':
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            break;
        case 'weekly':
          const startOfWeek = now.getDate() - (now.getDay() === 0 ? 6 : now.getDay() - 1);
          startDate = new Date(now.getFullYear(), now.getMonth(), startOfWeek);
          endDate = new Date(now.getFullYear(), now.getMonth(), startOfWeek + 7);
          endDate.setHours(23, 59, 59, 999); // Include the entire Sunday
          break;
          
        case 'lastweek':
          const lastWeekStart = now.getDate() - (now.getDay() === 0 ? 6 : now.getDay() - 1) - 7;
          startDate = new Date(now.getFullYear(), now.getMonth(), lastWeekStart);
          endDate = new Date(now.getFullYear(), now.getMonth(), lastWeekStart + 7);
          endDate.setHours(23, 59, 59, 999); // Include the entire Sunday
          break;
          
        case 'monthly':
            startDate = new Date(now.getFullYear(), now.getMonth(), 1);
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
            break;
        case 'lastmonth':
            startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), 1);
            break;
        case 'last3months':
            startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), 1);
            break;
        case 'thisyear':
            startDate = new Date(now.getFullYear(), 0, 1);
            endDate = new Date(now.getFullYear() + 1, 0, 1);
            break;
        default:
            throw new Error('Invalid criteria');
    }

    const startTimestamp = Math.floor(startDate.getTime() / 1000).toString();
    const endTimestamp = (Math.floor(endDate.getTime() / 1000) - 1).toString();

    //console.log('Start of Period:', startTimestamp);
    //console.log('End of Period:', endTimestamp);

    try {
        let allDocuments = [];
        let limit = 100;
        let lastDocumentId = null;

        while (true) {
            let queryOptions = [
                Appwrite.Query.greaterThanEqual('date', startTimestamp),
                Appwrite.Query.lessThanEqual('date', endTimestamp),
                Appwrite.Query.orderDesc('clicks'),
                Appwrite.Query.limit(limit)
            ];
            if (lastDocumentId) {
                queryOptions.push(Appwrite.Query.cursorAfter(lastDocumentId));
            }
            const response = await this.db.listDocuments(
                ASBEK_DATABASE_ID,
                ASBEK_CLICK_HISTORY_COLLECTION_ID,
                queryOptions
            );

            allDocuments = allDocuments.concat(response.documents);

            if (response.documents.length < limit) {
                break; // No more documents to fetch
            }

            lastDocumentId = response.documents[response.documents.length - 1].$id;
        }

        const aggregatedClicks = allDocuments.reduce((acc, doc) => {
            if (acc[doc.userId]) {
                acc[doc.userId] += doc.clicks;
            } else {
                acc[doc.userId] = doc.clicks;
            }
            return acc;
        }, {});

        const clicks = Object.keys(aggregatedClicks).map(userId => ({
            userId: userId,
            clicks: aggregatedClicks[userId]
        }));

        clicks.sort((a, b) => b.clicks - a.clicks);

        if (userId) {
            // Find the rank of the specific user
            const rank = clicks.findIndex(click => click.userId === userId) + 1;
            return { rank, topClicks: clicks.slice(0, 50) };
        } else {
            return clicks.slice(0, 50);
        }

    } catch (err) {
        //console.error('Error fetching documents:', err);
        throw new Error('Internal Server Error');
    }
}


  getDateFilters(criteria) {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay())); // Monday of this week
    const lastWeekStart = new Date(today.setDate(today.getDate() - today.getDay() - 7));
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, 1);

    switch (criteria) {
      case 'daily':
        return { startDate: new Date(today.setHours(0, 0, 0, 0)), endDate: new Date(today.setHours(23, 59, 59, 999)) };
      case 'weekly':
        return { startDate: startOfWeek, endDate: new Date(today.setDate(today.getDate() + 6)) };
      case 'lastweek':
        return { startDate: lastWeekStart, endDate: new Date(lastWeekStart.setDate(lastWeekStart.getDate() + 6)) };
      case 'monthly':
        return { startDate: startOfMonth, endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0) };
      case 'lastmonth':
        return { startDate: lastMonthStart, endDate: new Date(lastMonthStart.getFullYear(), lastMonthStart.getMonth() + 1, 0) };
      case 'last3months':
        return { startDate: threeMonthsAgo, endDate: new Date(today.getFullYear(), today.getMonth(), 0) };
      case 'thisyear':
        return { startDate: startOfYear, endDate: new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999) };
      default:
        return null;
    }
  }
  async getTopClicksOfToday() {
    const today = new Date();
    const todayStart = Math.floor(new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime() / 1000);
    const todayEnd = Math.floor(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).getTime() / 1000) - 1;

    //console.log('Start of Today:', todayStart);
    //console.log('End of Today:', todayEnd);

    try {
        const response = await this.db.listDocuments(
            ASBEK_DATABASE_ID,
            ASBEK_CLICK_HISTORY_COLLECTION_ID,
            [
                Appwrite.Query.greaterThanEqual('date', todayStart.toString()),
                Appwrite.Query.lessThanEqual('date', todayEnd.toString()),
                Appwrite.Query.orderDesc('clicks'),
                Appwrite.Query.limit(50),
            ]
        );

        //console.log('Response:', response);

        const clicksToday = response.documents.map(doc => ({
            userId: doc.userId,
            date: doc.date,
            clicks: doc.clicks,
        }));

        // Output or process the data
        //console.log('Clicks Today:', clicksToday);

        return clicksToday;

    } catch (err) {
        //console.error('Error fetching documents:', err);
        throw new Error('Internal Server Error');
    }
}

  
  async getTopUsers(criteria = 'alltime') {
    const now = Date.now();

    // Check if cache is still valid
    if (this.cache.data && this.cache.criteria === criteria && (now - this.cache.timestamp) < this.cacheExpirationTime) {
      return this.cache.data;
    }

    // If a fetch operation is already in progress, return cached data
    if (this.fetching) {
      return this.cache.data;
    }

    // Cache is either empty or expired, fetch from Appwrite
    this.fetching = true; // Set flag to indicate fetching
    let sortField = 'coinValue';
    if (criteria === 'daily') sortField = 'dailyClickCount';
    else if (criteria === 'weekly') sortField = 'weeklyClickCount';
    else if (criteria === 'monthly') sortField = 'monthlyClickCount';

    try {
      const response = await this.db.listDocuments(
        ASBEK_DATABASE_ID,
        ASBEK_COLLECTION_ID,
        [
          Appwrite.Query.orderDesc(sortField),
          Appwrite.Query.limit(50),
        ]
      );

      let users = response.documents;

      // Separate top 3 all-time users
      let top3 = [];
      let rest = [];

      if (criteria === 'alltime') {
        top3 = users.slice(0, 3).map(doc => ({
          $id: doc.$id,
          first_name: doc.first_name,
          coinValue: doc.coinValue,
          [criteria]: doc[sortField],
        }));
        rest = users.slice(3).map(doc => ({
          $id: doc.$id,
          first_name: doc.first_name,
          coinValue: doc.coinValue,
          [criteria]: doc[sortField],
        }));
      } else {
        rest = users.map(doc => ({
          $id: doc.$id,
          first_name: doc.first_name,
          coinValue: doc.coinValue,
          [criteria]: doc[sortField],
        }));
      }

      // Update in-memory cache
      this.cache.data = { top3, rest };
      this.cache.timestamp = now;
      this.cache.criteria = criteria;

      // Save to local storage
      this.saveToLocalStorage();

      return { top3, rest };

    } catch (err) {
      //console.error('Error fetching documents:', err);
      throw new Error('Internal Server Error');
    } finally {
      this.fetching = false; // Reset flag after fetch completes
    }
  }

  async getUserRank(userId, criteria = 'alltime') {
    let sortField = 'coinValue';
    if (criteria === 'daily') sortField = 'dailyClickCount';
    else if (criteria === 'weekly') sortField = 'weeklyClickCount';
    else if (criteria === 'monthly') sortField = 'monthlyClickCount';

    try {
      let users = [];
      let response;
      let offset = 0;
      const limit = 100; // Appwrite's limit per request

      // Fetch all documents
      do {
        response = await this.db.listDocuments(
          ASBEK_DATABASE_ID,
          ASBEK_COLLECTION_ID,
          [
            Appwrite.Query.orderDesc(sortField),
            Appwrite.Query.limit(limit),
            Appwrite.Query.offset(offset)
          ]
        );
        users = users.concat(response.documents);
        offset += limit;
      } while (response.documents.length === limit);

      // Find user rank
      const userRank = users.findIndex(user => user.$id === userId) + 1;

      //console.log('Fetched users:', users);
      //console.log(`Rank of user ${userId}: ${userRank}`);

      if (userRank === 0) {
        //console.warn(`User with ID ${userId} not found.`);
        return null;
      }

      return userRank;

    } catch (err) {
      //console.error('Error fetching documents:', err);
      throw new Error('Internal Server Error');
    }
  }

  // Save cache data to local storage
  saveToLocalStorage() {
    // //console.log('daaaaaaarrrrrrrrrrrrrrrrrr');
    // //console.log(this.cache.data);
    // //console.log('ttttttttttttu');
    // //console.log(this.cache.timestamp);
    // //console.log('crite');
    // //console.log(this.cache.criteria);
    localStorage.setItem('appwriteCache', JSON.stringify({
      data: this.cache.data,
      timestamp: this.cache.timestamp,
      criteria: this.cache.criteria
    }));
  }

  // Load cache data from local storage
  loadFromLocalStorage() {
    const cached = localStorage.getItem('appwriteCache');
    if (cached) {
      const parsed = JSON.parse(cached);
      this.cache.data = parsed.data;
      this.cache.timestamp = parsed.timestamp;
      this.cache.criteria = parsed.criteria;
    }
  }

  // Automatically refresh cache every 10 minutes
  startAutoRefresh() {
    setInterval(async () => {
      await this.getTopUsers(this.cache.criteria || 'alltime');
    }, this.cacheExpirationTime);
  }
}

export default AppwriteService;
