import React from 'react';
import styled, { keyframes } from 'styled-components';
import { TelegramShareButton } from 'react-share';
import { FaTelegramPlane, FaCopy } from 'react-icons/fa';
import TransactionPage from './transaction_page';
import IDTranslator from '../api/IDTranslator';




export const CopyButton = ({ inviteLink, title }) => {
    const handleCopyClick = () => {
        // Translate userId before copying
        const translatedId = IDTranslator.translateId(inviteLink);
        const textToCopy = `${translatedId}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('Account ID copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    return (
        <div style={{
            display: 'flex',
            
        }}>
            {/* <TelegramShareButton url={inviteLink} title={title}>
                <button
                    style={{
                        flex: '1',
                        padding: '15px',
                        backgroundColor: '#0088cc',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '1.2rem',
                        marginRight: '10px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'background-color 0.3s ease', 
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#006699'}
                    onMouseOut={(e) => e.target.style.backgroundColor = '#0088cc'}
                >
                    <FaTelegramPlane style={{ marginRight: '8px' }} />
                    Invite Friend
                </button>
            </TelegramShareButton> */}
            <TransactionPage />
            <button
                onClick={handleCopyClick}
                style={{
                    flex: '1',
                    padding: '20px',
                    background: 'linear-gradient(135deg, #7b1fa2 30%, #ab47bc 90%)',
                    marginLeft: '10px',
                    marginTop: '22px',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '20px',
height: '50px',
                    cursor: 'pointer',
                    

                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'background-color 0.3s ease',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
 
                }}
                onMouseOver={(e) => e.target.style.background = 'linear-gradient(135deg, #6a1b9a 30%, #ab47bc 90%)'}
onMouseOut={(e) => e.target.style.background = 'linear-gradient(135deg, #7b1fa2 30%, #ab47bc 90%)'}
            >
                <FaCopy style={{ marginRight: '8px' }} />
                Copy your account Id
            </button>
        </div>
    );
};


