import { EarnContainer } from './styles';
import AppwriteService from '../api/scripts.js';
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import AdsComponent from '../AdsGram';
// import TopClicksOfToday from '../api/TopClicksOfToday';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import {FetchingText, RowContainer, RowLabel, RowValue, WhiteCard, CloseButton,GiveLifeButton, IframeContainer, Modal, ModalContent,  } from './earn_style.js';
import {
  APPWRITE_ENDPOINT,
  APPWRITE_PROJECT_ID,
  APPWRITE_DATABASE_ID,
  APPWRITE_COLLECTION_ID,
  TASKS_COLLECTION_ID
} from '../api/config';
// import TopUsers from './topchart';
import TopClicksPage from '../api/TopClicksPage';
import WeeklyPage from '../api/weeklyLive';
import { TopContainer } from './top_style';
const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

// Create a Skeleton component with the loading animation
const Skeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  height: 17px;
  width: 100px; /* Adjust the width as needed */
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;
const ImageSkeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const SkeletonName = styled(Skeleton)`
  height: 20px;
  width: 100px;
  margin-top: 5px;
`;

const SkeletonCoins = styled(Skeleton)`
  height: 20px;
  width: 50px;
  margin-top: 5px;
`;

const clicksAppwriteService = new AppwriteService();
const appwriteService = new AsbekAppwriteService();
const taskappwriteService = new AsbekAppwriteService(TASKS_COLLECTION_ID);

const TaskSection = () => {
  const [status, setStatus] = useState('');
  const [clicks, setClicks] = useState(0);
  const [showResumeButton, setShowResumeButton] = useState(false); 
  const [countdown, setCountdown] = useState(0);
  
  const [isFetchingCoinValue, setIsFetchingCoinValue] = useState(true);

  const [user, setUser] = useState(null);
  const [coinValue, setCoinValue] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [limitReached, setLimitReached] = useState(false); // State to manage if the limit is reached
  const [dailyClickCount, setDailyClickCount] = useState(0);
  const [weeklyClickCount, setWeeklyClickCount] = useState(null);
  const [monthlyClickCount, setMonthlyClickCount] = useState(0);
  const [documents, setDocuments] = useState([]);
  
  const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

  
    //console.log('User found:', usertg); // Log user details
    
    
    const userId = String(usertg.id).trim();
 


    const fetchAllDocuments = async () => {
      try {
          // setLoading(true);
          const allDocuments = await appwriteService.getAllDocuments();
          setDocuments(allDocuments);
          return allDocuments;
      } catch (error) {
          console.error('Error fetching all documents:', error);
         // setMessage({ type: 'error', text: 'Error fetching all documents.' });
      } finally{
          //  setLoading(false);
      }
  };

  const calculateRank = (weeklyClickCount) => {
    const sortedDocuments = documents.sort((a, b) => b.weeklyClickCount - a.weeklyClickCount);
    return sortedDocuments.findIndex(doc => doc.weeklyClickCount <= weeklyClickCount) + 1;
};
const userRank = calculateRank(weeklyClickCount);

  const handleClick = async () => {
    try {
     // await clicksAppwriteService.registerClick(userId);
      
      setStatus('Click registered successfully!');
    } catch (error) {
      setStatus('Failed to register click.');
      //console.error(error);
    }
  };
 

  

  useEffect(() => {
  
    const tg = window.Telegram.WebApp;
    tg.ready();

    const initDataUnsafe = tg.initDataUnsafe;
    const user = initDataUnsafe?.user;

    if (user) {
      //console.log('User found:', user); // Log user details
      setUser(user);
      
      const userId = String(user.id).trim();
      const storedCoinValue = localStorage.getItem(`coinValue_${userId}`);
  if (storedCoinValue !== null) {
    setCoinValue(parseInt(storedCoinValue, 10));
    setIsFetchingCoinValue(false);
  }
      appwriteService.getDocument(userId)
        .then((userDoc) => {
          if (userDoc) {
            const currentCoinValue = parseInt(userDoc.coinValue) || 0;
            const dailyClickCount = parseInt(userDoc.dailyClickCount) || 0;
            const weeklyClickCount = parseInt(userDoc.weeklyClickCount) || 0;
            const monthlyClickCount = parseInt(userDoc.monthlyClickCount) || 0;
            //console.log('Fetched click value:', dailyClickCount, weeklyClickCount.monthlyClickCount); // Log fetched coin value

            setDailyClickCount(dailyClickCount);
            setWeeklyClickCount(weeklyClickCount);
            setMonthlyClickCount(monthlyClickCount);
            //console.log('Fetched coin value:', currentCoinValue); // Log fetched coin value
            setCoinValue(currentCoinValue);
            // Save the coin value to local storage
           // fetchAllDocuments();
          localStorage.setItem(`coinValue_${userId}`, currentCoinValue);
            setIsFetchingCoinValue(false);
            const currentClickCount = parseInt(userDoc.clickCount) || 0;
            const lastReset = String(userDoc.lastReset) || String(Math.floor(new Date().getTime() / 1000)); // Retrieve as string or use current timestamp
            //console.log('lastReset:', lastReset);

            const today = String(Math.floor(new Date().getTime() / 1000)); // Current Unix timestamp as a string
            //console.log('today:', today);

            // Convert lastReset to seconds if it's in milliseconds
            const lastResetSeconds = lastReset.length > 10 ? Math.floor(parseInt(lastReset) / 1000) : lastReset;

            // Convert timestamps to Date objects
            const lastResetDate = new Date(lastResetSeconds * 1000);
            const todayDate = new Date(today * 1000);

            // Check if they are on the same day
            const isSameDay = lastResetDate.toDateString() === todayDate.toDateString();

            //console.log('Is today and last reset on the same day?', isSameDay);

            // Reset click count if last reset date is not today
            
            if (!isSameDay) {
              //console.log('anbesa:'); 
              setClickCount(0);
              appwriteService.updateDocument(userId, { clickCount: 0, lastReset: today });
              //console.log('gobez:'); 
            } else {
              setClickCount(currentClickCount);
              //console.log('else:', currentClickCount); 
            }

          } else {
            //console.error('Error: User document not found');
          }
        })
        .catch((error) => {
          //console.error('Error fetching user document:', error);
        });
        
    } else {
      //console.log('User not found in Telegram init data');
    }
    
  }, []);
  
  
  const lifeGiven = () => {
    
    if (clickCount >= 25000000) {
      const today = String(Math.floor(new Date().getTime()));
      const userId = String(user.id).trim();
      appwriteService.updateDocument(userId, { lastReset: today });
      setLimitReached(true);
      return;
    }else{
      setCountdown(15);
    }
  };

  const resumeGame = async () => {
  if (countdown === 0) {
    handleClick();
    setShowResumeButton(false);

    if (user) {
      try {
        // Use the user data from state instead of fetching again
        const now = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp
        await appwriteService.updateDocument(userId, { online: String(now) });

        // Calculate new values based on current state
        const newCoinValue = coinValue + 10;
        const newClickCount = clickCount + 1;
        const newDailyClickCount = dailyClickCount + 1;
        const newWeeklyClickCount = weeklyClickCount + 1;
        const newMonthlyClickCount = monthlyClickCount + 1;

        setCoinValue(newCoinValue);
        localStorage.setItem(`coinValue_${userId}`, newCoinValue);

        // Update the user document with new values
        await appwriteService.updateDocument(userId, {
          coinValue: newCoinValue,
          clickCount: newClickCount,
          dailyClickCount: newDailyClickCount,
          weeklyClickCount: newWeeklyClickCount,
          monthlyClickCount: newMonthlyClickCount
        });

        // Update local state
        setClickCount(newClickCount);
        setDailyClickCount(newDailyClickCount);
        setWeeklyClickCount(newWeeklyClickCount);
        setMonthlyClickCount(newMonthlyClickCount);

      } catch (error) {
        console.error('Error updating user data:', error);
      }
    }
  }
};

  // const resumeGame = async () => {
  //   if (countdown === 0) {
  //     handleClick();
  //     setShowResumeButton(false);
  //     if (user) {
  //       try {
  //       const userId = String(user.id).trim();
  //       //console.log('User ID:', userId);
  //       const userDoc = await appwriteService.getDocument(userId);
  //       if (userDoc) {
  //         const now = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp

  //         appwriteService.updateDocument(userId, { online: String(now)})
  //         // Retrieve the most recent values from Appwrite
  //         const currentCoinValue = parseInt(userDoc.coinValue) || 0;
  //         const currentClickCount = parseInt(userDoc.clickCount) || 0;
  //         const currentDailyClickCount = parseInt(userDoc.dailyClickCount) || 0;
  //         const currentWeeklyClickCount = parseInt(userDoc.weeklyClickCount) || 0;
  //         const currentMonthlyClickCount = parseInt(userDoc.monthlyClickCount) || 0;


  //       //  // const currentCoinValue = coinValue;
  //       //   const newCoinValue = currentCoinValue + 10;
  //       //   const newclickCount = clickCount + 1;
  //       // Calculate new values
  //       const newCoinValue = currentCoinValue + 10;
  //       const newClickCount = currentClickCount + 1;
  //       const newDailyClickCount = currentDailyClickCount + 1;
  //       const newWeeklyClickCount = currentWeeklyClickCount + 1;
  //       const newMonthlyClickCount = currentMonthlyClickCount + 1;
  //         setCoinValue(newCoinValue);
  //         localStorage.setItem(`coinValue_${userId}`, newCoinValue);
  //         // await appwriteService.updateDocument(userId, {
  //         //    coinValue: newCoinValue,
  //         //    clickCount: newclickCount,
  //         //    dailyClickCount: dailyClickCount + 1,
  //         //    weeklyClickCount: weeklyClickCount + 1,
  //         //    monthlyClickCount: monthlyClickCount + 1
  //         //   });
  //         await appwriteService.updateDocument(userId, {
  //           coinValue: newCoinValue,
  //           clickCount: newClickCount,
  //           dailyClickCount: newDailyClickCount,
  //           weeklyClickCount: newWeeklyClickCount,
  //           monthlyClickCount: newMonthlyClickCount
  //         });
  //         //console.log('Updated document:', updatedDoc); // Log updated document
  //          // Update local storage
  //          // Update state values
  //          setClickCount(newClickCount);
  //          setDailyClickCount(newDailyClickCount);
  //          setWeeklyClickCount(newWeeklyClickCount);
  //          setMonthlyClickCount(newMonthlyClickCount);
  //         // setClickCount(clickCount + 1);
  //         // setDailyClickCount(dailyClickCount + 1);
  //         // setWeeklyClickCount(weeklyClickCount + 1);
  //         // setMonthlyClickCount(monthlyClickCount + 1);
  //       } else {
  //         //console.error('Error: User document not found');
  //       }

  //       } catch (error) {
  //         //console.error('Error marking task as completed:', error);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if (countdown > 0) {
      const countdownInterval = setInterval(() => {
        setShowResumeButton(true);
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(countdownInterval);
    }
  }, [countdown]);
  const closeModal = () => {
    setLimitReached(false);
  };
  const left = 2500000 - clickCount
  return (
    <TopContainer>
      
    </TopContainer>
//     <EarnContainer>
      
//       <RowContainer>
//             <WhiteCard>
//                 <RowContainer>
//                     <RowLabel>Your Asbeks</RowLabel>
//                 </RowContainer>
//                 <RowContainer>
//                 {coinValue !== null ? (
//                         <RowValue>{coinValue}</RowValue>
//                     ) : (
//                         <Skeleton />
//                     )}
//                 </RowContainer>
//             </WhiteCard>

//             <WhiteCard>
//                 <RowContainer>
//                     <RowLabel>Remaining</RowLabel>
//                 </RowContainer>
//                 <RowContainer>
//                     <RowValue>Unlimited</RowValue>
//                 </RowContainer>
//             </WhiteCard>
//         </RowContainer>
     
//       <RowContainer>

//       <WhiteCard>
//                 <RowContainer>
//                     <RowLabel>Your Reward Clicks</RowLabel>
//                 </RowContainer>
//                 <RowContainer>
//                 {weeklyClickCount !== null ? (
//                         <RowValue>{weeklyClickCount}</RowValue>
//                     ) : (
//                         <Skeleton />
//                     )}
//                 </RowContainer>
//             </WhiteCard>

//             <WhiteCard>
//                 <RowContainer>
//                     <RowLabel>Your Reward Rank</RowLabel>
//                 </RowContainer>
//                 <RowContainer>
//                     {userRank !== 0 ? (
//      <RowValue >{userRank}  </RowValue>
// ) : (
//   <Skeleton />
// )} 
//                 </RowContainer>
//             </WhiteCard>
//       </RowContainer>
        
//         <GiveLifeButton 
//   onClick={(e) => { e.stopPropagation(); lifeGiven(); }} 
//   disabled={isFetchingCoinValue} // Disable button until coin value is fetched
// >
//   EARN ASBEK
// </GiveLifeButton>
// {isFetchingCoinValue && <FetchingText>Loading...</FetchingText>}


     
//       {limitReached && (
//         <Modal>
//           <ModalContent>
//             <CloseButton onClick={closeModal}>X</CloseButton>
//             <p>You have reached the maximum number of clicks for today.</p>
//           </ModalContent>
//         </Modal>
//       )}
//       {showResumeButton && (
//         <Modal>
//           <ModalContent>
//             <CloseButton onClick={resumeGame}>
//               {countdown > 0 ? countdown : 'Claim'}
//             </CloseButton>
//             <AdsComponent blockId="687" />
//             <IframeContainer>
//               <iframe
//                 title="External Content"
//                 src={'https://madurird.com/4/6246757'}
//               />
//             </IframeContainer>
//           </ModalContent>
//         </Modal>
//       )}
//       {/* <WeeklyPage/> */}
//   {/* <TopClicksPage/> */}
//       {/* <TopClicksOfToday/> */}
//       {/* <TopUsers/> */}
//     </EarnContainer>
  );
};

export default TaskSection;
