// src/WeeklyPage.js
import React, {useContext, useState, useEffect } from 'react';
import { CoinContext } from '../contextdtatashare/CoinContext.js';
import AppwriteService from './liveWeekly.js';
import { 
  ASBEK_ENDPOINT, 
  ASBEK_PROJECT_ID, 
  ASBEK_DATABASE_ID, 
  ASBEK_CLICK_HISTORY_COLLECTION_ID, 
  ASBEK_COLLECTION_ID
} from './config.js';
import {
    PageContainer,
    MainHeader,
    SectionHeader,
    InfoText,
    Separator,
    UserList,
    UserItem,
    UserHeading,
    UserDetail,
    Loader,
    ErrorMessage,
    PasswordField,
    SubmitButton
} from './realui.js';
import { LiveTv } from '@material-ui/icons';
import asbek1 from '../assets/asbek1.jpg';
import * as Appwrite from 'appwrite';
import { renderSkeleton } from '../asbek/skeleton.js';
import AsbekAppwriteService from './AsbekAppwriteService.js';
import TransferBirr from './TransferBirr.js';
import { getTopClicksByCriteria } from './AppwriteService.js';
import styled, { keyframes } from 'styled-components';
import { ToggleContainer } from '../asbek/top_style.js';


const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

// Create a Skeleton component with the loading animation
const Skeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  height: 24px;
  width: 100px; /* Adjust the width as needed */
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;
const ImageSkeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const SkeletonName = styled(Skeleton)`
  height: 20px;
  width: 100px;
  margin-top: 5px;
`;

const SkeletonCoins = styled(Skeleton)`
  height: 20px;
  width: 50px;
  margin-top: 5px;
`;

const transfer = new TransferBirr();
const asbekService = new AsbekAppwriteService();
const WeeklyPage = () => {
  const {
    theTopUsers,
    theTop3Users,
    resetClickCount,
    theCoinValue,
    theWeeklyClickCount,
    theClickCount,
    theDailyClickCount,
    theMonthlyClickCount,
    incrementCoinValue,
    incrementWeeklyClickCount,
    incrementClickCount,
    incrementDailyClickCount,
    incrementMonthlyClickCount,
    theUser,
    theLastReset
  } = useContext(CoinContext);

  const [customAmount, setCustomAmount] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [rankClickCount, setRankClickCount] = useState(0);
  const [topUsers, setTopUsers] = useState({ top3: [], rest: [] });
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
    const [userRank, setUserRank] = useState(null);

    const fixedUserId = '6307965462';
    const correctPassword = '14899315abdiisabaa';


  const client = new Appwrite.Client();

  client
    .setEndpoint(ASBEK_ENDPOINT)
    .setProject(ASBEK_PROJECT_ID);

   
    const fetchRank = async (criteria) => {
      const tg = window.Telegram.WebApp;
      tg.ready();
    
      const initDataUnsafe = tg.initDataUnsafe;
      const usertg = initDataUnsafe?.user;
      
      
        ////console.log('User found:', usertg); // Log user details
        
        
        const userId = String(usertg.id).trim();
        const now = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp
    
        asbekService.updateDocument(userId, { online: String(now) })
        
          // try {
          //     const data = await getTopClicksByCriteria(criteria, userId);
          //     if (data.rank) {
          //         setUserRank(data.rank);
                  
          //     } else {
          //         setUserRank(null);
                  
          //           }
          // } catch (err) {
             
          // } finally {
          //     setLoading(false);
          // }
      };

      const handleRankClick = () => {
        setRankClickCount(prevCount => prevCount + 1);
    };
    const handlePasswordSubmit = () => {
      if (password === correctPassword) {
          setIsPasswordCorrect(true);
          setPasswordError('');
      } else {
          setPasswordError('Incorrect password. Please try again.');
      }
  };
   
    const TransferButton = ({ userId, onTransfer }) => {
      const [amount, setAmount] = useState('');
      
      const handleAmountChange = (e) => {
          setAmount(e.target.value);
      };
  
      return (
          <div>
              <input
                  type="number"
                  value={amount}
                  onChange={handleAmountChange}
                  placeholder="Enter amount"
                  style={{ marginRight: '8px' }}
              />
              <button onClick={() => onTransfer(userId, amount)}>
                  Transfer Birr
              </button>
          </div>
      );
  };
  const handleBirrTransfer = async (recipientId, amount) => {
    const transactionId = `tx-${Date.now()}`;
    const timestamp = new Date().toISOString();
    
    // Convert amount to integer
    const amountInt = parseInt(amount, 10);

    // Check if amount is a valid number
    if (isNaN(amountInt) || amountInt <= 0) {
        console.error('Invalid amount:', amount);
        setSuccessMessage('Invalid amount. Please enter a valid number.');
        return;
    }

    const newHistoryDocument = {
        transactionId,
        type: 'Received',
        amount: amountInt,
        currency: 'ETB',
        sender: 'Asbek', // Assuming the sender is the fixed user
        recipient: recipientId,
        timestamp
    };

    try {
        await transfer.createHistoryDocument(newHistoryDocument);
        console.log('History document created successfully');
        setSuccessMessage(`Transferred ${amountInt} Birr to ${recipientId} successfully!`);
    } catch (error) {
        console.error('Failed to create history document:', error.message);
        setSuccessMessage('Failed to transfer Birr. Please try again.');
    }
};


  const fetchClickData = async (criteria) => {
    try {
      const appwriteService = new AppwriteService();
      const data = await appwriteService.getTopUsers(criteria);
      
      setTopUsers(data);
    } catch (error) {
      console.error('Error fetching weekly click data:', error);
    } finally {
      setLoading(false);
      fetchRank('weekly')
    }
  };

  const fetchFixedUserDetails = () => {
    // Implement this function to fetch details for specific users if needed
  };

  useEffect(() => {
    //fetchClickData('weekly');
    fetchFixedUserDetails();

    // const unsubscribe = client.subscribe(
    //   `databases.${ASBEK_DATABASE_ID}.collections.${ASBEK_COLLECTION_ID}.documents`,
    //   (response) => {
    //     if (response.events.includes('databases.*.collections.*.documents.*')) {
    //       fetchClickData('weekly');
    //     }
    //   }
    // );

    // return () => {
    //  unsubscribe();
    // };
  }, []);

  
  if (theTopUsers.rest.length === 0) return  (
    <div>  
      <ToggleContainer onClick={handleRankClick}>
      <LiveTv style={{ fontSize: '30px', fontweight: 'bold', color: 'red', marginRight: '8px' }} />  LIVE RANK</ToggleContainer>
       
      {renderSkeleton()}
    </div>
    );
  return (
    <div>
     
      {/* {userRank !== null ? (
     <InfoText onClick={handleRankClick}>Weekly Rank: {userRank}  </InfoText>
) : (
  <Skeleton />
)}  */}

            
{isPasswordCorrect ? (
               <div></div>
            ) : (
                <div>
                   {rankClickCount >= 5 && (
    <div>
        <InfoText>Please enter the password to view details:</InfoText>
        <PasswordField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
        />
        <SubmitButton onClick={handlePasswordSubmit}>Submit</SubmitButton>
        {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
    </div>
)}

                </div>
            )}
         <ToggleContainer onClick={handleRankClick}>
         <LiveTv style={{ fontSize: '30px', fontweight: 'bold', color: 'red', marginRight: '8px' }} />  LIVE RANK</ToggleContainer>
        <div>
      {/* <h1>Weekly Top Users</h1>
      <h2>Top 3 Users</h2>
      <ul>
        {topUsers.top3.map((user, index) => (
          <li key={user.$id}>
            {user.first_name} - {user.coinValue} coins
          </li>
        ))}
      </ul> */}
      <UserList>
      {theTopUsers.rest.map((user,index) => (
        <UserItem key={user.$id}>
            <UserDetail>
            <img src={asbek1} alt="User Profile" />
            <div>
            <name>{user.first_name || `${user.last_name}`}</name>
            <stars>Reward Clicks: {user.weeklyClickCount} </stars>
            {user.online ? (
                <span style={{ color: 'green' }}>Online</span>
            ) : (
                <span style={{ color: 'red' }}>Offline</span>
            )}
            </div>
            <count>{index + 4}</count>
            </UserDetail>
            {isPasswordCorrect &&  (
              <>
              <UserDetail>First Name: {user.first_name || 'N/A'}</UserDetail>
                                    <UserDetail>Last Name: {user.last_name || 'N/A'}</UserDetail>
                                    <UserDetail>Account Number: {user.accountNumber || 'N/A'}</UserDetail>
                                    <UserDetail>Account Name: {user.accountName || 'N/A'}</UserDetail>
                                    <UserDetail>Phone Number: {user.phoneNumber || 'N/A'}</UserDetail>
                                    <TransferButton userId={user.$id} onTransfer={handleBirrTransfer} />
                                    {successMessage && <div className="success-message">{successMessage}</div>}
                                    </>
            )}
            
        </UserItem>
        
                   
                ))}

      </UserList>
      
            </div>
            </div>
    
          );
        };
        
        export default WeeklyPage;
        
