import React, {useContext, useState, useEffect } from 'react';
import { CoinContext } from './contextdtatashare/CoinContext.js';
import TopSection from './asbek/TopSection.js';
import FriendsSection from './asbek/FriendsSection.js';
import EarnSection from './asbek/EarnSection.js';
import { Container, Nav, NavItem, NavIcon, NavText, BlockMessage } from './asbek/styles.js'; // Ensure BlockMessage is correctly imported from your styled components
import { FaWallet , FaTrophy, FaUserFriends, FaMoneyBillAlt, FaListAlt } from 'react-icons/fa';
import HistoryPage from './asbek/historyPage.js';
import AsbekAppwriteService from './api/AsbekAppwriteService.js';
import QRCode from 'react-qr-code';
import ClickHistory from './asbek/ClickHistory .js';
import FunctionExecutions from './api/listUsers.js';
import ListDocuments from './api/function.js';
import UserRank from './api/UserRank.js';
import SpeedTestPage from './api/SpeedTestPage.js';
import TopUsers from './api/TopUsers.js';
import TopClicksOfToday from './api/TopClicksOfToday.js';
import RealTimeRank from './api/realtime.js';
import TopClicksPage from './api/TopClicksPage.js';
import WeeklyPage from './api/weeklyLive.js';
import CandlestickGraph from './api/price.js';
import AppwriteComponent from './api/excutiom.js';
import LocalStorageDisplay from './api/LocalStorageDisplay.js';
import TransactionPage from './asbek/transaction_page.js';
import AlgoPage from './api/algorithm.js';
import SessionPage from './sessionPage.js';
import { ASBEK_ENDPOINT, ASBEK_PROJECT_ID, ASBEK_DATABASE_ID, ASBEK_COLLECTION_ID } from './api/config.js';
import * as Appwrite from 'appwrite';
import SomeComponent from './contextdtatashare/SomeComponent.js';
import AdDisplay from './monetag.js';
import TasksSection from './contextdtatashare/TasksSection.js';
import TaskSection from './asbek/TaskSection.js';
import styled from 'styled-components';
import WalletPage from './tonpython/WalletPage.js';
import CandlestickChart from './tonpython/PriceChart.js';
import SendCrypto from './tonpython/sendTon.js';
import PaymentPage from './contextdtatashare/payementpage.js';
import ShowAdButton from './asbek/monetagTelegram.js';
import WalletConnectPage from './ton/WalletConnectPage.js';
import TaskListing from './contextdtatashare/taskstest.js';
import axios from 'axios';
import TasksPage from './contextdtatashare/TasksPage.js';
import ConnectPage from './tonpython/ConnectPage.js';
import Game from './game/game.js';
import WithdrawCard from './tonpython/withdraw/WithdrawCard.js';
import ShoppingPage from './shop/components/ShoppingPage.js';
import AirtimeCard from './tonpython/airtime/AirtimeCard.js';
import Website from './asbekswebsite/website.js';
import { Helmet } from 'react-helmet'; 
const client = new Appwrite.Client()
    .setEndpoint(ASBEK_ENDPOINT)
    .setProject(ASBEK_PROJECT_ID);

const db = new Appwrite.Databases(client);

const appwriteService = new AsbekAppwriteService();

// Badge for showing the count of tasks
const Badge = styled.span`
  position: absolute;
  top: -8px; /* Positioned above the icon */
  right: -8px; /* Positioned to the right of the icon */
  background-color: red; /* Facebook blue color */
  color: white;
  border-radius: 50%;
  height: 20px; /* Fixed height for the circle */
  width: 20px; /* Fixed width for the circle */
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
  border: 2px solid white; /* Clean white border */
`;

function App() {
  
  const {
    taskCount,
    tasks,
    theBlock,
    theTopUsers,
    theTop3Users,
    resetClickCount,
    theCoinValue,
    theWeeklyClickCount,
    theClickCount,
    theDailyClickCount,
    theMonthlyClickCount,
    incrementCoinValue,
    incrementWeeklyClickCount,
    incrementClickCount,
    incrementDailyClickCount,
    incrementMonthlyClickCount,
    theUser,
    theLastReset
  } = useContext(CoinContext);
  const [activeTab, setActiveTab] = useState('top');
  // const [user, setUser] = useState(null);
  const [coinValue, setCoinValue] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionConflict, setSessionConflict] = useState(false);
  const [sessionIdToUpdate, setSessionIdToUpdate] = useState(null);
  const [formattedSessionTime, setFormattedSessionTime] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [sessionTime, setSessionTime] = useState('');
  const tg = window.Telegram.WebApp;

    tg.ready();

    const initDataUnsafe = tg.initDataUnsafe;

    const user = initDataUnsafe?.user;

    const userId = String(user?.id).trim();

    useEffect(() => {
      // Initialize Telegram Web App
      const tg = window.Telegram.WebApp;

      // Set header background color
      tg.MainButton.setParams({
          background_color: "#ff0000", // Set the header color to red
      });

      // Optional: Set the body background color
      document.body.style.backgroundColor = '#ffffff'; // Example background color
  }, []);
    // Apply styles based on the color scheme
   
    const formatTimeDifference = (differenceInMs) => {
    const seconds = Math.floor(differenceInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
      
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    }
  };
  // const telegramWebApp = window.Telegram.WebApp;

  // telegramWebApp.colorScheme
  useEffect(() => {
    

    //checkSession();

    // const unsubscribe = client.subscribe(
    //     `databases.${ASBEK_DATABASE_ID}.collections.${ASBEK_COLLECTION_ID}.documents`,
    //     (response) => {
    //         //console.log('Subscription response:', response);
    //         if (response.events.includes('databases.*.collections.*.documents.*')) {
    //             checkSession();
    //         }
    //     }
    // );

    // return () => {
    //     unsubscribe();
    // };
  }, []);

  const checkSession = async () => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    const initDataUnsafe = tg.initDataUnsafe;
    const user = initDataUnsafe?.user;

    const userId = String(user.id).trim();
    try {
      const service = new AsbekAppwriteService();
      const result = await service.handleSession(userId);
      setSessionTime(result.sessionTime); // Set the session time

      // Calculate and update the formatted session time
      const sessionDate = new Date(result.sessionTime);
      const now = new Date();
      const timeDifference = now - sessionDate;
      setFormattedSessionTime(formatTimeDifference(timeDifference));

      // If the session is less than 1 minute old, hide the button
      if (timeDifference < 300000) {
        setShowButton(false);
      } else {
        setShowButton(true);
      }

      if (result.sessionConflict) {
        setSessionConflict(true);
        setSessionIdToUpdate(result.sessionIdToUpdate);
        setSessionTime(result.sessionTime);
      } else {
        setMessage(result.message);
        setSessionTime(result.sessionTime);
      }
    } catch (err) {
      setError(`CRITICAL ERROR: ${err.message}. Immediate action required.`);
    } finally {
      setLoading(false);
    }
  };
  
  const handleConfirmSession = async () => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    const initDataUnsafe = tg.initDataUnsafe;
    const user = initDataUnsafe?.user;

    const userId = String(user.id).trim();
    try {
      const service = new AsbekAppwriteService();
      const result = await service.updateSession(userId, sessionIdToUpdate);
      window.location.reload();
      setMessage(result.message);
      setSessionConflict(false);
    } catch (err) {
      setError(`CRITICAL ERROR: ${err.message}. Immediate action required.`);
    }
  };

  const handleDenySession = () => {
    setError('SECURITY BREACH: Access denied due to session conflict. We may ban your account.');
    setSessionConflict(false);
  };

  const renderContent = () => {
    if (theBlock) {
      return (
        <BlockMessage>
          <h2>Access Denied</h2>
          <p>You have been blocked due to violating our terms of service.</p>
          <p>Please contact support for further assistance.</p>
        </BlockMessage>
      );
    }

    switch (activeTab) {
      case 'top':
        return <TopSection />;
      case 'wallet':
        return <WalletConnectPage />;
      case 'friends':
        return <FriendsSection />;
      case 'tasks':
        return <TaskListing />;
      case 'history':
        return <HistoryPage />;
      default:
        return <TopSection />;
    }
  };
  // if (loading) {
  //   return (
  //     <div style={styles.loading}>
  //       <p>Loading critical session data... Please wait.</p>
  //     </div>
  //   );
  // }

  if (sessionConflict) {
    return (
      <div style={styles.warning}>
      <p>DANGEROUS VIOLATION:</p>
      <p>If Your session is active on another device.</p>
      <p>We do not allow the use of multiple devices. Continuing to do so may result in a permanent ban.</p>
      <p>You must choose one device to continue.</p>
      {/* <p>{sessionTime}</p> */}
      <p>Session started: {formattedSessionTime}</p>
      <div style={styles.buttonContainer}>
      {showButton && (
<button style={styles.buttonConfirm} onClick={handleConfirmSession}>
  Yes, use this device
</button>
)}
        </div>
      </div>
    );
  }
  if (userId == 'undefined') {
  return <Website />;
}
  return (
    <>
    <Helmet>
      <title>Asbeks - Earn Rewards, Boost Your Telegram Audience, Complete Tasks, and Grow with the TON Blockchain</title>

        <meta
          name="description"
          content="Asbeks empowers you to grow your Telegram audience effortlessly. Complete tasks, watch ads, engage with real users, and earn TON cryptocurrency rewards. Join a dynamic community of over 400k users!"
        />
        <meta 
    name="keywords" 
    content="Asbeks, earn money online, grow Telegram audience, task management, TON blockchain, cryptocurrency rewards, task creators, Telegram engagement, ad watching, decentralized platform, increase followers, TikTok, YouTube, blockchain rewards, Telegram groups, rewards system, community building, real users, secure transactions, task rewards, platform for creators, Telegram rewards, engagement platform" 
  />
        <meta
          property="og:title"
          content="Asbeks - Boost Your Telegram Reach, Engage, and Earn TON"
        />
        <meta
          property="og:description"
          content="Asbeks helps you grow your Telegram following with real interactions. Watch ads, complete tasks, and earn TON cryptocurrency rewards while joining a thriving community of 400k+ users."
        />
        <meta
          property="og:image"
          content="https://github.com/asbeks/asbeks.github.io/blob/main/photo_2024-12-14_23-36-28.jpg?raw=true"
        />
        <meta property="og:url" content="https://www.asbeks.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Asbeks - Boost Your Telegram Reach, Engage, and Earn TON"
        />
        <meta
          name="twitter:description"
          content="Grow your Telegram reach, complete tasks, and earn TON cryptocurrency with Asbeks. Watch ads and engage with real users in our growing community of over 400k members."
        />
        <meta
          name="twitter:image"
          content="https://github.com/asbeks/asbeks.github.io/blob/main/photo_2024-12-14_23-36-28.jpg?raw=true"
        />
        <meta name="twitter:creator" content="@AsbeksOfficial" />
      </Helmet>
    
    <Container>
      {renderContent()}
      {!theBlock && (
        <Nav>
          <NavItem active={activeTab === 'top'} onClick={() => setActiveTab('top')}>
            <NavIcon active={activeTab === 'top'}>
              <FaTrophy />
            </NavIcon>
            <NavText active={activeTab === 'top'}>Top</NavText>
          </NavItem>
          <NavItem active={activeTab === 'tasks'} onClick={() => setActiveTab('tasks')} style={{ position: 'relative' }}>
  <NavIcon active={activeTab === 'tasks'}>
    <FaMoneyBillAlt />
  </NavIcon>
  <NavText active={activeTab === 'tasks'}>Tasks</NavText>
  {taskCount > 0 && (
  <Badge>{taskCount}</Badge>
)}
 
</NavItem>

<NavItem active={activeTab === 'wallet'} onClick={() => setActiveTab('wallet')}>
            <NavIcon active={activeTab === 'wallet'}>
              <FaWallet  />
            </NavIcon>
            <NavText active={activeTab === 'wallet'}>Wallet</NavText>
          </NavItem>
          <NavItem active={activeTab === 'friends'} onClick={() => setActiveTab('friends')}>
            <NavIcon active={activeTab === 'friends'}>
              <FaUserFriends />
            </NavIcon>
            <NavText active={activeTab === 'friends'}>Friends</NavText>
          </NavItem>

          

          <NavItem active={activeTab === 'history'} onClick={() => setActiveTab('history')}>
            <NavIcon active={activeTab === 'history'}>
              <FaListAlt />
            </NavIcon>
            <NavText active={activeTab === 'history'}>History</NavText>
          </NavItem>
        </Nav>
      )}
    </Container>
    </>
  );
}
const styles = {
  loading: {
    color: '#000',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px',
  },
  error: {
    color: '#ff0000',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '20px',
  },
  warning: {
    color: '#ff3300',
    backgroundColor: '#fff5f5',
    border: '2px solid #ff0000',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '30px',
    borderRadius: '5px',
    margin: '20px',
  },
  buttonContainer: {
    marginTop: '20px',
  },
  buttonConfirm: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonDeny: {
    backgroundColor: '#ff0000',
    color: '#fff',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  message: {
    color: '#333',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '20px',
  },
};
export default App;
