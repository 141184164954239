import React, { useState } from 'react';
import { Client, Functions } from 'appwrite';
import { ASBEK_PROJECT_ID, API_KEY, API_ENDPOINT, ASBEK_FUNCTION_ID , } from './config.js';

// Initialize ASBEK client
const client = new Client()
    .setEndpoint(`${API_ENDPOINT}/functions/${ASBEK_FUNCTION_ID}/executions`) 
    .setProject(ASBEK_PROJECT_ID) // Your project ID

const functions = new Functions(client);

const FunctionExecutions = () => {
    const [executions, setExecutions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchExecutions = async () => {
        setLoading(true);
        try {
            const response = await functions.listExecutions(
                ASBEK_FUNCTION_ID // Function ID
            );

            // Log response for debugging
            console.log('API Response:', response);

            // Safeguard against unexpected response format
            const executionsData = response.executions || [];
            if (Array.isArray(executionsData)) {
                setExecutions(executionsData);
            } else {
                console.error('Unexpected response format:', response);
                setExecutions([]);
            }
        } catch (err) {
            console.error('Error fetching executions:', err);
            setError(err.message);
            setExecutions([]); // Ensure executions is always an array
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Function Executions</h1>
            <button onClick={fetchExecutions} disabled={loading}>
                {loading ? 'Loading...' : 'Fetch Executions'}
            </button>
            {error && <p>Error: {error}</p>}
            <ul>
                {executions.length > 0 ? (
                    executions.map((execution) => (
                        <li key={execution.$id}>
                            <p><strong>ID:</strong> {execution.$id}</p>
                            <p><strong>Function ID:</strong> {execution.functionId}</p>
                            <p><strong>Status:</strong> {execution.status}</p>
                            <p><strong>Created At:</strong> {new Date(execution.$createdAt).toLocaleString()}</p>
                        </li>
                    ))
                ) : (
                    <p>No executions found.</p>
                )}
            </ul>
        </div>
    );
};

export default FunctionExecutions;
