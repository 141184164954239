import React, { useState, useEffect } from 'react';
import AsbekAppwriteService from './api/AsbekAppwriteService';
import { ASBEK_ENDPOINT, ASBEK_PROJECT_ID, ASBEK_DATABASE_ID, ASBEK_COLLECTION_ID } from './api/config.js';
import * as Appwrite from 'appwrite';
const client = new Appwrite.Client()
    .setEndpoint(ASBEK_ENDPOINT)
    .setProject(ASBEK_PROJECT_ID);

const db = new Appwrite.Databases(client);
const SessionPage = () => {
  //   const tg = window.Telegram.WebApp;
  //   tg.ready();

  //   const initDataUnsafe = tg.initDataUnsafe;
  //   const user = initDataUnsafe?.user;

    
  // const userId = String(user.id).trim();
  const userId = 6428496396;
  const [message, setMessage] = useState('');
  const [sessionTime, setSessionTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionConflict, setSessionConflict] = useState(false);
  const [sessionIdToUpdate, setSessionIdToUpdate] = useState(null);
  const [timePassed, setTimePassed] = useState('');
  const [formattedSessionTime, setFormattedSessionTime] = useState('');
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    

    checkSession();

    const unsubscribe = client.subscribe(
        `databases.${ASBEK_DATABASE_ID}.collections.${ASBEK_COLLECTION_ID}.documents`,
        (response) => {
            //console.log('Subscription response:', response);
            if (response.events.includes('databases.*.collections.*.documents.*')) {
                checkSession();
            }
        }
    );

    return () => {
        unsubscribe();
    };
  }, [userId]);
const formatTimeDifference = (differenceInMs) => {
  const seconds = Math.floor(differenceInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
};

  const checkSession = async () => {
    try {
      const service = new AsbekAppwriteService();
      const result = await service.handleSession(userId);
      setSessionTime(result.sessionTime); // Set the session time

        // Calculate and update the formatted session time
        const sessionDate = new Date(result.sessionTime);
        const now = new Date();
        const timeDifference = now - sessionDate;
        setFormattedSessionTime(formatTimeDifference(timeDifference));

        // If the session is less than 1 minute old, hide the button
    if (timeDifference < 60000) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }

      if (result.sessionConflict) {
        setSessionConflict(true);
        setSessionIdToUpdate(result.sessionIdToUpdate);
        setSessionTime(result.sessionTime);
      } else {
        setMessage(result.message);
        setSessionTime(result.sessionTime);
      }
    } catch (err) {
      setError(`CRITICAL ERROR: ${err.message}. Immediate action required.`);
    } finally {
      setLoading(false);
    }
  };
  const handleConfirmSession = async () => {
    try {
      const service = new AsbekAppwriteService();
      const result = await service.updateSession(userId, sessionIdToUpdate);
      setMessage(result.message);
      setSessionConflict(false);
    } catch (err) {
      setError(`CRITICAL ERROR: ${err.message}. Immediate action required.`);
    }
  };

  const handleDenySession = () => {
    setError('SECURITY BREACH: Access denied due to session conflict. We may ban your account.');
    setSessionConflict(false);
  };

  if (loading) {
    return (
      <div style={styles.loading}>
        <p>Loading critical session data... Please wait.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.error}>
        <p>{error}</p>
      </div>
    );
  }

  if (sessionConflict) {
    return (
      <div style={styles.warning}>
        <p>DANGEROUS VIOLATION:</p>
        <p>If Your session is active on another device.</p>
        <p>We do not allow the use of multiple devices. Continuing to do so may result in a permanent ban.</p>
        <p>You must choose one device to continue.</p>
        {/* <p>{sessionTime}</p> */}
        <p>Session started: {formattedSessionTime}</p>
        <div style={styles.buttonContainer}>
        {showButton && (
  <button style={styles.buttonConfirm} onClick={handleConfirmSession}>
    Yes, use this device
  </button>
)}
          {/* <button style={styles.buttonConfirm} onClick={handleConfirmSession}>
            Yes, use this device
          </button>
          <button style={styles.buttonDeny} onClick={handleDenySession}>
            No, deny access
          </button> */}
        </div>
      </div>
    );
  }

  return (
    <div style={styles.message}>
      <p>{message}</p>
      {/* <p>{sessionTime}</p> */}
        <p>Session started: {formattedSessionTime}</p>
    </div>
  );
};

const styles = {
  loading: {
    color: '#000',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px',
  },
  error: {
    color: '#ff0000',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '20px',
  },
  warning: {
    color: '#ff3300',
    backgroundColor: '#fff5f5',
    border: '2px solid #ff0000',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '30px',
    borderRadius: '5px',
    margin: '20px',
  },
  buttonContainer: {
    marginTop: '20px',
  },
  buttonConfirm: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonDeny: {
    backgroundColor: '#ff0000',
    color: '#fff',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  message: {
    color: '#333',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '20px',
  },
};

export default SessionPage;
