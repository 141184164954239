import styled, { keyframes } from 'styled-components';

const shootingAnimation = keyframes`
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw) translateY(-100vh);
    opacity: 0;
  }
`;

export const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Ensures stars do not overflow */
  z-index: 1; /* Positioned behind other content */
  pointer-events: none; /* Ensures stars do not interfere with interactions */
  will-change: transform, opacity; /* Performance optimization */
`;

export const Star = styled.div`
z-index: 0;
color: gold;
  position: absolute;
  left: ${(props) => Math.random() * 100}%; /* Randomize horizontal starting position */
  top: ${(props) => Math.random() * 100}%; /* Randomize vertical starting position */
  font-size: 20px; /* Adjust size of star emoji */
  animation: ${shootingAnimation} ${(props) => Math.random() * 10 + 6}s linear infinite; /* Slower and optimized animation */
`;

export const TopContainer = styled.div`
  position: relative;
  background: linear-gradient(135deg, #e0f7fa, #80deea, #a0e0f1);
  padding: 20px;
  border-radius: 10px;
 // margin-bottom: 6px; /* Adjusted for navigation bar */
 
  overflow: hidden; /* Ensures stars do not overflow */
  z-index: 0; /* Positioned above the stars */
`;

// export const TopContainer = styled.div`
//   position: relative;
//   background: linear-gradient(135deg, #e0f7fa, #80deea, #a0e0f1);
//   padding: 20px;
//   border-radius: 10px;
//   margin-bottom: 0; /* Removed margin to avoid extra space */
//   overflow: hidden; /* Prevent overflow */
//   z-index: 0;

//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start; /* Align content to the top */
//   align-items: center; /* Center content horizontally */
  
//   height: auto; /* Auto height based on content */
//   min-height: 100%; /* Ensure the container grows to fit its parent */
  
//   > *:last-child {
//     margin-bottom: 0; /* Ensure the last child has no margin */
//   }
// `;

export const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 10px;
`;

// export const TopContainer = styled.div`
//   background: #f0f0f0;
//   padding: 20px;
//   border-radius: 10px;
//   margin-bottom: 6px; /* Adjusted for navigation bar */
// `;

export const Leaderboard = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Profile = styled.div`
z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  name {
    margin-top: 10px; /* Adjust spacing if needed */
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10ch;
  }
  .image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.background || 'transparent'};
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width + 'px' : '60px')};
    height: ${(props) => (props.height ? props.height + 'px' : '60px')};
  }

  .king-emoji {
    position: absolute;
    top: -30px;
    left: 22px;
    font-size: 40px;
  }

  .badge {
    position: absolute;
    bottom: -5px;
    background-color: blue;
    color: white;
    border-radius: 12px;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
  }

  img {
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width - 10 + 'px' : '65px')};
    height: ${(props) => (props.height ? props.height - 10 + 'px' : '65px')};
  }
`;


export const Badge = styled.div`
  position: absolute;
  bottom:${(props) => (props.bottom ? props.bottom + 'px' : '18px')};
  left: ${(props) => (props.left ? props.left + 'px' : '19px')};
  transform: translate(50%, -50%);
  background-color: #008CBA; /* Blue circle */
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
`;

export const RowContainer = styled.div`
  color: #808080;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

export const WhiteCard = styled.div`
z-index: 2;
  background-color: #ffffff; /* White background */
  width: 45%;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
`;
export const RowLabel = styled.div`
  font-weight: bold;
  font-size: 13px;
`;

export const RowValue = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #333333;
`;

export const ListProfile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  img {
    border-radius: 50%;
    width: ${(props) => (props.width ? props.width + 'px' : '50px')};
    height: ${(props) => (props.height ? props.height + 'px' : '50px')};
    margin-right: 10px;
  }

  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  name {
    font-weight: bold;
    font-size: 15px;
    color: #333;
    margin-bottom: 0px;
  }

  stars {
    font-size: 14px;
    color: #666;
  }

  count {
    margin-left: auto;
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }

  /* Very Dim Divider */
  ::after {
    content: '';
    flex: none;
    width: 100%;
    height: 0.3px; /* Adjust thickness as needed */
    background: linear-gradient(135deg, #a8edea, #fed6e3); /* Soft gradient for a modern look */
    margin: 10px 0; /* Spacing above and below the divider */
  }
`;


export const LoadingContainer = styled.div`
  /* Optional: Adjust positioning and styling for the loader container */

  /* Center the loader */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Fullscreen overlay example */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  z-index: 999; /* Ensure it's above other content */

  /* Alternatively, you can adjust size and positioning for a specific container */
  /* Example for centered loading spinner within a specific container */
  /* position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  */
`;


export const ToggleContainer = styled.div`
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 13px;
  font-weight: bold; /* Makes the text bold */
  background: linear-gradient(135deg, #a8edea, #fed6e3); /* Soft gradient for a modern look */
`;





export const ToggleItem = styled.div`
font-size: 14px; /* Adjusted font size */
  font-weight: 600; /* Adjusted font weight */
  text-align: center;
  cursor: pointer;
  padding: 5px 10px;
  color: ${(props) => (props.active ? 'blue' : '#808080')};
  border-radius: 50px;
  margin: 0 5px;
  border-bottom: ${(props) => (props.active ? '3px solid blue' : 'none')}; /* Indicator for active item */
`;

export const Divider = styled.div`
 content: '';
    flex: none;
    width: 100%;
    height: 1px; /* Adjust thickness as needed */
    background: linear-gradient(135deg, #a8edea, #fed6e3); /* Soft gradient for a modern look */
    margin: 10px 0; /* Spacing above and below the divider */

`;

export const LeaderboardContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensure content starts from the left */
`;


