import React, { useContext, useState ,useEffect, useRef } from 'react';
import {
  Card,
  Title,
  Balance,
  Input,
  Button,
  Message,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  Skeleton,
  TableWrapper,
  CollapsibleContainer,
  DropdownIcon, FilterButton, FilterButtonContainer,FilterContainer
} from "./AirtimeCardStyle";
import { CoinContext } from "../../contextdtatashare/CoinContext";
import AsbekAppwriteService from '../../api/AsbekAppwriteService';
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { ErrorMessage } from '../TonToAsbeksSwap.styles';

const formatValue = (value) => {
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(1)}B`;
  }
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(1)}M`;
  }
  if (value >= 1_000) {
    return `${(value / 1_000).toFixed(1)}k`;
  }
  return value.toString();
};
const appwriteService = new AsbekAppwriteService(); 

const AirtimeCard = () => {
  
  const wallet = '';
  const userFriendlyAddress = '';
  const tonToAsbeksRate = 10000; // Example: 1 TON = 10,000 Asbeks (Optional if you have fixed rate)
 
  const {withdrawRequestType, setWithdrawalRequests, setWithdrawRequestType, withdrawalRequests, withdrawLimit, theCoinValue, theasbeksPrice, decrementCoinValue } = useContext(CoinContext);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [error, setError] = useState('')
  const [dollarEquivalent, setDollarEquivalent] = useState(0);

  const tg = window.Telegram.WebApp;
  
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;


  const userId = String(usertg?.id).trim();

  const toggleTable = () => {
    setIsTableOpen((prev) => !prev);
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    setAmount(inputValue);

    // Update dollar equivalent dynamically
    if (inputValue && theasbeksPrice) {
      setDollarEquivalent((inputValue * theasbeksPrice).toFixed(5));
    } else {
      setDollarEquivalent(0);
    }
  };
  const handleWithdrawClick = async () => {
    setLoading(true);
    try {
      //if (dollarEquivalent < withdrawLimit) {}
      if (parseFloat(amount) > parseFloat(theCoinValue)) {
        setMessage("Amount exceeds your available balance!");
        setIsSuccess(false);
        setLoading(false);
        return;
      }
      if (amount < withdrawLimit) {
        setMessage("Minimum withdrawal amount not reached!");
        setIsSuccess(false);
      } else {
        await appwriteService.createWithdrawalRequest(
          {
            userId: userId,
            asbeks:  parseFloat(amount),
            dollar: parseFloat(dollarEquivalent),
            ton: 0,
            wallet: String(userFriendlyAddress)
          }
        )
        setMessage("Withdrawal requested successfully!");
        setIsSuccess(true);
        decrementCoinValue(parseFloat(amount))
        
      }
      setLoading(false);
      setAmount("");
    } catch (error) {
      //console.log('jkhgfgh', error)
      setMessage("Something went wrong please try again!");
        setIsSuccess(false);
      setLoading(false);
      setAmount("");
    }
      
    
  };

  const isValidAmount = amount >= withdrawLimit;

  const openModal = (action, requestId) => {
    setCurrentAction(action);
    setCurrentRequestId(requestId);
    setModalOpen(true);
  };

  const confirmAction = () => {
    if(userId == '445929610'){
      if (currentAction === "Approve") {
        //console.log(`Approved request with ID: ${currentRequestId}`);
        updateRequestStatus(currentRequestId, "approved");
      } else if (currentAction === "Decline") {
        //console.log(`Declined request with ID: ${currentRequestId}`);
        updateRequestStatus(currentRequestId, "declined");
      }
      setModalOpen(false);
    }
    
  };

  const updateRequestStatus = async (id, newStatus) => {
    // Example: Update request status locally or via API
    await appwriteService.updateWithdrawalRequestStatus(
      {
        requestId: id,
        status: newStatus,
        userIdCheck: userId
      }
    )
    
    // Update state if necessary
  };
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false; // Set to false after the first render
      return; // Skip the effect logic on the first render
    }

    setWithdrawalRequests([]); // Run this only when withdrawRequestType changes after the initial render
  }, [withdrawRequestType]);


  const handleStatusChange = (newStatus) => {
    toggleTable()
    //setWithdrawalRequests([]);
    //console.log('Status changed:', newStatus);
    setWithdrawRequestType(newStatus); // Update the status based on the button clicked
  };

  return (  
    
    <Card>
      <Title>Withdraw Funds</Title>
      {!wallet && (
          <ErrorMessage>
            <span>No wallet connected. Please connect your wallet to continue.</span>
            
          </ErrorMessage>
        )}
      {theCoinValue !== null ? (
        <Balance>{formatValue(theCoinValue)} Asbeks</Balance>
      ) : (
        <Skeleton />
      )}

      <center>
        {theasbeksPrice !== null ? (
          <p style={{ color: "gray", fontSize: "0.9em" }}>
            ≈ ${parseFloat(formatValue(theCoinValue * theasbeksPrice)).toFixed(5)}
          </p>
        ) : (
          <Skeleton />
        )}
      </center>

      <p style={{ color: "#616161", fontSize: "0.9em", fontFamily: "Roboto", fontWeight: "300" }}>
    Minimum withdrawal: will be setted soon
  </p>

      {message && <Message success={isSuccess}>{message}</Message>}
      <Input
        type="number"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Enter Asbeks"
      />
      { dollarEquivalent !== 0 && (
        <p style={{ color: "#616161", fontSize: "0.9em" }}>
          ≈ ${dollarEquivalent || 0} (USD)
        </p>
      )}
      
      <Button onClick={handleWithdrawClick} disabled={true || !wallet|| loading || !isValidAmount || !theCoinValue || !withdrawLimit }>
        {loading ? "Processing..." : "Request Withdraw"}
      </Button>
      <center>
        <Title>Your Requests</Title>
      </center>
      <DropdownIcon open={isTableOpen} onClick={toggleTable}>
          ▼
        </DropdownIcon>
       
       
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>

            <FilterButton onClick={() => handleStatusChange('pending')}>Pending</FilterButton>
            <FilterButton onClick={() => handleStatusChange('declined')}>Declined</FilterButton>
            <FilterButton onClick={() => handleStatusChange('approved')}>Approved</FilterButton>
       
          </div>
       
        
        
      
        <CollapsibleContainer open={isTableOpen}>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>ID</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Amount (Asbeks)</TableHeader>
              <TableHeader>Created At</TableHeader>
              <TableHeader>Wallet</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {withdrawalRequests.map((request) => (
              <TableRow key={request.$id}>
                <TableCell>
                  {request.$id ? (
                    <span
                      onClick={() => navigator.clipboard.writeText(request.$id)}
                      style={{ cursor: "pointer", color: "grey" }}
                      title="Click to copy full wallet address"
                    >
                      {`${request.$id.slice(0, 3)}...${request.$id.slice(-3)}`}
                    </span>
                  ) : (
                    <span style={{ color: "gray" }}>N/A</span>
                  )}
                </TableCell>

                <TableCell>
                  {request.status === "pending" && userId == '445929610' ? (
                    <>
                    <span 
                    
                    style={{
                      color: request.status === 'approved' ? 'green' :
                            request.status === 'pending' ? 'gray' : 
                            request.status === 'declined' ? 'red' : 'black'
                    }}
                  >
                    {request.status}
                      </span>

                      <button
                        onClick={() => openModal("Approve", request.$id)}
                        style={{
                          background: "transparent",
                          color: "green",
                          border: "none",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => openModal("Decline", request.$id)}
                        style={{
                          background: "transparent",
                          color: "red",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        Decline
                      </button>
                    </>
                  ) : (
                    <span 
                    style={{
                      color: request.status === 'approved' ? 'green' :
                            request.status === 'pending' ? 'gray' : 
                            request.status === 'declined' ? 'red' : 'black'
                    }}
              >
                {request.status}
              </span>

                  )}
                </TableCell>
                <TableCell>{request.asbeks}</TableCell>
                <TableCell>{new Date(request.$createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  {request.wallet ? (
                    <span
                      onClick={() => navigator.clipboard.writeText(request.wallet)}
                      style={{ cursor: "pointer", color: "grey" }}
                      title="Click to copy full wallet address"
                    >
                      {`${request.wallet.slice(0, 3)}...${request.wallet.slice(-3)}`}
                    </span>
                  ) : (
                    <span style={{ color: "gray" }}>N/A</span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      </CollapsibleContainer>
      {/* Modal for Confirmation */}
      {modalOpen && (
        <div style={{
          background: "linear-gradient(135deg, #ff7e5f, #feb47b)",  // Gradient with solid colors
          padding: "20px",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1000,
          borderRadius: "10px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)"
        }}
          >
          <p>Are you sure you want to {currentAction.toLowerCase()} this request?</p>
          <button onClick={confirmAction} style={{ marginRight: "10px" }}>
            Yes
          </button>
          <button onClick={() => setModalOpen(false)}>No</button>
        </div>
      )}
    </Card>
    

);
  

};

export default AirtimeCard;
