import React, {useContext, useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import {FriendRowContainer,FriendRowLabel,WhiteFriendsCard, WhiteCardCount,Container,GiveLifeButton, StarsWrapper , Star,WhiteCard, TopContainer, Leaderboard, Profile, RowLabel, RowValue, Badge, ListProfile, RowContainer } from '../asbek/friends_style';
import { CoinContext } from '../contextdtatashare/CoinContext';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import asbek from '../assets/asbek.jpg';
import asbek1 from '../assets/asbek1.jpg';
import asbek2 from '../assets/asbek2.jpg';
import asbek3 from '../assets/asbek3.jpg';
import asbek4 from '../assets/asbek4.jpg';
import asbek5 from '../assets/asbek5.jpg';
import { FaCopy } from 'react-icons/fa';
import { TelegramShareButton } from 'react-share';
import { TelegramShare } from 'react-share-kit';
import LoadingSpinner from './LoadingSpinner';
import { TelegramInviteButton } from './InviteFriendButton';

const appwriteService = new AsbekAppwriteService();

const formatValue = (value) => {
    if (value >= 1000) {
        return `${(value / 1000).toFixed(1)}k`;
    }
    return value.toString();
};

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

// Create a Skeleton component with the loading animation
const Skeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  height: 17px;
  width: 100px; /* Adjust the width as needed */
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;
const ImageSkeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const TopSection = () => {
    const [user, setUser] = useState(null);
    const [coinValue, setCoinValue] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [message, setMessage] = useState(null);
    const [filters, setFilters] = useState({ minScore: 0, maxScore: 100 });
    const [documentCount, setDocumentCount] = useState(0);
    const [leaderboardType, setLeaderboardType] = useState('weekly'); // State for toggling leaderboard type
    const [loading, setLoading] = useState(false);
    const [startParam, setStartParam] = useState(null);
    const [toastVisible, setToastVisible] = useState(false);
    const {loadingFriends,friends,
        friendsCount,localClickCount,localCoinValue,setLocalClickCount,setLocalCoinValue,blockId,country,vpn,competitionStartDate,ergaa,incrementRank,theRank,resetClickCount,theasbeksPrice, theCoinValue,theWeeklyClickCount,theClickCount,theDailyClickCount,theMonthlyClickCount,incrementCoinValue,incrementWeeklyClickCount,incrementClickCount,incrementDailyClickCount,incrementMonthlyClickCount,theUser,theLastReset,theUserId,theUsername,theFirstName,theLastName,theInvitedBy,theInvitationLink,theAccountNumber,theAccountName,thePhoneNumber,theLastDailyReset,theLastWeeklyReset,theLastMonthlyReset,theBlock,theClickHistory,theOnline,theLastWeekClickCount,theSessionId,theWallet,theSessionTime
      } = useContext(CoinContext);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const startParam = query.get('tgWebAppStartParam');
    setStartParam(startParam);
  }, []);

    
  const tg = window.Telegram.WebApp;
  tg.ready();
  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

    // Optional story sharing parameters

    // Share the story

    //console.log('User found:', usertg); // Log user details
    
    
    const userId = String(usertg?.id).trim();
    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.ready();

        const initDataUnsafe = tg.initDataUnsafe;
        const user = initDataUnsafe?.user;
        setUser(user)
       // fetchAllDocuments();
      
    }, [filters]);

    
    const getTopUsers = (count) => {
        return documents
            .sort((a, b) => b.coinValue - a.coinValue)
            .slice(0, count);
    };

    const handleToggle = (type) => {
      
        setLeaderboardType(type); // Function to set leaderboard type
        // Implement logic to fetch data based on type (weekly, monthly, all time)
    };

    const calculateRank = (coinValue) => {
        const sortedDocuments = documents.sort((a, b) => b.coinValue - a.coinValue);
        return sortedDocuments.findIndex(doc => doc.coinValue <= coinValue) + 1;
    };

    
    
    const topUsers = getTopUsers(3);
    const userRank = calculateRank(coinValue);
    
    const generateStars = (numStars) => {
        let stars = [];
        for (let i = 0; i < numStars; i++) {
          stars.push(<Star key={i}>★</Star>);
        }
        return stars;
      };
      
      const Stars = ({ numStars }) => {
        return <StarsWrapper>{generateStars(numStars)}</StarsWrapper>;
      };
    const title="Start earning effortlessly on ASBEKS. Join now and know your rank";
    //   const userId = String(user.id).trim();
    const telegramLink = userId ? `https://t.me/asbeksbot/start/?startapp=${userId}` : '';
    const handleCopyClick = () => {
        const linkWithText = `Start earning effortlessly on ASBEKS. Join now and know your rank! ${telegramLink}`;
        navigator.clipboard.writeText(linkWithText).then(() => {
            setToastVisible(true); // Show toast when link is copied
                setTimeout(() => {
                    setToastVisible(false); // Hide toast after 3 seconds
                }, 3000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };
    
    return (
        
        <>
        
         
        <Container>
            <TopContainer>
                <StarsWrapper>
                    {[...Array(50)].map((_, i) => (
                        <Star key={i}>⭐</Star>
                    ))}
                </StarsWrapper>
                <RowLabel>Invite Friends</RowLabel>
                <RowValue>
                    You and your friend will receive bonuses for invitation.
                    <br />
                    <strong>+1000 to you and your friend</strong>
                </RowValue>
                
                    
                <TelegramInviteButton inviteLink={telegramLink} title={title} />
                
                
            </TopContainer>
        </Container>
        

            <ToggleContainer>
            <WhiteFriendsCard fullWidth={true}>
    <FriendRowContainer>
        <FriendRowLabel>
            Your have {!loadingFriends ? friendsCount : <Skeleton />} Friends
        </FriendRowLabel>
    </FriendRowContainer>
</WhiteFriendsCard>
        
       
        
      </ToggleContainer>
      <Divider></Divider>
      
      {loading ? (
          <LoadingContainer>
          <LoadingSpinner size="50px" />
        </LoadingContainer>
        ) :
        <LeaderboardContainer>
       <ul
    style={{
        listStyle: 'none',
        padding: '0',
        margin: '0',
        width: '100%', // Allow full width
    }}
>
    {friends.map((doc) => (
        <li
            key={doc.$id}
            style={{
                display: 'flex',
                alignItems: 'center', // Ensures vertical alignment
                justifyContent: 'space-between', // Space between image and the text area
                padding: '12px 10px',
                borderBottom: '1px solid #f0f0f0',
                transition: 'background-color 0.3s, transform 0.3s ease',
                cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#f7f7f7';
                e.currentTarget.style.transform = 'scale(1.02)';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.transform = 'scale(1)';
            }}
        >
            {/* Profile Image */}
            <img
                src={asbek1}
                alt="Profile"
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    marginRight: '12px',
                    objectFit: 'cover',
                }}
            />
            {/* Name and Coin Value */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div
                    style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#333',
                        textAlign: 'left', // Align name to the left
                    }}
                >
                    {doc.first_name}
                </div>
                <div
                    style={{
                        fontSize: '14px',
                        color: '#666',
                        textAlign: 'left', // Align coin value to the left
                    }}
                >
                    {doc.coinValue} 
                </div>
            </div>
            {/* Link to username */}
            {doc.username && (
                <a
                    href={`https://t.me/${doc.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        fontSize: '14px',
                        color: '#007bff',
                        textDecoration: 'none',
                    }}
                >
                    Visit
                </a>
            )}
        </li>
    ))}
</ul>


    </LeaderboardContainer>
    
    }
        </>
    );
};

const LoadingContainer = styled.div`
  /* Optional: Adjust positioning and styling for the loader container */

  /* Center the loader */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Fullscreen overlay example */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  z-index: 999; /* Ensure it's above other content */

  /* Alternatively, you can adjust size and positioning for a specific container */
  /* Example for centered loading spinner within a specific container */
  /* position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  */
`;


const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ToggleItem = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  color: ${(props) => (props.active ? 'blue' : '#808080')};
  border-radius: 5px;
  margin: 0 5px;
  border-bottom: ${(props) => (props.active ? '2px solid blue' : 'none')}; /* Indicator for active item */
`;

const Divider = styled.div`
  width: 100%;
  height: 0.1px;
  background-color: #808080; /* Divider color */
  margin: 0 10px;
`;

const LeaderboardContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensure content starts from the left */
`;



export default TopSection;
