import styled from 'styled-components';

export const Card = styled.div`
  background: linear-gradient(135deg, #4caf50, #2196f3);
  border-radius: 12px;
  width: 90%;
  max-width: 350px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
`;

export const Title = styled.div`
  color: white;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const CurrencySelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CurrencyImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const Arrow = styled.span`
  font-size: 18px;
  color: white;
`;

export const InputContainer = styled.div`
  margin-bottom: 12px;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd; /* Minimal border */
  border-radius: 6px; /* Slightly rounded corners */
  background-color: #fff; /* Clean white background */
  color: #333;
  box-sizing: border-box; /* Ensure consistent sizing */
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4f94d4; /* Modern blue accent */
  }

  &::placeholder {
    color: #aaa; /* Soft placeholder color */
  }
`;

export const OutputContainer = styled.div`
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
`;

export const AsbeksOutput = styled.strong`
  color: #ff9900; /* Highlighted output */
  font-weight: 600; /* Semi-bold for clarity */
`;


export const ErrorMessage = styled.div`
  color: #d32f2f; /* Modern red tone */
  font-size: 14px;
  margin-bottom: 10px;
  background-color: #fdecea; /* Subtle, modern red highlight */
  padding: 12px; /* Adds space for readability */
  border-left: 4px solid #d32f2f; /* Modern accent border */
  border-radius: 6px; /* Smooth rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds slight depth */
  font-family: 'Roboto', sans-serif; /* Clean, modern font */
`;


export const Button = styled.button`
  background: linear-gradient(120deg, #4f94d4, #81d4fa); /* Eye-catching gradient */
  color: #ffffff; /* Bright white text for contrast */
  border: none;
  padding: 14px 28px; /* Slightly larger padding for modern feel */
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px; /* Smooth corners */
  cursor: pointer;
  width: 100%; /* Flexible width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(135deg, #3a8cc9, #70c9f7); /* Subtle hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Slightly elevated shadow */
    transform: translateY(-3px); /* Raised hover effect */
  }

  &:active {
    background: linear-gradient(135deg, #2e73a5, #5db8dc); /* Deeper tones for active state */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Reduced shadow for active press */
    transform: translateY(1px); /* Subtle press effect */
  }

  &:disabled {
    background: linear-gradient(135deg, #e0e0e0, #c8c8c8); /* Neutral grey for disabled state */
    color: #a0a0a0; /* Muted text color */
    cursor: not-allowed; /* Disabled cursor */
    box-shadow: none;
    transform: none;
    opacity: 0.7; /* Slight fade for clarity */
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 4px rgba(81, 196, 255, 0.5); /* Accessible focus ring */
  }
`;
export const SuccessBanner = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f4f8; /* Soft neutral background */
  color: #333; /* Muted text color */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
`;

export const SuccessMessage = styled.div`
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #555; /* Muted color for text */
`;


export const CloseButton = styled.button`
  background-color: white;
  color: #4caf50;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f1f1f1;
  }
`;

