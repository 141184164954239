import React, {useContext, useState, useEffect } from 'react';
import { CoinContext } from '../contextdtatashare/CoinContext';
import { Snackbar, Stepper, Step, StepLabel , Box, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Confetti from 'react-confetti';
import axios from 'axios';
import { faShoppingCart, faPaperPlane, faArrowDown, faSyncAlt, faQrcode, faCopy } from '@fortawesome/free-solid-svg-icons';
import {
  StyledCard,StyledCardContent,
  StyledButton,
  StyledDrawer,
  StyledTextField,
  StyledBox,
  StyledTypography,
  StyledCloseIconButton,
} from './buyAsbeksStyle';
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';

const Icon = styled(FontAwesomeIcon)`
    font-size: 20px;
  `;
const formatAddress = (address) => {
    if (address.length <= 15) return address; // Return the address if it's short enough
    const start = address.slice(0, 6); // First 6 characters
    const end = address.slice(-4); // Last 4 characters
    return `${start}...${end}`; // Formatted address
  };
const SEND_FEE_PERCENTAGE = 0.01;
const CRYPTO_TO_USD = 2000;
const getTonPriceInUsd = async () => {
    const price_url = `https://jetton-index.tonscan.org/market_data/chart?days=1&interval=daily&vs_currency=USD&precision=4`;
    try {
      const price_response = await axios.get(price_url);
      if (price_response.status === 200) {
        const latestPriceData = price_response.data.prices;
        // Assuming the latest price is the last entry in the prices array
        return latestPriceData[latestPriceData.length - 1][1]; // The price of 1 TON in USD
      } else {
        //console.error('Error fetching price data:', price_response.statusText);
        return null; // Or handle the error appropriately
      }
    } catch (error) {
      //console.error('Error fetching price data:', error);
      return null;
    }
  };

  
const BuyAsbeks = () => {
  const [Balance, setBalance] = useState(null); // State to store  balance
  const [balanceInUsd, setBalanceInUsd] = useState(null); 
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress(true);
  const [tonConnectUI] = useTonConnectUI();
  const [asbeksPrice, setAsbeksPrice] = useState(null);
  const [coinValueToUsd, setCoinValueToUsd] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [coinValue, setCoinValue] = useState('');
  const [recipient, setRecipient] = useState('UQC6cS3uwguDjyOl9SrP9gty2BBynjZ70M7dHPtr5N2gA919');
  const [activeStep, setActiveStep] = useState(0);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [estimatedUSD, setEstimatedUSD] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [securing, setSecuring] = useState(false);
  const [isSecuring, setIsSecuring] = useState(false);
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds

const [confirmationExpiry, setConfirmationExpiry] = useState(null);

  const [cryptoToUsd, setCryptoToUsd] = useState(0); // Store the current price of TON
  const {
    createWallet, fetchWalletData
  } = useContext(CoinContext);
  const [maxSendableAmount, setMaxSendableAmount] = useState(0);
  
  const toggleDrawer = (open) => () => setOpen(open);

  useEffect(() => {
    if (securing) { // Only start countdown if securing state is true
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(interval);
            return 0; // Stops countdown
          }
          return prevCountdown - 1; // Decrease countdown by 1 second
        });
      }, 1000); // 1000ms = 1 second
  
      // Cleanup the interval when the component unmounts or when securing is false
      return () => clearInterval(interval);
    }
  }, [securing]);
  
  
  useEffect(() => {
    if (wallet) {
      const data = window.Telegram.WebApp.initData;
  
      const fetchWalletInfo = async () => {
        const initdata =
          'user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d';
  
        const url = `https://67078ccf4eb31299c3df.appwrite.global/walletInfo?thewallet=${encodeURIComponent(userFriendlyAddress)}&initData=${data}&asbeksValue=1000&value=1`;
  
        try {
          const response = await axios.get(url, {
            timeout: 60000,
          });
  
          if (response.data.status === 'true') {
            setBalance(response.data.balance); // Update  balance
            setBalanceInUsd(response.data.balanceInUsd); // Update balance in USD
          } else {
            setErrorMessage('Failed to fetch wallet info.');
          }
        } catch (error) {
          //console.error('Error fetching wallet info:', error);
          setErrorMessage('Error fetching wallet info.');
        }
      };
  
      fetchWalletInfo();
    }
  }, [wallet, userFriendlyAddress]);
  const fetchAsbeksPrice = async () => {
    const data = window.Telegram.WebApp.initData;
    const initdata =
        'user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d';

    try {
      const response = await axios.get(
        `https://67078ccf4eb31299c3df.appwrite.global/asbeksPrice?initData=${data}&toAddress=UQC6cS3uwguDjyOl9SrP9gty2BBynjZ70M7dHPtr5N2gA919&value=0.1&comment=Payment%20for%20services&title=Service%20Payment&description=Payment%20for%20requested%20services&earn=0.1&url=http%3A%2F%2Fexample.com%2Fservice&adsBudget=10`
      );
      //console.log('projnjnsdk', response)
      // Extract `asbeksPrice` and `coinValueToUsd` from the response
      setAsbeksPrice(response.data.asbeksPrice);
      // setCoinValueToUsd(response.data.coinValueToUsd);
    } catch (err) {
      //console.error('Error fetching ASBeks price:', err);
      setError('Failed to fetch ASBeks price.');
    }
  };

  // Use effect to fetch ASBeks price on component mount
  useEffect(() => {
    fetchAsbeksPrice();
  }, []);

  
  const handleMaxAmount = () => {
    setAmount(maxSendableAmount.toString()); // Set the max amount to the amount text field
  };
  const [addressError, setAddressError] = useState('');
  const validateAddress = (address) => {
    
    if (createWallet.address == address) {
      setAddressError("You can't send to your self!");
      
    } else {
      setAddressError(''); // Clear error if valid
    }
    
  };
  useEffect(() => {
    const fetchTonPrice = async () => {
      const price = await getTonPriceInUsd();
      if (price) {
        setCryptoToUsd(price);
      }
    };

    fetchTonPrice();
  }, []);

  useEffect(() => {
    
    const amountInNumber = parseFloat(amount) || 0;
    setEstimatedUSD(amountInNumber * cryptoToUsd);
    setAmount(asbeksPrice * coinValue / cryptoToUsd)
    const data = window.Telegram.WebApp.initData;
    
    // setTransactionFee(amountInNumber * SEND_FEE_PERCENTAGE);
  }, [amount, cryptoToUsd, asbeksPrice, coinValue]);

  
  const handleNextStep = () => setActiveStep((prev) => prev + 1);
  const handleReset = () => {
    setActiveStep(0);
    setAmount('');
    setLoading(false);
  setConfirmLoading(false);
  setSecuring(false);
  setIsSecuring(false);
  setCountdown(120); // Reset countdown
  setConfirmationExpiry(null);
  setCoinValue('');
  setActiveStep(0);
    // setRecipient('');
    toggleDrawer(false)();
  };

  const handleConfirmSend = async () => {
    const data = window.Telegram.WebApp.initData;
    const initdata = 'user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d'
     
    const apiUrl = `https://67078ccf4eb31299c3df.appwrite.global/buyAsbeks?initData=${encodeURIComponent(data)}&toAddress=${recipient}&value=${parseFloat(amount).toFixed(9)}&asbeksValue=${coinValue}`;
    
    //console.log('dhskjjncjk', (parseFloat(amount).toFixed(9) * 1e9).toString())
    const validUntil = Math.floor(Date.now() / 1000) + 60;
  setLoading(true);
    setConfirmLoading(true);
    
    setIsSecuring(true);
setConfirmationExpiry(null);
    //const apiUrl = `https://67078ccf4eb31299c3df.appwrite.global/buyAsbeks?initData=user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d&toAddress=${recipient}&value=${parseFloat(amount).toFixed(9)}&asbeksValue=${coinValue}`;
    const securingUrl = `https://67078ccf4eb31299c3df.appwrite.global/generate-payment-token`;
    const response = await axios.get(securingUrl, {
      timeout: 90000, // Timeout set to 10 seconds (you can adjust as needed)
    });
    if (response.data.status === 'true') {
      setSecuring(true)
      setTimeout(() => {
        setIsSecuring(false)
        setConfirmationExpiry(new Date().getTime() + 2 * 60 * 1000); // Set expiry to 2 minutes
      }, 3000); // Assume securing takes 3 seconds; adjust as needed

      const token = response.data.token
      //console.log('tokening', token)
      
      const transaction = {
        validUntil: validUntil, // 60 seconds validity
        messages: [
          {
            address: 'UQC6cS3uwguDjyOl9SrP9gty2BBynjZ70M7dHPtr5N2gA919', // recipient address
            amount: (parseFloat(0.01).toFixed(9) * 1e9).toString(), // convert amount to nanotons
          },
        ],
      };
      const options = {
        modals: ['before', 'success', 'error'],
        notifications: ['before', 'success', 'error'],
        skipRedirectToWallet: 'never',  // Option for iOS redirect behavior
        returnStrategy: 'none',  // No redirection after transaction
    };
      try {
        console.log('sdnjkdf',tonConnectUI.sendTransaction(transaction, options) )
        const result = await tonConnectUI.sendTransaction(transaction);
        
        //console.log('kjhsefegfhsjdk', result)
        //cghjj
        const url = `https://67078ccf4eb31299c3df.appwrite.global/waitForTransaction?thewallet=${encodeURIComponent(userFriendlyAddress)}&boc=${encodeURIComponent(result.boc)}&initData=${data}&asbeksValue=${coinValue}&value=${parseFloat(amount).toFixed(9)}&token=${token}`;
        // Send GET request with URL parameters
        const response = await axios.get(url, {
          timeout: 60000, // Timeout set to 10 seconds (you can adjust as needed)
        });
        //console.log('rejnkjn', response)
        // Check the response status
      if (response.data.status === 'true') {
        fetchWalletData()
        setSuccessOpen(true);
        handleNextStep();
        // alert('Transaction confirmed');
      } else {
        setErrorMessage(response.data.message || 'An error occurred.');
        setErrorOpen(true);
        // alert('Transaction failed');
      }
      } catch (err) {
        setErrorMessage('Failed to send payment. Please try again.');
        setErrorOpen(true);
        //console.error(err);
      } finally {
        setLoading(false);
        setConfirmLoading(false);
      }
    
    }
  };

  const handleCloseSuccess = () => {
    setSuccessOpen(false);
    handleReset();
  };

  const handleCloseError = () => setErrorOpen(false);

  return (
    <> 
        <StyledButton
          variant="contained"
          disabled={!wallet}
          // endIcon={<SendIcon />}
          onClick={handleConfirmSend}
        >
          <Icon icon={faShoppingCart} /> &nbsp; Buy Asbeks
        </StyledButton>
   
      <StyledDrawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
        <StyledBox>
          <StyledTypography variant="h5">
            Buy Asbeks
          </StyledTypography>
          <StyledCloseIconButton onClick={() => { handleReset(); toggleDrawer(false)(); }}>
            <CloseIcon />
        </StyledCloseIconButton>
        </StyledBox>
        
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mt: 2, mb: 4 }}>
          {['Enter Details', 'Confirm', 'Success'].map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
          <div>
          {asbeksPrice !== null  ? (
        <div>
          <center><p><strong>Asbeks Price ASBEKS/USDT:</strong> ${parseFloat(asbeksPrice)}</p></center>
          
        </div>
      ) : (
        <p>Loading ASBeks price...</p>
      )}
          </div>
        {activeStep === 0 && (
          <>
          <StyledBox
      style={{
        display: 'flex',
        alignItems: 'center', // Center both elements vertically
        justifyContent: 'space-between', // Space between the elements
        gap: '10px', // Adds space between the TextField and Button
      }}
    >
      <StyledTextField
        label="Asbeks"
        type="number"
        fullWidth
        variant="outlined"
        value={coinValue}
        onChange={(e) => setCoinValue(e.target.value)}
        style={{ flex: 1 }} // Make the text field take all available space
      />

      <StyledButton
        onClick={handleNextStep}
        variant="contained"
        disabled={
          !coinValue || isNaN(Number(coinValue)) || Number(coinValue) <= 0
        }
        style={{ height: '100%' }} // Ensure button height matches the text field
      >
        Proceed
      </StyledButton>
    </StyledBox>
    <div>
      
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      {amount !== 0  ? (
        
        <div>
  <StyledCard>
    <StyledCardContent>
      <StyledTypography variant="body1">
        💵 Estimated USD: <b>${parseFloat(coinValue * asbeksPrice).toFixed(9)}</b>
      </StyledTypography>
    </StyledCardContent>
    <StyledCardContent>
      <StyledTypography variant="body1">
        💸 Estimated Ton: <b>{amount} Ton</b>
      </StyledTypography>
    </StyledCardContent>
  </StyledCard>
</div>


      ):(
        <p></p>
      )}
    </div> 
            
            
            {/* <StyledBox mt={2}>
              
              <Typography color="#3b3f5c">
                💸 Transaction Fee: <b>{transactionFee} Ton</b>
              </Typography>
            </StyledBox> */}
            
            
          </>
        )}

        {activeStep === 1 && (
          <Box textAlign="center" mt={4}>
            <Typography variant="h6" color="#3b3f5c">
              Confirm Transaction
            </Typography>
            <Typography mt={2}>
              Buy <b>{coinValue}</b> Asbeks 
            </Typography>
            {isSecuring && <Typography variant="body1">Securing transaction...</Typography>}
            {securing && countdown > 0 ? (
  <Typography variant="body1">
    This transaction will be expired in : {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? '0' : ''}{countdown % 60} minutes
  </Typography>
) : (
  countdown === 0 && (
    <Typography variant="body1" color="error">
      Transaction confirmation expired. Please try again.
    </Typography>
  )
)}


            
            <StyledButton
              onClick={handleConfirmSend}
              variant="contained"
              disabled={confirmLoading}
              sx={{ mt: 4 }}
            >
              {confirmLoading ? 'Pending please wait...' : 'Confirm'}
            </StyledButton>
          </Box>
        )}

        {activeStep === 2 && (
          <Box textAlign="center" mt={4}>
            <Typography variant="h4" color="success.main">
              🎉 Success!
            </Typography>
            <Confetti numberOfPieces={200} />
            <StyledButton
              onClick={handleCloseSuccess}
              variant="contained"
              sx={{ mt: 4 }}
            >
              Finish
            </StyledButton>
          </Box>
        )}
      </StyledDrawer>

      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        message="Transaction Successful!"
        action={
          <StyledCloseIconButton onClick={handleCloseSuccess}>
            <CloseIcon />
          </StyledCloseIconButton>
        }
      />

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={errorMessage}
        action={
          <StyledCloseIconButton onClick={handleCloseError}>
            <CloseIcon />
          </StyledCloseIconButton>
        }
      />
    </>
  );
};

export default BuyAsbeks;
