// src/ApiService.js
import axios from 'axios';
import { API_ENDPOINT } from './config.js';

// Cache expiration time in milliseconds (5 minutes)
const CACHE_EXPIRATION = 10 * 60 * 1000;

const cache = {
  topUsers: {},
  userRank: {
    data: {},
    timestamp: 0,
  }
}; 

// Retrieve from local storage
const loadFromLocalStorage = () => {
  const topUsers = localStorage.getItem('topUsers');
  const userRank = localStorage.getItem('userRank');
  if (topUsers) {
    cache.topUsers = JSON.parse(topUsers);
  }
  if (userRank) {
    cache.userRank = JSON.parse(userRank);
  }
};

// Save to local storage
const saveToLocalStorage = () => {
  localStorage.setItem('topUsers', JSON.stringify(cache.topUsers));
  localStorage.setItem('userRank', JSON.stringify(cache.userRank));
};

// Fetch new data and update the cache
const fetchAndUpdateCache = async (criteria) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/?criteria=${criteria}`);
    cache.topUsers[criteria] = {
      data: response.data,
      timestamp: Date.now()
    };
    saveToLocalStorage();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// ApiService methods
const ApiService = {
  getTopUsers: async (criteria) => {
    const now = Date.now();
    const cachedData = cache.topUsers[criteria];

    // Show expired cache immediately
    if (cachedData) {
      if (now - cachedData.timestamp <= CACHE_EXPIRATION) {
        // Cache is still valid
        return cachedData.data;
      } else {
        // Cache is expired, show cached data and update in the background
        fetchAndUpdateCache(criteria);
        return cachedData.data;
      }
    } else {
      // No cache available, fetch new data
      await fetchAndUpdateCache(criteria);
      return cache.topUsers[criteria].data;
    }
  },

  getUserRank: async (userId, criteria) => {
    const now = Date.now();
    const cacheKey = `${userId}_${criteria}`;
    const cachedRank = cache.userRank.data[cacheKey];

    // Show expired cache immediately
    if (cachedRank) {
      if (now - cache.userRank.timestamp <= CACHE_EXPIRATION) {
        // Cache is still valid
        return cachedRank;
      } else {
        // Cache is expired, show cached data and update in the background
        axios.get(`${API_ENDPOINT}/?criteria=${criteria}&userId=${userId}`)
          .then(response => {
            cache.userRank.data[cacheKey] = response.data;
            cache.userRank.timestamp = Date.now();
            saveToLocalStorage();
          })
          .catch(error => console.error("Error fetching rank data:", error));
        return cachedRank;
      }
    } else {
      // No cache available, fetch new data
      const response = await axios.get(`${API_ENDPOINT}/?criteria=${criteria}&userId=${userId}`);
      cache.userRank.data[cacheKey] = response.data;
      cache.userRank.timestamp = Date.now();
      saveToLocalStorage();
      return response.data;
    }
  }
};

// Load initial cache from local storage
loadFromLocalStorage();

export default ApiService;
