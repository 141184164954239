import React, { useState } from 'react';
import { Client, Functions, ExecutionMethod  } from 'appwrite';

const client = new Client()
    .setEndpoint('https://66b08271d75db0be960b.appwrite.global/v1') // Your API Endpoint
    .setProject('66af5ec5001608e38606'); // Your Project ID

const functions = new Functions(client);

const AppwriteComponent = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [consoleLog, setConsoleLog] = useState([]);

    const callFunction = () => {
        setLoading(true);
        setConsoleLog([]); // Clear previous logs
    
        // Mocking console.log to capture logs
        const originalConsoleLog = console.log;
        console.log = (...args) => {
            setConsoleLog(prevLogs => [...prevLogs, ...args]);
            originalConsoleLog(...args); // Also print to the browser console
        };
    
        functions.createExecution(
            '66afddbc001291c89e83', // Function ID
            '/?criteria=alltime',   
               // Path and parameters
            true,                    // Additional boolean parameter
            ExecutionMethod.GET, 
            '', // body (optional)
            Headers  // headers (optional)   // Execution method (this is not used in the request, it's an execution method flag)
        )
        .then(response => {
            setResponse(response);
            setError(null);
            setLoading(false);
        })
        .catch(error => {
            setError(error);
            setResponse(null);
            setLoading(false);
        })
        .finally(() => {
            console.log = originalConsoleLog; // Restore original console.log
        });
    };
    

    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <button
                onClick={callFunction}
                disabled={loading}
                style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    cursor: loading ? 'not-allowed' : 'pointer',
                    backgroundColor: loading ? '#ccc' : '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                }}
            >
                {loading ? 'Loading...' : 'Call Appwrite Function'}
            </button>
            {response && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Response:</h3>
                    <pre style={{
                        backgroundColor: '#f8f9fa',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                        padding: '10px',
                        maxWidth: '600px',
                        overflowX: 'auto'
                    }}>
                        {JSON.stringify(response, null, 2)}
                    </pre>
                </div>
            )}
            {error && (
                <div style={{ marginTop: '20px', color: '#dc3545' }}>
                    <h3>Error:</h3>
                    <pre style={{
                        backgroundColor: '#f8d7da',
                        border: '1px solid #f5c6cb',
                        borderRadius: '5px',
                        padding: '10px',
                        maxWidth: '600px',
                        overflowX: 'auto'
                    }}>
                        {JSON.stringify(error, null, 2)}
                    </pre>
                </div>
            )}
            <div style={{ marginTop: '20px' }}>
                <h3>Console Logs:</h3>
                <pre style={{
                    backgroundColor: '#e9ecef',
                    border: '1px solid #ced4da',
                    borderRadius: '5px',
                    padding: '10px',
                    maxWidth: '600px',
                    maxHeight: '300px',
                    overflowY: 'auto'
                }}>
                    {consoleLog.map((log, index) => (
                        <div key={index}>{log}</div>
                    ))}
                </pre>
            </div>
        </div>
    );
};

export default AppwriteComponent;
