import styled from 'styled-components';

export const Container = styled.div`
  
  margin-bottom: 70px;
  font-family: 'Roboto', sans-serif;
 
`;

export const AppBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #ff4b2b, #ff416c); // Gradient background
  height: 70px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 0 20px;
  // position: sticky;
  top: 0;
  // z-index: 10;
  transition: all 0.3s ease;
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 0;
`;

export const HamburgerIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
`;

export const Bar = styled.div`
  width: 30px;
  height: 4px;
  background-color: #fff;
  border-radius: 5px;
`;

export const Menu = styled.div`
  position: absolute;
  top: 70px; // Below the app bar
  left: 0;
  width: 100%;
  background-color: #ff416c;
  padding: 20px 0;
  z-index: 2;
  transition: transform 0.3s ease-out;
  transform: translateY(0); // Used for transition effect
`;

export const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
  opacity: 1; // Ensures the menu fades in
  transition: opacity 0.3s ease-in-out;
`;

export const MenuItem = styled.li`
  color: #fff;
  font-size: 1.4rem;
  padding: 15px 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #ff4b2b;
  }
`;

export const MainContent = styled.div`
  padding: 30px;
  text-align: center;
  z-index: 0;
`;

export const ShopTitle = styled.h2`
  font-size: 2.4rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const ShopDescription = styled.p`
  font-size: 1.2rem;
  color: #666;
  font-weight: 400;
  margin-bottom: 30px;
`;

export const Card = styled.div`
  background: linear-gradient(135deg, #fff, #f8f8f8);
  border-radius: 16px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 10px;
`;

export const CardBody = styled.div`
  padding: 25px;
  text-align: center;
`;

export const CardTitle = styled.h3`
  font-size: 1.8rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 14px;
`;

export const CardDescription = styled.p`
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 20px;
`;

export const CardPrice = styled.p`
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 25px;
`;

export const BuyButton = styled.button`
  background-color: #333;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 30px;
  font-size: 1.3rem;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #555;
  }
`;
export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);  // Default: 2 products per row
  gap: 10px;
  margin: 20px 0;
  padding: 0 10px;
  @media (min-width: 500px) {
    grid-template-columns: repeat(3, 1fr); // Keep 2 products per row on medium screens
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); // Automatically adjusts to fill available space on larger screens
  }
`;
