import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 80%;
  height: 500px;
  max-width: 400px;
  animation: fadeIn 0.3s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CloseButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60;
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const GiveLifeButton = styled.button`
  width: 100%;
 background-color: #1e90ff;
  background-image: linear-gradient(135deg, #6dd5fa, #2980b9, #2a9df4); /* Brighter, multi-tone gradient */
  color: white;
  border: none;
  border-radius: 16px; /* More rounded for a sleek look */
  padding: 16px 32px;
  font-size: 18px; /* Slightly larger text for emphasis */
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease; /* Smoother transition for all elements */
  //box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* More pronounced shadow for depth */
 // margin-top: 15px;

  &:hover {
    background-image: linear-gradient(135deg, #2a9df4, #6dd5fa); /* Reverse gradient */
    box-shadow: 0 12px 25px rgba(0, 206, 209, 0.5), 0 0 20px rgba(0, 206, 209, 0.2); /* Added a glowing hover effect */
    transform: translateY(-5px) scale(1.05); /* Hover lift and slight scaling for emphasis */
  }

  &:active {
    background-image: linear-gradient(135deg, #1e90ff, #2980b9); /* Darker active gradient */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(2px); /* Subtle push down on click */
  }

  &:disabled {
    background-color: #bbb;
    background-image: none;
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.6;
  }
`;


export const FetchingText = styled.p`
  text-align: center;
  margin-top: 10px; /* Adjust as needed */
  color: #555; /* Optional: add some styling */
`;


export const IframeContainer = styled.div`
  width: 100%;
  height: 450px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const RowContainer = styled.div`
  color: #808080;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

export const WhiteCard = styled.div`
  z-index: 2;
  background-color: #ffffff; /* White background */
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '45%')};
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
`;

export const RowLabel = styled.div`
  font-weight: bold;
  font-size: 13px; /* Base font size */
  
  letter-spacing: 0.5px; /* Slightly spaced letters for better readability */
  line-height: 1.5; /* Adjusted line height for better readability */

  /* Responsive Design */
  @media (max-width: 1024px) {
    font-size: 13px; /* Keep the font size 13px for larger screens */
  }

  @media (max-width: 768px) {
    font-size: 13px; /* Keep the font size 13px for medium screens */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Slightly smaller font size for small screens */
    text-align: center; /* Centered text for better appearance on small screens */
  }

  @media (max-width: 360px) {
    font-size: 11px; /* Further reduce font size for very small screens */
  }
`;


export const RowValue = styled.div`
  font-size: 17px; /* Default font size */
  font-weight: bold;
  color: #333333; /* Professional dark color */
  text-align: left; /* Align text to the left by default */
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    text-align: left; /* Align text to the left for tablets */
  }

  @media (max-width: 768px) {
    text-align: center; /* Center the text for medium screens */
    padding: 0 10px; /* Add some horizontal padding for medium screens */
  }

  @media (max-width: 480px) {
    text-align: center; /* Center the text for small screens */
    padding: 0 5px; /* Less padding for small screens */
  }

  @media (max-width: 360px) {
    text-align: center; /* Keep the text centered for very small screens */
    padding: 0 5px; /* Keep the same padding for smaller screens */
  }
`;


export const WhiteFriendsCard = styled.div`
  z-index: 2;
  background: linear-gradient(135deg, #a1c4fd, #c2e9fb, #ffecd2, #fcb69f);

  width: ${({ fullWidth }) => (fullWidth ? '90%' : '40%')};
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
`;