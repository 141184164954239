import React, { useState, useEffect } from 'react'; 
import styled from 'styled-components';

// Styled components for layout
const CryptoListContainer = styled.div`
  max-width: 600px; // Maximum width for better readability
  margin: auto; // Center the column
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const CryptoRow = styled.div`
  padding: 10px; // Padding for each row
  border-bottom: 1px solid #ccc; // Light bottom border
  display: flex; // Flex layout for alignment
  justify-content: space-between; // Space between elements
  align-items: center; // Center items vertically

  &:last-child {
    border-bottom: none; // Remove border for the last row
  }
`;

const CryptoName = styled.span`
  font-weight: bold; // Bold text for the name
`;

const PriceChange = styled.span`
  color: ${({ isPositive }) => (isPositive ? 'green' : 'red')}; // Color based on price change
  margin-left: 10px;
`;

const LoadingText = styled.p`
  text-align: center; // Center loading text
  color: #888; // Muted color for loading text
`;

// Simple CryptoList component
const CryptoList = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        const response = await fetch('https://jet.ton.cat/jettons?limit=100');
        const result = await response.json();

        if (result.ok) {
          setCryptoData(result.data.jettons);
        } else {
          console.error('Failed to fetch data:', result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCryptoData();
  }, []);

  return (
    <CryptoListContainer>
      {loading ? (
        <LoadingText>Loading...</LoadingText>
      ) : (
        cryptoData
          .filter(crypto => crypto.name === 'Notcoin' || crypto.name === 'Dogs') // Filter for specific cryptos
          .map((crypto) => {
            const priceChangePercentage = ((crypto.priceChange24h || 0) * 100).toFixed(2); // Price change percentage
            const isPositive = priceChangePercentage >= 0; // Determine if positive change

            return (
              <CryptoRow key={crypto.id}>
                <CryptoName>{crypto.name}</CryptoName>
                <div>
                  <span>${parseFloat(crypto.priceUsd).toLocaleString()}</span>
                  <PriceChange isPositive={isPositive}>
                    {isPositive ? '+' : ''}{priceChangePercentage}%
                  </PriceChange>
                </div>
              </CryptoRow>
            );
          })
      )}
    </CryptoListContainer>
  );
};

export default CryptoList;
