import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import calendar styles
import { format, startOfDay, isSameDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subWeeks } from 'date-fns'; // Import date-fns for date formatting

// Define the keyframes animation
const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

// Define styled components
const Skeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  height: 17px;
  width: 100px;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const ImageSkeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const SkeletonName = styled(Skeleton)`
  height: 20px;
  width: 100px;
  margin-top: 5px;
`;

const SkeletonCoins = styled(Skeleton)`
  height: 20px;
  width: 50px;
  margin-top: 5px;
`;

const appwriteService = new AsbekAppwriteService();

const ClickHistory = () => {
  const [clickHistories, setClickHistories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [topUsers, setTopUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filteredClicks, setFilteredClicks] = useState([]);

  useEffect(() => {
    appwriteService.getAllDocuments()
      .then(docs => {
        console.log('Fetched documents:', docs); // Debug log
        setClickHistories(docs);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching documents:', error);
        setLoading(false);
      });
  }, []);
  
    // Handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    filterClicksByDate(date);
  };
  // Format date for display
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to Date
    return format(date, 'MMM d, yyyy'); // Format date
  };

  // Filter clicks based on selected date
  const filterClicksByDate = (date) => {
    const selectedStartOfDay = startOfDay(date); // Get the start of the selected day
  
    const filtered = clickHistories.map(user => {
      const totalClicks = user.clickHistory.reduce((total, entry) => {
        const [clicks, entryDate] = entry.split(':');
        const entryDateObject = new Date(parseInt(entryDate, 10) * 1000); // Convert Unix timestamp to Date
        const entryStartOfDay = startOfDay(entryDateObject); // Get the start of the day for the entry
  
        // Check if clicks is null or invalid and skip if so
        const clicksInt = parseInt(clicks, 10);
        if (isNaN(clicksInt)) return total;
  
        // Compare only the date portion
        if (isSameDay(selectedStartOfDay, entryStartOfDay)) {
          return total + clicksInt;
        }
        return total;
      }, 0);
  
      return {first_name:user.first_name, username:user.username, last_name:user.last_name,coinValue:user.coinValue, userId: user.$id, clicks: totalClicks };
    });
  
    // Sort filtered clicks by total clicks in descending order and take the top 10
    const topFiltered = filtered.sort((a, b) => b.clicks - a.clicks).slice(0, 10);
  
    setFilteredClicks(topFiltered);
  };
  
  const filterClicksThisWeek = () => {
    const startOfWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(new Date(), { weekStartsOn: 1 });
  
    const filtered = filterClicksByRange(startOfWeekDate, endOfWeekDate);
    setFilteredClicks(filtered);
  };

  const filterClicksLastWeek = () => {
    const startOfLastWeek = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
    const endOfLastWeek = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
  
    const filtered = filterClicksByRange(startOfLastWeek, endOfLastWeek);
    setFilteredClicks(filtered);
  };


  const filterClicksThisMonth = () => {
    const startOfMonthDate = startOfMonth(new Date());
    const endOfMonthDate = endOfMonth(new Date());
  
    const filtered = filterClicksByRange(startOfMonthDate, endOfMonthDate);
    setFilteredClicks(filtered);
  };

  const filterClicksByRange = (startDate, endDate) => {
    const filtered = clickHistories.map(user => {
      const totalClicks = user.clickHistory.reduce((total, entry) => {
        const [clicks, entryDate] = entry.split(':');
        const entryDateObject = new Date(parseInt(entryDate, 10) * 1000); // Convert Unix timestamp to Date
  
        // Check if clicks is null or invalid and skip if so
        const clicksInt = parseInt(clicks, 10);
        if (isNaN(clicksInt)) return total;
  
        // Check if the entry date is within the specified range
        if (entryDateObject >= startDate && entryDateObject <= endDate) {
          return total + clicksInt;
        }
        return total;
      }, 0);
  
      return {first_name:user.first_name, username:user.username, last_name:user.last_name,coinValue:user.coinValue, userId: user.$id, clicks: totalClicks };
    });
  
    // Sort filtered clicks by total clicks in descending order and take the top 10
    return filtered.sort((a, b) => b.clicks - a.clicks).slice(0, 10);
  };
  

  // Calculate total clicks for each user and sort them in descending order
  const calculateTopUsers = () => {
    console.log('Click histories:', clickHistories);
  
    const userClicks = clickHistories.map(user => {
      console.log('Processing user:', user);
  
      const totalClicks = user.clickHistory.reduce((total, entry) => {
        const [clicks, dateStr] = entry.split(':');
        console.log('Entry:', entry, 'Clicks:', clicks, 'Date:', dateStr);
  
        // Handle null or invalid click values
        if (!clicks || isNaN(parseInt(clicks, 10))) {
          console.warn('Invalid click value:', clicks); // Log invalid entries
          return total; // Skip invalid entries
        }
  
        const parsedClicks = parseInt(clicks, 10);
        return total + parsedClicks;
      }, 0);
  
      console.log('Total clicks for user', user.$id, ':', totalClicks);
  
      return {first_name:user.first_name, username:user.username, last_name:user.last_name,coinValue:user.coinValue, userId: user.$id, clicks: totalClicks };
    });
  
    console.log('User clicks before sorting:', userClicks);
  
    return userClicks.sort((a, b) => b.clicks - a.clicks).slice(0, 10); 
  };
  
  

  useEffect(() => {
    if (clickHistories.length > 0) {
      const topUsers = calculateTopUsers();
      console.log('Top users:', topUsers); // Debug log
      setTopUsers(topUsers);
    }
  }, [clickHistories]);
  

  // Fetch click history for a specific user
  const fetchUserClickHistory = (userId) => {
    console.log('Fetching click history for user ID:', userId); // Debug log
  
    appwriteService.getDocument(userId)
      .then(doc => {
        console.log('Fetched document:', doc); // Debug log
        if (doc) {
          setClickHistories([doc]);
        }
      })
      .catch(error => {
        console.error('Error fetching document:', error);
      });
  };
  

  return (
    <div>
      
      <h1>Click History</h1>
      {loading ? (
        <div>
          <SkeletonName />
          <SkeletonCoins />
          <ImageSkeleton />
        </div>
      ) : (
        
        <div>
          <div>
    <h1>Click History Comparison</h1>
    <Calendar
      onChange={handleDateChange}
      value={selectedDate}
    />
    <button onClick={filterClicksThisWeek}>This Week</button>
    <button onClick={filterClicksLastWeek}>Last Week</button>
    <button onClick={filterClicksThisMonth}>This Month</button>
    <h2>Clicks on {formatDate(Math.floor(selectedDate.getTime() / 1000))}</h2>
    <ul>
      {filteredClicks.length > 0 ? (
        filteredClicks.map((user, index) => (
          <li key={index}>
            {user.first_name}: {user.clicks} clicks
          </li>
        ))
      ) : (
        <p>No clicks recorded for this date.</p>
      )}
    </ul>
  </div>
          <input
            type="text"
            placeholder="Enter user ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <button onClick={() => fetchUserClickHistory(userId)}>Fetch User Click History</button>
          <h2>Top Users</h2>
          <ul>
            {topUsers.map((user, index) => (
              <li key={index}>
                User ID: {user.first_name}, Total Clicks: {user.clicks}
              </li>
            ))}
          </ul>
          {clickHistories.length > 0 && (
            <div>
              <h2>Specific User Click History</h2>
              <ul>
                {clickHistories[0].clickHistory.map((entry, index) => {
                  const [clicks, dateStr] = entry.split(':');
                  const date = new Date(parseInt(dateStr, 10) * 1000);
                  const formattedDate = format(date, 'MMM d, yyyy hh:mm a');
                  return (
                    <li key={index}>
                      {clicks} clicks on {formattedDate}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
  
};

export default ClickHistory;
