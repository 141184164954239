// src/AppwriteService.js
import { 
  ASBEK_ENDPOINT, 
  ASBEK_PROJECT_ID, 
  ASBEK_DATABASE_ID, 
  ASBEK_COLLECTION_ID 
} from './config.js';
import * as Appwrite from 'appwrite';

class AppwriteService {
  constructor() {
      this.client = new Appwrite.Client();
      this.client
          .setEndpoint(ASBEK_ENDPOINT)
          .setProject(ASBEK_PROJECT_ID);

      this.db = new Appwrite.Databases(this.client);
  }

  isOnline(lastOnlineTime) {
      const currentTime = Math.floor(Date.now() / 1000);
      const onlineThreshold = 300; // 5 minutes (300 seconds)

      return currentTime - lastOnlineTime <= onlineThreshold;
  }

  async getTopUsers(criteria = 'weekly') {
      let sortField = 'coinValue';
      if (criteria === 'daily') sortField = 'dailyClickCount';
      else if (criteria === 'weekly') sortField = 'weeklyClickCount';
      else if (criteria === 'lastweek') sortField = 'lastWeekClickCount';
      else if (criteria === 'monthly') sortField = 'monthlyClickCount';

      try {
          const response = await this.db.listDocuments(
              ASBEK_DATABASE_ID,
              ASBEK_COLLECTION_ID,
              [
                  Appwrite.Query.orderDesc(sortField),
                  Appwrite.Query.limit(50),
              ]
          );

          let users = response.documents;

          // Separate top 3 all-time users
          let top3 = [];
          let rest = [];

         
              top3 = users.slice(0, 3).map(doc => ({
                  $id: doc.$id,
                  first_name: doc.first_name,
                  coinValue: doc.coinValue,
                  weeklyClickCount: doc.weeklyClickCount,
                  accountNumber:doc.accountNumber,
                accountName:doc.accountName,
                phoneNumber:doc.phoneNumber,
                  [criteria]: doc[sortField],
                  online: this.isOnline(doc.online)
              }));
              rest = users.slice(3).map(doc => ({
                  $id: doc.$id,
                  first_name: doc.first_name,
                  coinValue: doc.coinValue,
                  weeklyClickCount: doc.weeklyClickCount,
                  lastWeekClickCount: doc.lastWeekClickCount,
                  accountNumber:doc.accountNumber,
                accountName:doc.accountName,
                phoneNumber:doc.phoneNumber,
                  [criteria]: doc[sortField],
                  online: this.isOnline(doc.online)
              }));
         

          return { top3, rest };

      } catch (err) {
          console.error('Error fetching documents:', err);
          throw new Error('Internal Server Error');
      }
  }
}

export default AppwriteService;
