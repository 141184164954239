import React, { useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components'; // Import styled-components
import { Progress } from 'antd';
import { CoinContext } from './CoinContext';

// Define the loading animation
const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

// Define the Skeleton styled component
const Skeleton = styled.div`
  background-color: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  height: 17px;
  width: 100px; /* Adjust the width as needed */
  animation: ${loadingAnimation} 1.2s ease-in-out infinite;
`;

const containerStyle = {
  padding: '10px',
  textAlign: 'center',
};

const titleStyle = {
  fontSize: '22px',
  fontWeight: '600',
  margin: '10px 0',
  color: '#4A4A4A',
};

const progressTextStyle = {
  margin: '15px 0 5px 0',
  fontSize: '18px',
  fontWeight: '500',
  color: '#333',
};

const goalStyle = {
  fontSize: '14px',
  color: '#7D7D7D',
  marginTop: '10px',
};

const messages = [
  "Every step counts! 🌟",
  "Keep pushing your limits! 💪",
  "You're doing great! Keep it up! 🚀",
  "Every effort brings you closer! 🎯",
  "Small steps lead to big changes! 🌈",
  "You're almost there! Don't give up! 🌟",
  "Progress is progress, no matter how small! 🏆",
  "Stay positive; your journey matters! 🌈",
  "Success is built on persistence! 🔑",
  "Keep your eyes on the prize! 🏆",
  "You've got the strength to succeed! 💪",
  "Every moment is a chance to grow! 🌱",
  "Believe in yourself and keep going! 🚀",
  "Your efforts today shape your tomorrow! 🌟",
  "Your future is bright! 🔥",
  "Each day brings new opportunities! 🌅",
  "Your hard work will pay off soon! 🎉",
  "You have the power to make it happen! 💪",
  "Dream big and take small steps! 🌟",
  "Focus on progress, not perfection! 🌻",
  "Keep moving forward; you've got this! 🚀",
  "Hard work beats talent when talent sleeps! 😴",
  "Keep striving for greatness every day! 💎",
  "You're capable of achieving your dreams! 🌈",
  "Believe in the process; you're on track! 🔄",
  "Challenge yourself to reach new heights! 🏔️",
];

function GoalPage() {
  const { balance, goal } = useContext(CoinContext);
  const [motivationalMessage, setMotivationalMessage] = useState("");

  // Calculate the progress percentage
  const progressPercent = goal > 0 ? (balance / goal) * 100 : 0;

  // Function to determine the motivational message
  const getMotivationalMessage = () => {
    if (balance === goal) {
      return "Congratulations! You've reached your goal! 🎉";
    } else if (progressPercent >= 75) {
      return messages[1]; // "Keep pushing your limits! 💪"
    } else if (progressPercent >= 50) {
      return messages[3]; // "Small steps lead to big changes! 🌈"
    } else {
      return messages[Math.floor(Math.random() * messages.length)]; // Random message
    }
  };

  useEffect(() => {
    const updateMessage = () => {
      setMotivationalMessage(getMotivationalMessage());
    };

    updateMessage(); // Initial message

    // Set an interval to update the message every 5 minutes
    const intervalId = setInterval(updateMessage, 300000); // Change message every 5 minutes (300000 ms)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [balance, goal]); // Update when balance or goal changes

  return (
    <div style={containerStyle}>
      
      {balance === null ? (
        // Show Skeleton while loading
        <p></p>
        // <Skeleton />
      ) : (
        <>
        <h2 style={titleStyle}>Progress towards Goal</h2>
          <Progress
            percent={Math.min(progressPercent, 100)} // Ensure it doesn't exceed 100
            status={balance >= goal ? 'success' : 'active'}
            strokeColor={{
              '0%': '#00c6ff',
              '100%': '#0072ff',
            }}
            showInfo={false}
            trailColor="#e0e0e0"
            strokeWidth={12} // Slightly thicker stroke for better visibility
          />
          <p style={progressTextStyle}>{balance} / {goal}</p>
          <p style={goalStyle}>You're {goal - balance} away from reaching the goal!</p>
          
        </>
      )}
      <p style={{ fontSize: '16px', marginTop: '20px', color: '#0072ff' }}>
            {motivationalMessage}
          </p>
    </div>
  );
}

export default GoalPage;
