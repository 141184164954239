// src/AppwriteService.js

import * as Appwrite from 'appwrite';
import { 
  ASBEK_ENDPOINT, 
  ASBEK_PROJECT_ID, 
  ASBEK_DATABASE_ID, 
  ASBEK_COLLECTION_ID,
  ASBEK_CLICK_HISTORY_COLLECTION_ID,

} from './config.js';
const client = new Appwrite.Client()
    .setEndpoint(ASBEK_ENDPOINT)
    .setProject(ASBEK_PROJECT_ID);

const db = new Appwrite.Databases(client);


export async function getTopClicksByCriteria(criteria, userId = null) {
    const now = new Date();
    let startDate, endDate;

    switch (criteria) {
        case 'daily':
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            break;
        case 'weekly':
            const startOfWeek = now.getDate() - (now.getDay() === 0 ? 6 : now.getDay() - 1);
            startDate = new Date(now.getFullYear(), now.getMonth(), startOfWeek);
            endDate = new Date(now.getFullYear(), now.getMonth(), startOfWeek + 7);
            endDate.setHours(23, 59, 59, 999); // Include the entire Sunday
            break;
            
        case 'lastweek':
            const lastWeekStart = now.getDate() - (now.getDay() === 0 ? 6 : now.getDay() - 1) - 7;
            startDate = new Date(now.getFullYear(), now.getMonth(), lastWeekStart);
            endDate = new Date(now.getFullYear(), now.getMonth(), lastWeekStart + 7);
            endDate.setHours(23, 59, 59, 999); // Include the entire Sunday
            break;
            
        case 'monthly':
            startDate = new Date(now.getFullYear(), now.getMonth(), 1);
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
            break;
        case 'lastmonth':
            startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), 1);
            break;
        case 'last3months':
            startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
            endDate = new Date(now.getFullYear(), now.getMonth(), 1);
            break;
        case 'thisyear':
            startDate = new Date(now.getFullYear(), 0, 1);
            endDate = new Date(now.getFullYear() + 1, 0, 1);
            break;
        default:
            throw new Error('Invalid criteria');
    }

    const startTimestamp = Math.floor(startDate.getTime() / 1000).toString();
    const endTimestamp = (Math.floor(endDate.getTime() / 1000) - 1).toString();

    try {
        let allDocuments = [];
        let limit = 100;
        let lastDocumentId = null;

        while (true) {
            let queryOptions = [
                Appwrite.Query.greaterThanEqual('date', startTimestamp),
                Appwrite.Query.lessThanEqual('date', endTimestamp),
                Appwrite.Query.orderDesc('clicks'),
                Appwrite.Query.limit(limit)
            ];
            if (lastDocumentId) {
                queryOptions.push(Appwrite.Query.cursorAfter(lastDocumentId));
            }
            const response = await db.listDocuments(
                ASBEK_DATABASE_ID,
                ASBEK_CLICK_HISTORY_COLLECTION_ID,
                queryOptions
            );

            allDocuments = allDocuments.concat(response.documents);

            if (response.documents.length < limit) {
                break;
            }

            lastDocumentId = response.documents[response.documents.length - 1].$id;
        }

        const aggregatedClicks = allDocuments.reduce((acc, doc) => {
            if (acc[doc.userId]) {
                acc[doc.userId] += doc.clicks;
            } else {
                acc[doc.userId] = doc.clicks;
            }
            return acc;
        }, {});

        const clicks = Object.keys(aggregatedClicks).map(userId => ({
            userId: userId,
            clicks: aggregatedClicks[userId]
        }));

        clicks.sort((a, b) => b.clicks - a.clicks);

        if (userId) {
            const rank = clicks.findIndex(click => click.userId === userId) + 1;
            return { rank, topClicks: clicks.slice(0, 50) };
        } else {
            return clicks.slice(0, 50);
        }

    } catch (err) {
        throw new Error('oops');
    }
}

