import React, { useContext, useEffect, useState } from 'react';
import { CoinContext } from './CoinContext';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import axios from 'axios';
import { FaCheckCircle ,FaExclamationTriangle} from 'react-icons/fa';

import { ProgressBar,BotLink,ButtonGroup,CancelButton,CharacterCount,Container,CostAmount,DetailItem,Explanation,Form,Input,MessageContainer,ModalHeader,MotivationalMessage,OpenButton,SheetContent,SubmitButton,TaskDetails,TelegramNotice,TextArea,TotalCost,TotalWorkers, } from './taskformcreationstyle';
// Initialize Appwrite servicenjn
const appwriteService = new AsbekAppwriteService();
const WarningMessage = styled.div`
  color: #f44336;
  font-size: 14px;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;
const TaskCreationForm = () => {
    const [isUrlValid, setIsUrlValid] = useState(true);
  // Function to validate URL
  const validateUrl = (url) => {
    const urlRegex = /^(http|https):\/\/[^\s$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };
    const [isTaskCreated, setIsTaskCreated] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [usersReachable, setUsersReachable] = useState(0); // State to hold how many users can be reached
    const [totalBudget, setTotalBudget] = useState('');
    const [budgeInTon, setBudgetInTon] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [taskData, setTaskData] = useState({
        title: '',
        description: '',
        earn: '',
        url: '',
        adsBudget: '',
        
    });

    const [totalCost, setTotalCost] = useState(0);
    const [isFormValid, setIsFormValid] = useState(false);
        
    const {
        createWallet, fetchWalletData, cryptoToUsd, estimatedUSD, theCoinValue, decrementCoinValue
    } = useContext(CoinContext);

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleOpenButtonClick = () => {
    
        
    // Check if the balance is suffici
    if (Number(theCoinValue) < 10000) {
        setMessage('You don\'t have enough balance. You have to have atleast 10,000 Asbeks to create you amazing task. '); // Set insufficient balance message
        return; // Prevent opening the form
    }

    // Clear any previous messages and open the form
    setMessage(''); // Clear the message
    setIsOpen(true); // Open the form
    
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'url') {
            setIsUrlValid(validateUrl(value));
          }
        if (name === 'budget') {
            // Handle budget-specific logic
            if (parseFloat(value) > theCoinValue) {
                setTotalBudget(theCoinValue); // Cap the budget to max available balance
            } else {
                setTotalBudget(value); // Set the budget normally
            }
        } else {
            // Handle general input changes
            setTaskData({ ...taskData, [name]: value });
        }
    };
    
    
    
    // Calculate total cost whenever earn or adsBudget changes
    useEffect(() => {
        const earnAmount = parseFloat(taskData.earn) || 0;
        const adsBudget = parseInt(taskData.adsBudget) || 0;
        const budgetAmount = parseFloat(totalBudget) || 0; // Use budget state
        // setTotalCost(earnAmount * adsBudget);
        const totalCost = earnAmount ? (budgetAmount / earnAmount) : 0;
        setUsersReachable(Math.floor(totalCost));
        const balance = theCoinValue;
        // setBudgetInTon(budgetAmount/cryptoToUsd)

        // const usersReachable = Math.floor(balance / earnAmount); // Use Math.floor to round down to the nearest whole number

        // // Set the usersReachable state
        // setUsersReachable(usersReachable);
        setTaskData((prevData) => ({
            ...prevData,
            adsBudget: Math.floor(totalCost), // Automatically set adsBudget to usersReachable
           
        }));
        // Enable submit button only when both earn and adsBudget are greater than 0
        if (earnAmount > 0 && Math.floor(totalCost) > 0) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [taskData.earn, totalBudget, budgeInTon]);

   
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const newTask = await appwriteService.createNewTask(taskData);
    //         console.log('Task created:', newTask);
    //         setIsOpen(false);
    //     } catch (error) {
    //         console.error('Error creating task:', error.message);
    //     }
    // };
    // const handleConfirm = async () => {
    //     try {
    //         await appwriteService.createNewTask(taskData);
    //         setIsOpen(false);
    //         setIsConfirmationOpen(false);
    //     } catch (error) {
    //         console.error('Error creating task:', error.message);
    //     }
    // };
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#eaf2f8', // Soft light blue background
            padding: '20px',
            fontFamily: "'Roboto', sans-serif", // Modern font
        },
        createTaskButton: {
            padding: '8px 16px', // Smaller padding for a more compact button
            fontSize: '14px', // Reduced font size for simplicity
            cursor: 'pointer',
            backgroundColor: '#007bff', // Bright blue button
            color: '#ffffff', // White text
            border: 'none',
            borderRadius: '20px', // Slightly rounded corners for style
            marginBottom: '15px',
            boxShadow: '0 2px 5px rgba(0, 123, 255, 0.2)', // Subtle shadow for depth
            transition: 'background-color 0.3s, transform 0.2s',
            
            // Hover effect
            '&:hover': {
                backgroundColor: '#0056b3', // Darker blue on hover
                transform: 'translateY(-1px)', // Small lift effect
            },
        },
        successCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f8fff3', // Light green for the card
            border: '2px solid #4caf50', // Green border
            borderRadius: '10px', // Rounded corners
            padding: '15px', // Smaller padding
            maxWidth: '280px', // Adjusted width for compactness
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
            textAlign: 'center',
        },
        successIcon: {
            color: '#4caf50', // Green color for the icon
            fontSize: '2rem', // Slightly smaller icon size
            marginBottom: '10px',
        },
        title: {
            color: '#4caf50', // Consistent green for the title
            margin: '0 0 5px',
            fontSize: '1.2rem', // Reduced title size
        },
        message: {
            color: '#333', // Dark gray for message text
            fontSize: '0.9rem', // Smaller font size for the message
            margin: 0,
        },
    };
    

    const SuccessCard = ({ message = "Your task is successfully created!" }) => (
        <div style={styles.successCard}>
            <FaCheckCircle style={styles.successIcon} />
            <h2 style={styles.title}>Success!</h2>
            <p style={styles.message}>{message}</p>
        </div>
    );

    const handleConfirm = async () => {
        
        setLoading(true);
        setError('');
        try {
            
  const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

  

  const userId = String(usertg?.id).trim();
                        
            const taskPayload = {
                userId: userId,
                toAddress: 'UQC6cS3uwguDjyOl9SrP9gty2BBynjZ70M7dHPtr5N2gA919',
                value: parseFloat(taskData.adsBudget),
                comment: 'Payment for services',
                title: taskData.title,
                description: taskData.description,
                earn: parseFloat(taskData.earn),
                url: taskData.url,
                adsBudget: parseFloat(taskData.adsBudget),
            };
            const response = await appwriteService.createTask(taskPayload)
            console.log('sdjh', response)
            // Handle response
            
            if (response === 'Task created successfully.') {
                decrementCoinValue(parseFloat(taskData.adsBudget ) * parseFloat(taskData.earn))
                setMessage('Task created successfully')
                setTaskData(
                    {title: '',
                    description: '',
                    earn: '',
                    url: '',
                    adsBudget: '',
                    
                }
                )
                
                setIsTaskCreated(true); // Show success card on success
                setTimeout(() => {
                    setIsTaskCreated(false); // Hide the card after a delay
                    setMessage('');
                }, 6000);
                 // Example delay of 3 seconds
                // You can update the UI or show a success message here
                setIsOpen(false);
                setIsConfirmationOpen(false);
            } else if (response === 'Task updated successfully.'){
              
                setMessage('Task updated successfully')
                decrementCoinValue(parseFloat(taskData.adsBudget))
                setTaskData(
                    {title: '',
                    description: '',
                    earn: '',
                    url: '',
                    adsBudget: '',
                    
                }
                )
                setIsTaskCreated(true); // Show success card on success
                setTimeout(() => {
                    setIsTaskCreated(false); // Hide the card after a delay
                    setMessage('');
                }, 6000);
                 // Example delay of 3 seconds
                // You can update the UI or show a success message here
                setIsOpen(false);
                setIsConfirmationOpen(false);
            } else {
                console.error('something went wrong:', response);
                setMessage('1Something went wrong please try again.')
                // Show an error message to the user
                }
        
        } catch (error) {
            console.error('Error confirming payment:', error.message);
            setMessage('Something went wrong, please try again.');
            
        } finally {
            setLoading(false);
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsConfirmationOpen(true);
    };
    
    return (
        <Container>
            {isTaskCreated && <SuccessCard />}
            <OpenButton  onClick={handleOpenButtonClick}>✨ Create Your Task Now!</OpenButton>
            
            {message && <MessageContainer>{message}</MessageContainer>} {/* Display message under the button */}
            <BottomSheet open={isOpen} onDismiss={() => {
                setIsOpen(false);
                setTaskData({
                    title: '',
                    description: '',
                    earn: '',
                    url: '',
                    adsBudget: '',
                });
            }}
>
                <SheetContent>
                    <ModalHeader>✨ Let's Create an Amazing Task!</ModalHeader>
                    <center><h>Your Asbeks {theCoinValue}</h></center>
                    <Form onSubmit={handleSubmit}>
                    <Explanation>
                            What exciting task do you want users to complete? Be catchy and clear!
                        </Explanation>
                        <Input 
                            type="text"
                            name="title"
                            placeholder="🎯 Task Title"
                            value={taskData.title}
                            onChange={handleInputChange}
                            required
                        />
                        
                        <Explanation>
                            Keep it brief! Describe your task clearly in 3-4 lines.
                        </Explanation>
                        <TextArea 
                            name="description"
                            placeholder="📝 Task Description (max 150 characters)"
                            value={taskData.description}
                            onChange={handleInputChange}
                            maxLength={150} // Limit to 150 characters (approx. 3-4 lines)
                            required
                        />
                        <CharacterCount>{taskData.description.length}/150</CharacterCount>
                        
                        <Explanation>
                            How many Asbeks would you like to distribute from your total of {theCoinValue}?
                        </Explanation>
                        <Input 
                            type="number"
                            name="budget"
                            placeholder="💰 How many Asbeks will you give away? (e.g., 50)"
                            value={totalBudget}
                            onChange={handleInputChange}
                            required
                        />
                        
                        <Explanation>
                            How much will you reward each user for completing the task? 
                            Make it big to attract more participants!
                        </Explanation>

                        <Input 
                            type="number"
                            name="earn"
                            placeholder="💰 Amount per User (e.g., 100)"
                            value={taskData.earn}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === "" || /^[0-9]+$/.test(value)) { // Only allow non-decimal numbers
                                    handleInputChange(e);
                                }
                            }}
                            required
                        />

                        
                        
                        {/* <Input 
                            type="number"
                            name="adsBudget"
                            placeholder="📊 Budget for Users (e.g., 1000 for 1000 users)"
                            value={taskData.adsBudget}
                            onChange={handleInputChange}
                            required
                        /> */}
                        {/* <Explanation>
                            How many users do you want to reach with this task? 
                            More users mean more visibility!
                        </Explanation> */}
                        <Explanation>
                            Where can users find the task? Ensure it’s a valid link for maximum engagement!
                        </Explanation>
                        <Input 
                            type="text"
                            name="url"
                            placeholder="🔗 Task URL"
                            value={taskData.url}
                            onChange={handleInputChange}
                            isValid={isUrlValid}
                            required
                        />
                        {!isUrlValid && taskData.url && (
                        <WarningMessage>
                            <FaExclamationTriangle style={{ marginRight: '5px' }} />
                            Please enter a valid URL starting with http:// or https://.
                        </WarningMessage>
                        )}
   


       
        
                        <TelegramNotice>
                            ⚠️ **Important!** To gain followers for your Telegram group or channel, ensure that <BotLink href="https://t.me/asbeksbot" target="_blank" rel="noopener noreferrer">@asbeksbot</BotLink> is an admin in your channel with the necessary permissions. 
                            Your task will **not** be displayed unless the bot has admin rights. The bot verifies if users have genuinely joined your Telegram. If they have, they’ll be rewarded, ensuring you get real followers for your channel!
                        </TelegramNotice>

                        <TotalCost>
                            💸 <strong>Total Workers you can reach: </strong><CostAmount>{usersReachable}</CostAmount>
                        </TotalCost>

                        <MotivationalMessage>
                            🚀 You’re just one step away from launching your task! Let’s make it happen!
                        </MotivationalMessage>

                        <ButtonGroup>
                        <SubmitButton type="submit" disabled={!isFormValid}> Create Task</SubmitButton>
                        <CancelButton type="button" onClick={() => {
    setIsOpen(false);
    setTaskData({
        title: '',
        description: '',
        earn: '',
        url: '',
        adsBudget: '',
    });
}}
> Cancel</CancelButton>
                        </ButtonGroup>
                    </Form>
                </SheetContent>
            </BottomSheet>

            <BottomSheet 
                open={isConfirmationOpen} 
                onDismiss={ loading ? null : () => {
                    setIsConfirmationOpen(false)

                }}
            >
                <SheetContent>
                    <ModalHeader>✨ Confirm Your Task Details</ModalHeader>
                    <TaskDetails>
                        <DetailItem>
                            <strong>Task Title:</strong> {taskData.title}
                        </DetailItem>
                        <DetailItem>
                            <strong>Description:</strong> {taskData.description}
                        </DetailItem>
                        <DetailItem>
                            <strong>Earn per User:</strong> {taskData.earn} ASBEKS
                        </DetailItem>
                        <DetailItem>
                            <strong>Total Budget:</strong> {totalBudget} ASBEKS
                        </DetailItem>
                        <DetailItem>
                            <strong>Task URL:</strong> {taskData.url}
                        </DetailItem>
                        {message && <MessageContainer>{message}</MessageContainer>}
                        {loading ? <MessageContainer>"Processing... please wait don't close untill it's done "</MessageContainer>  : ''}
                        {loading && <ProgressBar />}
                        <TotalWorkers>
                            <strong>Total Workers you can reach: </strong>
                            <CostAmount>{usersReachable}</CostAmount>
                        </TotalWorkers>
                    </TaskDetails>
                    

                    <ButtonGroup>
                        <SubmitButton
                            type="button"
                            onClick={handleConfirm}
                            disabled={!isFormValid || loading}
                        >
                            {loading ? 'Processing...' : 'Confirm'}
                        </SubmitButton>
                        <CancelButton 
                            onClick={() => setIsConfirmationOpen(false)}
                            disabled={!isFormValid || loading}
                        >
                                 Cancel
                        </CancelButton>
                    </ButtonGroup>
                </SheetContent>
            </BottomSheet>

        </Container>
    );
};


export default TaskCreationForm;
