import styled from 'styled-components';
import { Button, Drawer, TextField, Box, Typography, IconButton } from '@mui/material';

// Custom Styled Button with better hover effects and a gradient background
export const StyledButton = styled(Button)`
  background: linear-gradient(120deg, #4f94d4, #81d4fa);
    color: #ffffff;
    font-size: 14px;
    padding: 12px 16px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background: #3f51b5;
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }

    // Style for disabled state
    &:disabled {
      background: #cfd8dc; // Light gray background for disabled state
      color: #b0bec5; // Light gray text for disabled state
      cursor: not-allowed; // Change cursor to not-allowed
      pointer-events: none; // Prevent any interaction
    }
`;

// Drawer with smooth animations and rounded edges
export const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    border-radius: 20px 20px 0 0;
    background: linear-gradient(120deg, #d8eaf9, #e8f4fc);


    padding: 25px;
    box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.15);
    min-height: 88vh;
    overflow-x: hidden;
    overflow-y: auto;  // Enable vertical scrolling
  }
    animation: slideUp 0.4s ease;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

// Styled TextField with custom background and box shadow
// Adjusted TextField to have consistent height and spacing with the Button
export const StyledTextField = styled(TextField)`
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-right: 16px;  // Spacing between TextField and Button
  & .MuiInputBase-root {
    font-size: 16px;
    height: 48px;  // Ensure the height is the same as the button
    padding: 10px 15px;  // Consistent padding
  }
  & .MuiInputLabel-root {
    font-size: 14px;
  }
`;

// Styled Box to align TextField and Button in a row with appropriate spacing
export const StyledBox = styled(Box)`

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: white;

 /* Clean white background */
  border-radius: 10px; /* Soft rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Light shadow */
  margin: 10px 0; /* Vertical margin */
  overflow-x: hidden; /* Full width for responsive design */
`;


// Typography with modern font weight and spacing
export const StyledCard = styled(Box)`
  display: flex;
  justify-content: space-between; /* Distribute the cards evenly */
  align-items: center;
  padding: 15px;
  background-color: #ffffff; /* Clean white background */
  border-radius: 12px; /* Rounded corners for a soft, modern look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  overflow-x: hidden; /* Full width to make it responsive */
  margin: 12px 0; /* Even margin between components */
`;

export const StyledCardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0 10px; /* Ensure spacing inside each card */
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px; /* Moderate text size for clarity */
  font-weight: 500;
  color: #333333; /* Dark text for contrast */
  text-align: center;
  line-height: 1.5;

  b {
    font-weight: 600;
    color: #3f9bfc; /* Accent color for important values */
  }
`;


// Close button for Snackbar
export const StyledCloseIconButton = styled(IconButton)`
  color: #ff595e;
`;

