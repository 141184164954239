import React, { useState, useEffect } from 'react';
import {
    Drawer,
    Button,
    TextField,
    Typography,
    Box,
    IconButton,
    Divider,
    Paper,
    useTheme,
    useMediaQuery,
    Snackbar,
    Alert,
    CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import IDTranslator from '../api/IDTranslator';

// Initialize the appwriteService
const appwriteService = new AsbekAppwriteService();

const TransactionPage = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [senderId, setSenderId] = useState('');
    const [recipientId, setRecipientId] = useState('');
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [isRecipientValid, setIsRecipientValid] = useState(false);
const [recipientValidationMessage, setRecipientValidationMessage] = useState('');
const validateRecipientId = async (id) => {
    const translatedId = IDTranslator.reverseTranslateId(id);
    console.log(translatedId)
    // setRecipientValidationMessage(translatedId)
    try {
        const recipientDocument = await appwriteService.getUserDetails(translatedId);
        if(recipientDocument.userId == null) {
            setIsRecipientValid(false);
            setRecipientValidationMessage('Recipient ID is not valid.');
        } else {
            console.log(recipientDocument)
            setIsRecipientValid(true);
            setRecipientValidationMessage('');
            
        }
    } catch (error) {
        setIsRecipientValid(false);
        setRecipientValidationMessage('Error validating recipient ID.');
    }
};

const handleRecipientChange = (e) => {
    const id = e.target.value;
    setRecipientId(id);
    if (id) {
        validateRecipientId(id);
    } else {
        setIsRecipientValid(true);
        setRecipientValidationMessage('');
    }
};


    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.ready();
        const initDataUnsafe = tg.initDataUnsafe;
        const user = initDataUnsafe?.user;
        if (user) {
            setSenderId(String(user.id)); // Set the senderId from Telegram WebApp user ID
        }
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const result = await appwriteService.transferCoins(senderId, recipientId, parseInt(amount, 10));
            const successMessage = `Transfer successful! ${amount} Asbeks sent to recipient ID: ${recipientId}`;
            setMessage(successMessage);
            setStatusMessage(successMessage);
            setSnackbarSeverity('success');
            //setDrawerOpen(false); // Optionally close the drawer after success

            // const transactionData = {
            //     amount: amount,
            //     sender: senderId,
            //     recipient: recipientId,
            //     currency: 'Asbeks'
            // };
            // fetch('https://66f46cdd4587b0b3ee6b.appwrite.global/send', {
            //     method: 'POST',
                
            //     body: JSON.stringify(transactionData),
            // });
    
        } catch (error) {
            const errorMessage = 'Transfer failed: ' + error.message;
            setMessage(errorMessage);
            setStatusMessage(errorMessage);
            setSnackbarSeverity('error');
        } finally {
            setLoading(false);
            setOpenSnackbar(true);
        }
    };
    
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);

    //     try {
    //         const result = await appwriteService.transferCoins(senderId, recipientId, parseInt(amount, 10));
    //         // setMessage(result.message);
    //         setMessage(`Transfer successful! ${amount} Asbeks sent to recipient ID: ${recipientId}`);

    //         setSnackbarSeverity('success');
    //     } catch (error) {
    //         setMessage('Error: ' + error.message);
    //         setSnackbarSeverity('error');
    //     } finally {
    //         setLoading(false);
    //         setOpenSnackbar(true);
    //     }
    // };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setDrawerOpen(true)}
                sx={{ 
                    mt: 3, 
                    mb: 2, 
                    borderRadius: '20px',
                    boxShadow: theme.shadows[5],
                    ':hover': {
                        boxShadow: theme.shadows[8],
                    },
                    py: isMobile ? 1 : 1.5,
                    px: isMobile ? 2 : 3,
                    background: 'linear-gradient(135deg, #6a1b9a 30%, #ab47bc 90%)',
                    color: '#fff'
                }}
            >
                Send Asbeks
            </Button>
            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{ 
                    sx: { 
                        borderRadius: '24px 24px 0 0', 
                        p: 3, 
                        width: '100%',
                        maxWidth: '100%',
                        height: isMobile ? '80vh' : '90vh',
                        background: 'linear-gradient(135deg, #f3e5f5 30%, #e1bee7 90%)',
                        boxSizing: 'border-box',
                        overflow: 'auto'
                    } 
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6" component="h2" color="textPrimary" sx={{ flexGrow: 1 }}>
                            Transfer Asbeks
                        </Typography>
                        <IconButton onClick={() => setDrawerOpen(false)} color="inherit">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ mb: 2, borderColor: theme.palette.divider }} />
                    <Paper elevation={3} sx={{ p: 3, borderRadius: '12px', display: 'flex', flexDirection: 'column', gap: 2, backgroundColor: '#fff' }}>
                        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                            {/* <TextField
                                label="Sender ID"
                                variant="outlined"
                                value={senderId}
                                onChange={(e) => setSenderId(e.target.value)}
                                required
                                fullWidth
                                margin="normal"
                                InputProps={{ 
                                    sx: { 
                                        borderRadius: '8px', 
                                        backgroundColor: '#fafafa' 
                                    } 
                                }}
                                sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
                            /> */}
                            <TextField
    label="Recipient ID"
    variant="outlined"
    value={recipientId}
    onChange={handleRecipientChange}
    required
    fullWidth
    margin="normal"
    InputProps={{ 
        sx: { 
            borderRadius: '8px', 
            backgroundColor: '#fafafa' 
        } 
    }}
    sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
    error={!isRecipientValid}
    helperText={recipientValidationMessage}
/>

                            <TextField
                                label="Amount"
                                variant="outlined"
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                required
                                fullWidth
                                margin="normal"
                                InputProps={{ 
                                    sx: { 
                                        borderRadius: '8px', 
                                        backgroundColor: '#fafafa' 
                                    } 
                                }}
                                sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
                            />
                            <Button
    type="submit"
    variant="contained"
    color="primary"
    sx={{ 
        mt: 2, 
        borderRadius: '20px', 
        boxShadow: theme.shadows[5],
        ':hover': {
            boxShadow: theme.shadows[8],
        },
        py: isMobile ? 1 : 1.5,
        background: 'linear-gradient(135deg, #7b1fa2 30%, #ab47bc 90%)',
        color: '#fff',
        position: 'relative'
    }}
    disabled={loading || !isRecipientValid}
>
    {loading ? <CircularProgress size={24} sx={{ color: '#fff', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} /> : 'Transfer'}
</Button>

                        </form>
                       


                    </Paper>
                    <Snackbar
            open={openSnackbar}
            autoHideDuration={6000000}
            onClose={handleSnackbarClose}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{ bottom: 16, left: 0, right: 0, width: 'calc(100% - 32px)', mx: 'auto' }}
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                sx={{ width: '100%' }}
                iconMapping={{
                    success: <CheckCircleIcon fontSize="inherit" />,
                    error: <ErrorIcon fontSize="inherit" />
                }}
            >
                {message}
            </Alert>
        </Snackbar>
                </Box>
            </Drawer>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000000}
                onClose={handleSnackbarClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ top: 80 }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    iconMapping={{
                        success: <CheckCircleIcon fontSize="inherit" />,
                        error: <ErrorIcon fontSize="inherit" />
                    }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default TransactionPage;
